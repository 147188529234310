import React from "react";
import moment from "moment";
import "moment/locale/pt-br";

const LastMonthsSelect = ({ onChange, className, value }) => {
  const generateLastTenMonths = () => {
    const months = [];
    for (let i = 0; i < 10; i++) {
      const month = moment().subtract(i, "months");
      months.push({
        display: month.format("MMMM/YY"),
        value: month.format("YYYY-MM"),
      });
    }
    return months;
  };

  const lastTenMonths = generateLastTenMonths();

  return (
    <select onChange={onChange} className={`${className || `md:w-fit`} w-full block py-3 md:py-2 px-2 rounded-md text-sm text-gray-600 bg-gray-100 border-0 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer capitalize`} value={value}>
      {lastTenMonths.map((month, index) => (
        <option key={index} value={month.value}>
          {month.display}
        </option>
      ))}
    </select>
  );
};

export default LastMonthsSelect;
