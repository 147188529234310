import Select from "react-select";

const customStyles = {
  input: (provided, state) => ({
    ...provided,
    height: "40px",
    outline: state.isFocused ? "transparent" : "transparent",
  }),
  control: (provided, state) => ({
    ...provided,
    outline: "none",
    boxShadow: "none",
    "& input:focus": {
      outline: "none",
      boxShadow: "none",
    },
    borderColor: state.isFocused ? "var(--primary)" : "#d4d4d4",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "var(--primary)" : "transparent",
    color: state.isFocused ? "white" : "var(--primary)",
    "&:hover": {
      backgroundColor: "var(--primary)",
      color: "white",
    },
  }),
};

const AutoCompleteInput = ({
  noOptionsMessage = "Nenhum registro encontrado",
  options,
  onChange,
  isLoading,
  label,
  name,
  value,
  disabled = false
}) => {
  const selectedOption =
    options && options.find((option) => option.value === value);

  return (
    <div className="w-full relative">
      <Select
        noOptionsMessage={() => noOptionsMessage}
        options={options}
        onChange={onChange}
        placeholder=""
        isLoading={isLoading}
        name={name}
        value={selectedOption || null}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
        styles={customStyles}
        isDisabled={disabled}
      />
      <label
        htmlFor={name}
        className="absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-1 z-2 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
      >
        {label}
      </label>
    </div>
  );
};

export default AutoCompleteInput;
