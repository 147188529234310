import React from "react";
import { Alert, Badge, Card, Table } from "flowbite-react";
import { CodeBlock, CopyBlock, dracula } from "react-code-blocks";

const DocDefineRisco = ({ token }) => {
  return (
    <Card className={`w-full mt-3`}>
      <div className="mb-4">
        <h2 className="title-syncx">Documentação da API: Positivo Plus CNPJ</h2>
        <br /> Esta documentação descreve como integrar-se à API utilizando um
        método <b>GET</b> com autenticação baseada em token. <br />
        Siga as instruções abaixo para enviar uma solicitação autenticada e
        entender a resposta da API.
        <br />
        <br />
        Endpoint URL:{" "}
        <Badge className="inline" color="indigo">
          https://app.acessopositivo.com.br/api/query/define-risco/:CNPJ
        </Badge>{" "}
        <br />
        Método:{" "}
        <Badge className="inline" color="indigo">
          GET
        </Badge>
        <br />
        Autenticação:{" "}
        <Badge className="inline" color="indigo">
          Bearer Token
        </Badge>
        <br />
        <br />
        <div className="font-semibold">Token:</div>
        <CopyBlock text={token} theme={dracula} codeBlock />
        <Alert className="mt-2" color="warning">
          Não divulgue esse token com ninguém! O token é valido por 2 anos. Caso
          o token expire, entrar em contato diretamente com o suporte.
        </Alert>
        <div className="mt-4">
          <div className="overflow-x-auto shadow-lg rounded-lg mt-0 md:mt-5">
            <Table striped>
              <Table.Head>
                <Table.HeadCell className="text-center bg-gray-300">
                  Parâmetro
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Tipo
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Obrigatório
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Observações
                </Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y">
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    CNPJ
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    Texto
                  </Table.Cell>
                  <Table.Cell className="text-center text-red-500">
                    Sim
                  </Table.Cell>
                  <Table.Cell className="text-center">
                    Informar o CNPJ a ser consultado no parâmetro da URL.
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        </div>
        <br />
        Obs: Envie o token de autenticação no cabeçalho da requisição.
      </div>
      <CodeBlock
        text={`{
          "status": "success",
          "data": {
              "defineRisco": {
                  "identificacao_basico": {
                      "cnpj": "00000000000",
                      "razao_social": "Razao Social",
                      "nome_fantasia": "Nome fantasia",
                      "situacao_cnpj": "Ativo",
                      "data_situacao_cnpj": "DD/MM/YYYY",
                      "data_consulta_receita_federal": "DD/MM/YYYY",
                      "numero_nire": "9999999999",
                      "uf_nire": "UF",
                      "inscricao_estadual": "000000000000000",
                      "uf_inscricao_estadual": "UF",
                      "data_fundacao": "DD/MM/YYYY",
                      "codigo_natureza_juridica": "0000",
                      "natureza_juridica": "Natureza juridica",
                      "ramo_atividade": {
                          "atividade": "Atividade",
                          "cnae": "99999-999"
                      }
                  },
                  "localizacao_basico": {
                      "logradouro": "R EXEMPLO, 265",
                      "bairro": "Bairro EXEMPLO",
                      "cep": "00000000",
                      "cidade": "Exemplo",
                      "uf": "UF"
                  },
                  "score": {
                      "score_pj": {
                          "score": "540",
                          "modelo_score": "38",
                          "classificacao_numerica": "10",
                          "classificacao_alfabetica": "C",
                          "probabilidade": "28.2",
                          "texto": "De cada 100 empresas classificadas nesta classe de score, é provável que 28 apresentem débitos no mercado nos próximos 12 meses."
                      }
                  },
                  "consultas": {
                      "quantidade_total": "1",
                      "periodo_inicial": "01/09/2023",
                      "periodo_final": "01/09/2024",
                      "consultas_mes": [
                          {
                              "mes_ano": "092024",
                              "quantidade": "1"
                          },
                          {
                              "mes_ano": "082024",
                              "quantidade": "0"
                          },
                          {
                              "mes_ano": "072024",
                              "quantidade": "0"
                          },
                          {
                              "mes_ano": "062024",
                              "quantidade": "0"
                          },
                          {
                              "mes_ano": "052024",
                              "quantidade": "0"
                          },
                          {
                              "mes_ano": "042024",
                              "quantidade": "0"
                          },
                          {
                              "mes_ano": "032024",
                              "quantidade": "0"
                          },
                          {
                              "mes_ano": "022024",
                              "quantidade": "0"
                          },
                          {
                              "mes_ano": "012024",
                              "quantidade": "0"
                          },
                          {
                              "mes_ano": "122023",
                              "quantidade": "0"
                          },
                          {
                              "mes_ano": "112023",
                              "quantidade": "0"
                          },
                          {
                              "mes_ano": "102023",
                              "quantidade": "0"
                          },
                          {
                              "mes_ano": "092023",
                              "quantidade": "0"
                          }
                      ],
                      "ultimas_consultas": {
                          "consulta": {
                              "data": "24/09/2024",
                              "razao_social": "WIN SERVICOS E CREDITOS"
                          }
                      }
                  },
                  "pendencias_restricoes": {
                      "mensagem": "Nada Consta."
                  },
                  "cheques_sem_fundo": {
                      "mensagem": "Nada Consta."
                  },
                  "cheques_sustados": {
                      "mensagem": "Nada Consta."
                  },
                  "cheques_devolvidos_informados_usuario": {
                      "mensagem": "Nada Consta."
                  },
                  "protestos": {
                      "mensagem": "Nada Consta."
                  }
              }
          }
      }`}
        language={"json"}
        showLineNumbers={true}
        wrapLines
        theme={dracula}
      />
    </Card>
  );
};

export default DocDefineRisco;
