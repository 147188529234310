import { IoIosLock } from "react-icons/io";

const ForbiddenQueriesPermission = () => {
  return (
    <div className="mt-5 w-full text-center py-10 border-dashed border-gray-300 border-2 rounded font-semibold text-gray-500 flex items-center justify-center min-h-60 flex gap-1 flex-col">
      <IoIosLock fontSize={30} />
      <span>
        Você não possui autorização para realizar consultas nesta categoria.
        <br />
        Por favor, entre em contato com o administrador do sistema para
        solicitar acesso.
      </span>
    </div>
  );
};

export default ForbiddenQueriesPermission;
