import { Card } from "flowbite-react";
import React from "react";

const LGPDPage = () => {
  return (
    <Card>
      <h4 className="title-syncx">
        A Lei Geral de Proteção de Dados - LGPD - permite o uso de dados nas
        seguintes situações:
      </h4>

      <p>
        <strong>I - </strong>Mediante o fornecimento de consentimento pelo
        titular;
      </p>

      <p>
        <strong>II - </strong>Para o cumprimento de obrigação legal ou
        regulatória pelo controlador;
      </p>

      <p>
        <strong>III - </strong>Pela administração pública, para o tratamento e
        uso compartilhado de dados necessários à execução de políticas públicas
        previstas em leis e regulamentos ou respaldadas em contratos, convênios
        ou instrumentos congêneres, observadas as disposições do Capítulo IV
        desta Lei;
      </p>

      <p>
        <strong>IV - </strong>Para a realização de estudos por órgão de
        pesquisa, garantida, sempre que possível, a anonimização dos dados
        pessoais;
      </p>

      <p>
        <strong>V - </strong>Quando necessário para a execução de contrato ou de
        procedimentos preliminares relacionados a contrato do qual seja parte o
        titular, a pedido do titular dos dados;
      </p>

      <p>
        <strong>VI - </strong>Para o exercício regular de direitos em processo
        judicial, administrativo ou arbitral, esse último nos termos da Lei nº
        9.307, de 23 de setembro de 1996 (Lei de Arbitragem);
      </p>

      <p>
        <strong>VII - </strong>Para a proteção de vida ou da incolumidade física
        do titular ou de terceiro;
      </p>

      <p>
        <strong>VIII - </strong>Para a tutela da saúde, exclusivamente, em
        procedimento realizado por profissionais de saúde, serviços de saúde ou
        autoridade sanitária;
      </p>

      <p>
        <strong>IX - </strong>Quando necessário para atender aos interesses
        legítimos do controlador ou de terceiro, exceto no caso de prevalecerem
        direitos e liberdades fundamentais do titular que exijam a proteção dos
        dados pessoais; ou
      </p>

      <p>
        <strong>X - </strong>Para a proteção do crédito, inclusive quanto ao
        disposto na legislação pertinente.
      </p>
    </Card>
  );
};

export default LGPDPage;
