import React from "react";
import { Alert, Badge, Card, Table } from "flowbite-react";
import { CodeBlock, CopyBlock, dracula } from "react-code-blocks";
import TitleStripe from "../common/TitleStripe";

const DocCompletaPlus = ({ token }) => {
  return (
    <Card className={`w-full mt-3`}>
      <div className="mb-4">
        <h2 className="title-syncx">Documentação da API: Completa Plus</h2>
        <br /> Esta documentação descreve como integrar-se à API utilizando um
        método <b>GET</b> com autenticação baseada em token. <br />
        Siga as instruções abaixo para enviar uma solicitação autenticada e
        entender a resposta da API.
        <br />
        <br />
        Endpoint URL:{" "}
        <Badge className="inline" color="indigo">
          https://app.acessopositivo.com.br/api/query/completa-plus/:document
        </Badge>{" "}
        <br />
        Método:{" "}
        <Badge className="inline" color="indigo">
          GET
        </Badge>
        <br />
        Autenticação:{" "}
        <Badge className="inline" color="indigo">
          Bearer Token
        </Badge>
        <br />
        <div className="font-semibold mt-3">Token:</div>
        <CopyBlock text={token} theme={dracula} codeBlock />
        <Alert className="mt-2" color="warning">
          Não divulgue esse token com ninguém! O token é valido por 2 anos. Caso
          o token expire, entrar em contato diretamente com o suporte.
        </Alert>
        <div className="mt-4">
          <TitleStripe>Parâmetros da requisição</TitleStripe>
          <div className="overflow-x-auto shadow-lg rounded-lg mt-0 md:mt-5">
            <Table striped>
              <Table.Head>
                <Table.HeadCell className="text-center bg-gray-300">
                  Parâmetro
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Tipo
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Obrigatório
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Observações
                </Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y">
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    CPF/CNPJ
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    Texto
                  </Table.Cell>
                  <Table.Cell className="text-center text-red-500">
                    Sim
                  </Table.Cell>
                  <Table.Cell className="text-center">
                    Informar o CPF/CNPJ a ser consultado no parâmetro da URL
                    (Somente número).
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        </div>
        <br />
        Obs: Envie o token de autenticação no cabeçalho da requisição.
        <div className="mt-4">
          <TitleStripe>Parâmetros da resposta</TitleStripe>
          <div className="overflow-x-auto shadow-lg rounded-lg mt-0 md:mt-5">
            <Table striped>
              <Table.Head>
                <Table.HeadCell className="text-center bg-gray-300">
                  Chave
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Tipo
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Observações
                </Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y">
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    nome
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    String
                  </Table.Cell>
                  <Table.Cell className="text-center"></Table.Cell>
                </Table.Row>
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    data
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    String
                  </Table.Cell>
                  <Table.Cell className="text-center"></Table.Cell>
                </Table.Row>
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    refin_pefin
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    Array
                  </Table.Cell>
                  <Table.Cell className="text-center"></Table.Cell>
                </Table.Row>
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    SCPC
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    Array
                  </Table.Cell>
                  <Table.Cell className="text-center"></Table.Cell>
                </Table.Row>
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    CADIN
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    Array
                  </Table.Cell>
                  <Table.Cell className="text-center"></Table.Cell>
                </Table.Row>
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    Empresas
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    Array
                  </Table.Cell>
                  <Table.Cell className="text-center"></Table.Cell>
                </Table.Row>
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    Telefones
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    Array
                  </Table.Cell>
                  <Table.Cell className="text-center"></Table.Cell>
                </Table.Row>
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    Protestos
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                    Array
                  </Table.Cell>
                  <Table.Cell className="text-center"></Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        </div>
      </div>
      <CodeBlock
        text={`{
          "doc": "22083403886",
          "nome": "SHEILA CRISTINA GARCIA XAVIER",
          "data": "12/11/2024 17:35:10",
          "refin_pefin": [
              {
                  "data": "10/05/2022",
                  "tipo": " R-CRED FINAN",
                  "aval": " N",
                  "valor": " R$ 113,04",
                  "contrato": " 00000008663501",
                  "origem": " 032390384000192",
                  "empresa": "PROVI SOLUCOES E SERV LTDA",
                  "cidade": ""
              },
              {
                  "data": "12/05/2021",
                  "tipo": " R-OUTRAS OPE",
                  "aval": " N",
                  "valor": " R$ 139,74",
                  "contrato": " 05099044251452",
                  "origem": " 076535764000143",
                  "empresa": "OI SA EM RECUPERACAO JUDICIAL",
                  "cidade": ""
              },
              {
                  "data": "19/06/2020",
                  "tipo": " R-HO ",
                  "aval": " N",
                  "valor": " R$ 710,93",
                  "contrato": " 52634233",
                  "origem": " 051879500000186",
                  "empresa": "CLINICA PIERRO LTDA",
                  "cidade": ""
              }
          ],
          "protestos": [],
          "scpc": [
              {
                  "data": "05/08/2024",
                  "tipo": " T",
                  "nome": " SP-SPO/CLICKNET TELECOMUNICACOES PRO",
                  "valor": " R$ 99,90",
                  "cidade": " Cr�dito SAO PAULO",
                  "uf": " SP",
                  "contrato": " 875993161095",
                  "data_disponivel": ""
              },
              {
                  "data": "10/05/2022",
                  "tipo": " T",
                  "nome": " PROVI SOLUCOES E SERVICOS LTDA",
                  "valor": " R$ 113,04",
                  "cidade": " Cr�dito SAO PAULO",
                  "uf": " SP",
                  "contrato": " 0000000008663501",
                  "data_disponivel": ""
              },
              {
                  "data": "18/01/2022",
                  "tipo": " T",
                  "nome": " CIA PAULISTA DE FORCA E LUZ / CPFL",
                  "valor": " R$ 107,60",
                  "cidade": " Cr�dito SAO PAULO",
                  "uf": " SP",
                  "contrato": " 0000901553645380",
                  "data_disponivel": ""
              },
              {
                  "data": "02/05/2023",
                  "tipo": " T",
                  "nome": " SP-CAS/SEGUNDO CARTORIO DE CAMPINAS",
                  "valor": " R$ 1.650,38",
                  "cidade": " CAMPINAS",
                  "uf": " SP",
                  "contrato": " CAS/CD/",
                  "data_disponivel": ""
              },
              {
                  "data": "19/08/2022",
                  "tipo": " T",
                  "nome": " SP-CAS/PRIMEIRO CARTORIO DE CAMPINAS",
                  "valor": " R$ 113,04",
                  "cidade": " CAMPINAS",
                  "uf": " SP",
                  "contrato": " CAS/CB/",
                  "data_disponivel": ""
              },
              {
                  "data": "21/07/2022",
                  "tipo": " T",
                  "nome": " SP-CAS/PRIMEIRO CARTORIO DE CAMPINAS",
                  "valor": " R$ 251,06",
                  "cidade": " CAMPINAS",
                  "uf": " SP",
                  "contrato": " CAS/DM/",
                  "data_disponivel": ""
              },
              {
                  "data": "25/03/2022",
                  "tipo": " T",
                  "nome": " SP-CAS/SEGUNDO CARTORIO DE CAMPINAS",
                  "valor": " R$ 366,65",
                  "cidade": " CAMPINAS",
                  "uf": " SP",
                  "contrato": " CAS/DM/",
                  "data_disponivel": ""
              },
              {
                  "data": "16/11/2021",
                  "tipo": " T",
                  "nome": " SP-CAS/TERCEIRO CARTORIO DE CAMPINAS",
                  "valor": " R$ 1.248,19",
                  "cidade": " CAMPINAS",
                  "uf": " SP",
                  "contrato": " CAS/CD/",
                  "data_disponivel": ""
              },
              {
                  "data": "19/08/2021",
                  "tipo": " T",
                  "nome": " SP-CAS/TERCEIRO CARTORIO DE CAMPINAS",
                  "valor": " R$ 1.372,82",
                  "cidade": " CAMPINAS",
                  "uf": " SP",
                  "contrato": " CAS/CD/",
                  "data_disponivel": ""
              },
              {
                  "data": "09/03/2021",
                  "tipo": " T",
                  "nome": " SP-CAS/TERCEIRO CARTORIO DE CAMPINAS",
                  "valor": " R$ 302,77",
                  "cidade": " CAMPINAS",
                  "uf": " SP",
                  "contrato": " CAS/DM/",
                  "data_disponivel": ""
              },
              {
                  "data": "22/02/2021",
                  "tipo": " T",
                  "nome": " SP-CAS/SEGUNDO CARTORIO DE CAMPINAS",
                  "valor": " R$ 233,88",
                  "cidade": " CAMPINAS",
                  "uf": " SP",
                  "contrato": " CAS/DM/",
                  "data_disponivel": ""
              },
              {
                  "data": "14/09/2020",
                  "tipo": " T",
                  "nome": " SP-CAS/SEGUNDO CARTORIO DE CAMPINAS",
                  "valor": " R$ 1.674,62",
                  "cidade": " CAMPINAS",
                  "uf": " SP",
                  "contrato": " CAS/CD/",
                  "data_disponivel": ""
              },
              {
                  "data": "12/06/2020",
                  "tipo": " T",
                  "nome": " SP-CAS/TERCEIRO CARTORIO DE CAMPINAS",
                  "valor": " R$ 272,19",
                  "cidade": " CAMPINAS",
                  "uf": " SP",
                  "contrato": " CAS/DM/",
                  "data_disponivel": ""
              },
              {
                  "data": "30/04/2020",
                  "tipo": " T",
                  "nome": " SP-CAS/TERCEIRO CARTORIO DE CAMPINAS",
                  "valor": " R$ 640,00",
                  "cidade": " CAMPINAS",
                  "uf": " SP",
                  "contrato": " CAS/DM/",
                  "data_disponivel": ""
              },
              {
                  "data": "15/04/2020",
                  "tipo": " T",
                  "nome": " SP-CAS/PRIMEIRO CARTORIO DE CAMPINAS",
                  "valor": " R$ 85,18",
                  "cidade": " CAMPINAS",
                  "uf": " SP",
                  "contrato": " CAS/CD/",
                  "data_disponivel": ""
              },
              {
                  "data": "27/02/2020",
                  "tipo": " T",
                  "nome": " SP-CAS/TERCEIRO CARTORIO DE CAMPINAS",
                  "valor": " R$ 640,00",
                  "cidade": " CAMPINAS",
                  "uf": " SP",
                  "contrato": " CAS/DM/",
                  "data_disponivel": ""
              }
          ],
          "cadin": [],
          "dados_gerais": {
              "primeira_ocorrencia": "19/06/2020",
              "ultima_ocorrencia": "10/05/2022",
              "quantidade": 3
          },
          "cheques": [],
          "telefones": [
              {
                  "DDD": "19",
                  "TELEFONE": "981761059",
                  "TIPO_TELEFONE": "3",
                  "TIPO_DS": "TELEFONE MÓVEL",
                  "DT_INCLUSAO": "27/07/2017",
                  "DT_ATUALIZACAO": null,
                  "DT_INFORMACAO": null,
                  "FONE_NOTA": "11",
                  "SIGILO": null,
                  "PRIORIDADE": "1",
                  "CLASSIFICACAO": "A2",
                  "CLASSIFICACAO_DS": "BASE DE PARCERIAS"
              },
              {
                  "DDD": "19",
                  "TELEFONE": "981573662",
                  "TIPO_TELEFONE": "3",
                  "TIPO_DS": "TELEFONE MÓVEL",
                  "DT_INCLUSAO": "27/07/2017",
                  "DT_ATUALIZACAO": null,
                  "DT_INFORMACAO": null,
                  "FONE_NOTA": "8",
                  "SIGILO": null,
                  "PRIORIDADE": "2",
                  "CLASSIFICACAO": "A2",
                  "CLASSIFICACAO_DS": "BASE DE PARCERIAS"
              },
              {
                  "DDD": "19",
                  "TELEFONE": "38384226",
                  "TIPO_TELEFONE": "1",
                  "TIPO_DS": "TELEFONE RESIDENCIAL",
                  "DT_INCLUSAO": "27/07/2017",
                  "DT_ATUALIZACAO": null,
                  "DT_INFORMACAO": null,
                  "FONE_NOTA": "10",
                  "SIGILO": null,
                  "PRIORIDADE": "3",
                  "CLASSIFICACAO": "A2",
                  "CLASSIFICACAO_DS": "BASE DE PARCERIAS"
              },
              {
                  "DDD": "19",
                  "TELEFONE": "32132493",
                  "TIPO_TELEFONE": "1",
                  "TIPO_DS": "TELEFONE RESIDENCIAL",
                  "DT_INCLUSAO": "07/12/2015",
                  "DT_ATUALIZACAO": "06/12/2018",
                  "DT_INFORMACAO": "20/04/2015",
                  "FONE_NOTA": "10",
                  "SIGILO": null,
                  "PRIORIDADE": "4",
                  "CLASSIFICACAO": "B",
                  "CLASSIFICACAO_DS": "TELEFONE ATIVO TELCO"
              },
              {
                  "DDD": "19",
                  "TELEFONE": "32545900",
                  "TIPO_TELEFONE": "1",
                  "TIPO_DS": "TELEFONE RESIDENCIAL",
                  "DT_INCLUSAO": "02/12/2009",
                  "DT_ATUALIZACAO": "31/03/2015",
                  "DT_INFORMACAO": "26/02/2003",
                  "FONE_NOTA": "10",
                  "SIGILO": "0",
                  "PRIORIDADE": "5",
                  "CLASSIFICACAO": "C",
                  "CLASSIFICACAO_DS": "TELEFONE VALIDO (TOCA)"
              }
          ],
          "enderecos": [
              {
                  "LOGR_TIPO": "AV",
                  "LOGR_TITULO": "",
                  "LOGR_NOME": "JOAO ERBOLATO",
                  "LOGR_NUMERO": "861",
                  "LOGR_COMPLEMENTO": "BL MELOTTI GEOVANI",
                  "BAIRRO": "JD CHAPADAO",
                  "CIDADE": "CAMPINAS",
                  "UF": "SP",
                  "CEP": "13070071",
                  "CEP_NOTA": "10",
                  "ENDERECO": "AV JOAO ERBOLATO 861 BL MELOTTI GEOVANI",
                  "DT_INCLUSAO": "16/05/2019",
                  "DT_ATUALIZACAO": "30/08/2019",
                  "DT_INFORMACAO": null,
                  "LATITUDE": "-22.887933540000000",
                  "LONGITUDE": "-47.085762920000000",
                  "PRIORIDADE": "1",
                  "CLASSIFICACAO": "B",
                  "TIPO_ENDERECO_ID": "Comercial"
              },
              {
                  "LOGR_TIPO": "AV",
                  "LOGR_TITULO": "",
                  "LOGR_NOME": "JOAO ERBOLATO",
                  "LOGR_NUMERO": "861",
                  "LOGR_COMPLEMENTO": "AP 54",
                  "BAIRRO": "JD CHAPADAO",
                  "CIDADE": "CAMPINAS",
                  "UF": "SP",
                  "CEP": "13070071",
                  "CEP_NOTA": "10",
                  "ENDERECO": "AV JOAO ERBOLATO 861 AP 54",
                  "DT_INCLUSAO": "04/04/2017",
                  "DT_ATUALIZACAO": "12/08/2019",
                  "DT_INFORMACAO": "08/08/2019",
                  "LATITUDE": "-22.887933540000000",
                  "LONGITUDE": "-47.085762920000000",
                  "PRIORIDADE": "2",
                  "CLASSIFICACAO": "B",
                  "TIPO_ENDERECO_ID": "Residencial"
              },
              {
                  "LOGR_TIPO": "R",
                  "LOGR_TITULO": "",
                  "LOGR_NOME": "RAUL DE CASTRO",
                  "LOGR_NUMERO": "33",
                  "LOGR_COMPLEMENTO": "CS 1",
                  "BAIRRO": "JD CHAPADAO",
                  "CIDADE": "CAMPINAS",
                  "UF": "SP",
                  "CEP": "13070082",
                  "CEP_NOTA": "10",
                  "ENDERECO": "R RAUL DE CASTRO 33 CS 1",
                  "DT_INCLUSAO": "08/12/2015",
                  "DT_ATUALIZACAO": "12/12/2018",
                  "DT_INFORMACAO": "20/04/2015",
                  "LATITUDE": "-22.887980390000000",
                  "LONGITUDE": "-47.079526980000000",
                  "PRIORIDADE": "3",
                  "CLASSIFICACAO": "B",
                  "TIPO_ENDERECO_ID": "Residencial"
              },
              {
                  "LOGR_TIPO": "AV",
                  "LOGR_TITULO": "",
                  "LOGR_NOME": "JOAO ERBOLATO",
                  "LOGR_NUMERO": "835",
                  "LOGR_COMPLEMENTO": "",
                  "BAIRRO": "JD CHAPADAO",
                  "CIDADE": "CAMPINAS",
                  "UF": "SP",
                  "CEP": "13070071",
                  "CEP_NOTA": "6",
                  "ENDERECO": "AV JOAO ERBOLATO 835",
                  "DT_INCLUSAO": "09/12/2014",
                  "DT_ATUALIZACAO": null,
                  "DT_INFORMACAO": "01/03/2014",
                  "LATITUDE": "-22.888082110000000",
                  "LONGITUDE": "-47.085200490000000",
                  "PRIORIDADE": "4",
                  "CLASSIFICACAO": "B",
                  "TIPO_ENDERECO_ID": "Comercial"
              },
              {
                  "LOGR_TIPO": "AV",
                  "LOGR_TITULO": "STO",
                  "LOGR_NOME": "IRINEU",
                  "LOGR_NUMERO": "671",
                  "LOGR_COMPLEMENTO": "BL 3 AP 22",
                  "BAIRRO": "JD DAS OLIVEIRAS NOVA VENEZA",
                  "CIDADE": "SUMARE",
                  "UF": "SP",
                  "CEP": "13180170",
                  "CEP_NOTA": "7",
                  "ENDERECO": "AV STO IRINEU 671 BL 3 AP 22",
                  "DT_INCLUSAO": "09/12/2014",
                  "DT_ATUALIZACAO": "19/03/2015",
                  "DT_INFORMACAO": null,
                  "LATITUDE": "-22.835812650000000",
                  "LONGITUDE": "-47.159469790000000",
                  "PRIORIDADE": "5",
                  "CLASSIFICACAO": "B",
                  "TIPO_ENDERECO_ID": "Residencial"
              }
          ],
          "empresas": [
              {
                  "cnpj": "14813733000150",
                  "razaosocial": "GARCIA E PEREIRA SERV ADMINISTRATIVOS LTDA",
                  "fantasia": "GARCIA E PEREIRA SERV ADMINISTRATIVOS LTDA",
                  "fundacao": "2011-12-19 00:00:00.000",
                  "cidade": "CAMPINAS",
                  "uf": "SP",
                  "cnae": "82199",
                  "cnaesecun": "8219999",
                  "porcentagem": "1.00"
              },
              {
                  "cnpj": "10690081000152",
                  "razaosocial": "SHEILA CRISTINA GARCIA",
                  "fantasia": "SC CONSULTORIA",
                  "fundacao": "2009-02-02 00:00:00.000",
                  "cidade": "CAMPINAS",
                  "uf": "SP",
                  "cnae": "70204",
                  "cnaesecun": "7020400",
                  "porcentagem": "100.00"
              }
          ]
      }`}
        language={"json"}
        showLineNumbers={true}
        wrapLines
        theme={dracula}
      />
    </Card>
  );
};

export default DocCompletaPlus;
