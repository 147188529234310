import { AiOutlineLoading } from "react-icons/ai";
import { Accordion, Alert, Button } from "flowbite-react";
import { useForm } from "react-hook-form";
import { ADMIN, CATEGORIES, PROFESSIONALS } from "../../config/apiConfig";
import axios from "../../config/axiosInstance";
import { useEffect, useState } from "react";
import Input from "../inputs/Input";
import DrawerLoading from "../DrawerLoading";
import SelectInput from "../inputs/SelectInput";

const QueryEditForm = ({ categories, query, fetchCompanies, onClose }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [key, setKey] = useState(0);
  const [error, setError] = useState(null);

  const {
    register,
    getValues,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset();
    setValue("price", query?.price);
    setValue("providerId", query?.providerId);
    setValue("categoryId", query?.categoryId);
  }, [query]);

  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);

      let newArrayQueries = [];

      data.categories.forEach((cat) => {
        cat.queries.forEach((query) => {
          newArrayQueries = [...newArrayQueries, query];
        });
      });

      data.queries = newArrayQueries.filter((query) => query.isActive);

      // await axios.put(
      //   `${ADMIN.GET_COMPANY_QUERIES}/${company.id}`,
      //   data.queries
      // );

      onClose(false);

      //fetchProfessionals();
    } catch (error) {
      setError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <form className="mb-6" onSubmit={handleSubmit(onSubmit)}>
        <div className="font-semibold mb-5">Consulta: {query?.name}</div>
        <div className="mb-6 flex flex-col gap-4">
          <Input
            type="number"
            label="Preço base"
            step="0.01"
            register={register}
            name="price"
          />

          <SelectInput label="Provedor" register={register} name="providerId">
            <option value={1}>Boa Vista</option>
            <option value={2}>Consult Positivo</option>
            <option value={3}>Quod</option>
          </SelectInput>

          <SelectInput label="Categoria" register={register} name="categoryId">
            {categories?.map((category, idx) => (
              <option value={category.id}>{category.name}</option>
            ))}
          </SelectInput>
        </div>
        <Button className="w-full" type="submit">
          Editar consulta
        </Button>
      </form>
    </>
  );
};

export default QueryEditForm;
