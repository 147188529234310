import React from "react";
import { Card } from "flowbite-react";

const CardLoading = () => {
  return (
    <Card>
      <div role="status" className="max-w-sm animate-pulse">
        <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
        <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
        <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
      </div>
    </Card>
  );
};

export default CardLoading;
