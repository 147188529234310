import moment from "moment";
import React, { useState } from "react";
import FilterToolbar from "../inputs/FilterToolbar";
import { Badge, Button, Card, Pagination, Table, Tabs } from "flowbite-react";
import { LuCalendarClock } from "react-icons/lu";
import { FaArrowsLeftRight } from "react-icons/fa6";
import axios from "../../config/axiosInstance";
import { useQuery } from "react-query";
import { ADMIN } from "../../config/apiConfig";
import LastMonthsSelect from "../inputs/LastMonthsSelect";
import { formatToReal } from "../../utils/utils";
import EmptyTable from "./EmptyTable";

const AdminPaymentsTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const onPageChange = (page) => setCurrentPage(page);
  const [period, setPeriod] = useState(moment().format("YYYY-MM"));
  const [isVisibleFilter, setIsVisibleFilter] = useState(false);
  const [statusId, setStatusId] = useState(0);
  const [isPaid, setIsPaid] = useState(false);

  const {
    isLoading: isLoadingInvoices,
    error: errorInvoices,
    data: invoices,
  } = useQuery(["invoices", period, isPaid, currentPage], async () => {
    const { data } = await axios.get(
      `${ADMIN.GET_PAYMENTS}?period=${period}&isPaid=${isPaid}&page=${currentPage}`
    );

    setTotalPages(data.data.totalPages);

    return data.data.data;
  });

  return (
    <>
      <FilterToolbar
        title="Selecione o mês:"
        icon={<LuCalendarClock className="text-lg" />}
        isVisible={isVisibleFilter}
        setIsVisible={setIsVisibleFilter}
        style={{ background: "var(--bg-black)" }}
      >
        <div className="flex flex-col md:flex-row items-start md:items-center gap-2">
          <LastMonthsSelect onChange={(e) => setPeriod(e.target.value)} />
        </div>
      </FilterToolbar>
      <div className="bg-white p-4 mt-4 shadow-md rounded-md">
        <small
          className="gap-2 items-center mt-5 mb-1 flex md:hidden justify-center"
          style={{ fontSize: 10 }}
        >
          <span>Deslize para o lado para visualizar todas as informações</span>{" "}
          <FaArrowsLeftRight />
        </small>
        <Tabs
          aria-label="Tabs with underline"
          variant="underline"
          onActiveTabChange={(tab) => {
            setStatusId(tab);
            setIsPaid(() => (tab === 0 ? false : true));
            setCurrentPage(1);
          }}
        >
          <Tabs.Item active={statusId === 0} title="Pendentes">
            {invoices?.length > 0 ? (
              <div className="overflow-x-auto shadow-lg rounded-lg mt-0 md:mt-1">
                <Table striped>
                  {invoices?.length > 0 ? (
                    <Table.Head>
                      <Table.HeadCell className="text-center bg-gray-300">
                        Data de criação
                      </Table.HeadCell>
                      <Table.HeadCell className="text-center bg-gray-300">
                        Data de vencimento
                      </Table.HeadCell>
                      <Table.HeadCell className="text-center bg-gray-300">
                        Valor
                      </Table.HeadCell>
                      <Table.HeadCell className="text-center bg-gray-300">
                        Método
                      </Table.HeadCell>
                      <Table.HeadCell className="text-center bg-gray-300">
                        Cliente
                      </Table.HeadCell>
                      <Table.HeadCell className="text-center bg-gray-300">
                        Ações
                      </Table.HeadCell>
                    </Table.Head>
                  ) : (
                    <></>
                  )}

                  <Table.Body className="divide-y">
                    {!isLoadingInvoices ? (
                      <>
                        {invoices?.length > 0 && (
                          <>
                            {invoices.map((invoice, index) => (
                              <Table.Row
                                key={index}
                                className="bg-white dark:border-gray-700 dark:bg-gray-800"
                              >
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                  {moment(invoice.createdAt).format(
                                    "DD/MM/YY | HH:mm"
                                  )}
                                  h
                                </Table.Cell>
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                  {moment(invoice.dueDate).isBefore(
                                    moment()
                                  ) ? (
                                    <Badge
                                      color="failure"
                                      className="w-fit mx-auto"
                                    >
                                      {Math.abs(
                                        moment(invoice.dueDate).diff(
                                          moment(),
                                          "days"
                                        )
                                      ) === 0
                                        ? "Vence hoje"
                                        : `Vencido há ${Math.abs(
                                            moment(invoice.dueDate).diff(
                                              moment(),
                                              "days"
                                            )
                                          )} dias`}
                                    </Badge>
                                  ) : (
                                    <Badge
                                      className="w-fit mx-auto"
                                      color="success"
                                    >
                                      Vence em{" "}
                                      {moment(invoice.dueDate).diff(
                                        moment(),
                                        "days"
                                      ) + 1}{" "}
                                      dias
                                    </Badge>
                                  )}
                                </Table.Cell>
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                  {formatToReal(invoice.price)}
                                </Table.Cell>
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                  {invoice.type}
                                </Table.Cell>
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                  {invoice.Company?.name}
                                </Table.Cell>
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                  {invoice.paymentLink ? (
                                    <a
                                      className="bg-primary rounded text-white p-2"
                                      href={invoice.paymentLink}
                                      target="_blank"
                                    >
                                      Link de pagamento
                                    </a>
                                  ) : (
                                    <div>-</div>
                                  )}
                                </Table.Cell>
                              </Table.Row>
                            ))}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {Array.from({ length: 12 }).map((_, index) => (
                          <Table.Row
                            key={index}
                            className="bg-white dark:border-gray-700 dark:bg-gray-800"
                          >
                            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                              <div
                                role="status"
                                className="max-w-sm animate-pulse inline"
                              >
                                <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                              </div>
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </>
                    )}
                  </Table.Body>
                </Table>
              </div>
            ) : (
              <EmptyTable icon="search_off">
                {" "}
                Nenhum pagamento até o momento.
              </EmptyTable>
            )}
          </Tabs.Item>
          <Tabs.Item active={statusId === 1} title="Pagos">
            {invoices?.length > 0 ? (
              <div className="overflow-x-auto shadow-lg rounded-lg mt-0 md:mt-1">
                <Table striped>
                  {invoices?.length > 0 ? (
                    <Table.Head>
                      <Table.HeadCell className="text-center bg-gray-300">
                        Data de criação
                      </Table.HeadCell>
                      <Table.HeadCell className="text-center bg-gray-300">
                        Data de pagamento
                      </Table.HeadCell>
                      <Table.HeadCell className="text-center bg-gray-300">
                        Valor
                      </Table.HeadCell>
                      <Table.HeadCell className="text-center bg-gray-300">
                        Método
                      </Table.HeadCell>
                      <Table.HeadCell className="text-center bg-gray-300">
                        Cliente
                      </Table.HeadCell>
                    </Table.Head>
                  ) : (
                    <></>
                  )}

                  <Table.Body className="divide-y">
                    {!isLoadingInvoices ? (
                      <>
                        {invoices?.length > 0 && (
                          <>
                            {invoices.map((invoice, index) => (
                              <Table.Row
                                key={index}
                                className="bg-white dark:border-gray-700 dark:bg-gray-800"
                              >
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                  {moment(invoice.createdAt).format(
                                    "DD/MM/YY | HH:mm"
                                  )}
                                  h
                                </Table.Cell>
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                  {moment(invoice.updatedAt).format("DD/MM/YY")}
                                </Table.Cell>
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                  {formatToReal(invoice.price)}
                                </Table.Cell>
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                  {invoice.type}
                                </Table.Cell>
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                  {invoice.Company?.name}
                                </Table.Cell>
                              </Table.Row>
                            ))}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {Array.from({ length: 12 }).map((_, index) => (
                          <Table.Row
                            key={index}
                            className="bg-white dark:border-gray-700 dark:bg-gray-800"
                          >
                            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                              <div
                                role="status"
                                className="max-w-sm animate-pulse inline"
                              >
                                <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                              </div>
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </>
                    )}
                  </Table.Body>
                </Table>
              </div>
            ) : (
              <EmptyTable icon="search_off">
                {" "}
                Nenhum pagamento até o momento.
              </EmptyTable>
            )}
          </Tabs.Item>
        </Tabs>
      </div>
      {totalPages > 1 && (
        <div className="flex overflow-x-auto justify-center mt-3">
          <Pagination
            layout="pagination"
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
            previousLabel=""
            nextLabel=""
            showIcons
          />
        </div>
      )}
    </>
  );
};

export default AdminPaymentsTable;
