import React, { useEffect, useState } from "react";
import useLogin from "../hooks/useLogin";
import { checkIfAuthenticated } from "../utils/utils";
import { LOGIN } from "../config/apiConfig";
import { GoAlertFill } from "react-icons/go";
import { Button } from "flowbite-react";
import { AiOutlineLoading } from "react-icons/ai";

const LoginPage = () => {
  if (checkIfAuthenticated()) {
    window.location.href = "/dashboard";
  }
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { loading, error, isLoggedIn, login } = useLogin(LOGIN.POST_LOGIN);
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await login(email, password);

      if (isLoggedIn) window.location.href = "/dashboard";
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  return (
    <section className="flex flex-col md:flex-row" style={{ height: "100vh" }}>
      <div className="h-full bg-primary-300 p-8 rounded-xl flex-1 relative">
        <div className="relative">
          <img src="/assets/logo_acesso.png" className="h-10" />

          <img
            src="/assets/icon_login.gif"
            className="mx-auto mt-14"
            style={{ width: "350px" }}
            loading="lazy"
          />
          <div className=" text-white text-center mt-5">
            <div className="font-semibold text-4xl">
              Seja bem vindo ao <br /> Sistema da{" "}
              <span className="color-black">Acesso Positivo!</span>
            </div>
            <div className="mt-4">
              O sistema completo para consultas de crédito, veículo e cadastro.
              <br />
              Acesse de forma simples e segura para obter informações essenciais
              e tomar decisões com confiança.
            </div>
          </div>
        </div>
      </div>
      <div className="flex-1 h-full bg-white p-8 rounded-lg">
        <div className="font-semibold text-2xl mb-4 mt-10">Login</div>
        <div className=" text-base mb-10">
          Faça o login para utilizar o sistema.
        </div>
        <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
          <div>
            <label
              for="username"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              E-mail
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <label
              for="password"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Senha
            </label>
            <input
              type="password"
              name="password"
              id="password"
              placeholder="••••••••"
              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          {error && (
            <div className="bg-red-200 rounded-sm px-1 text-xs py-1 flex gap-2 items-center text-red-600">
              <GoAlertFill color="var(--primary)" />{" "}
              <span>
                {error?.response?.data?.message ||
                  "Erro ao tentar realizar o login!"}
              </span>
            </div>
          )}
          <div className="flex items-center justify-end">
            <a
              href="#"
              className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
            >
              Esqueceu a senha?
            </a>
          </div>
          <Button
            type="submit"
            className="w-fit text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-10 py-1 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 primary"
            isProcessing={loading}
            processingSpinner={
              <AiOutlineLoading className="h-6 w-6 animate-spin" />
            }
            disabled={loading}
          >
            Entrar
          </Button>
        </form>
      </div>
      {/* <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <a
          href="#"
          className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
        >
          <img className="h-12 mt-10" src="/assets/logo_acesso.png" alt="logo" />
        </a>
        <div
          className="w-full bg-white rounded-lg shadow-lg dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700"
          style={{ maxWidth: "600px"}}
        >
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Entre na sua conta
            </h1>
            <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
              <div>
                <label
                  for="username"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  E-mail
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div>
                <label
                  for="password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Senha
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="••••••••"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              {error && (
                <div className="bg-red-200 rounded-sm px-1 text-xs py-1 flex gap-2 items-center text-red-600">
                  <GoAlertFill color="var(--primary)" />{" "}
                  <span>Usuário ou senha incorreto.</span>
                </div>
              )}
              <div className="flex items-center justify-end">
                <a
                  href="#"
                  className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
                >
                  Esqueceu a senha?
                </a>
              </div>
              <Button
                type="submit"
                className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-1 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 primary"
                isProcessing={loading}
                processingSpinner={
                  <AiOutlineLoading className="h-6 w-6 animate-spin" />
                }
                disabled={loading}
              >
                Entrar
              </Button>
            </form>
          </div>
        </div>
      </div> */}
    </section>
  );
};

export default LoginPage;
