const TitleStripe = ({ children, className }) => {
  return (
    <div
      className={`px-2 py-1 bg-gray-200 font-semibold mt-5 text-lg ${className}`}
      style={{ "font-variant": "small-caps" }}
    >
      {children}
    </div>
  );
};

export default TitleStripe;
