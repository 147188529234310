import {
  Alert,
  Button,
  Card,
  Label,
  Table,
  TextInput,
  Timeline,
} from "flowbite-react";
import React, { useEffect, useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { FaSearch } from "react-icons/fa";
import { isValidCPF } from "../../utils/utils";
import axios from "../../config/axiosInstance";
import { QUERIES } from "../../config/apiConfig";
import { maskCPF } from "../../utils/masks";
import { HiInformationCircle } from "react-icons/hi";
import HeaderQuery from "./HeaderQuery";
import CardQueryDescription from "../common/CardQueryDescription";
import QueryLoading from "../common/QueryLoading";
import TitleStripe from "../common/TitleStripe";

const CadastralPFQuery = ({ query, selectedPrice }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [error, setError] = useState("");
  const [errorQuery, setErrorQuery] = useState("");
  const [data, setData] = useState(null);
  const [partnerships, setPartnerships] = useState([]);

  const handleChange = (event) => {
    let valor = event.target.value.replace(/\D/g, "");

    valor = maskCPF(valor);

    setInfo(valor);
  };

  const fetchInfo = async (raw) => {
    return await axios(
      `${QUERIES.GET_CADASTRAL_PF}/${info.replace(/\D/g, "")}`
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const unmaskedValue = info.replace(/\D/g, "");
    let isValid = true;

    if (!isValidCPF(unmaskedValue)) {
      setError("CPF inválido!");
      isValid = false;
    }

    if (isValid) {
      try {
        setData(null);
        setIsLoading(true);
        setError("");
        setErrorQuery("");
        const { data: dados } = await fetchInfo();
        setData(
          dados.data["QuodReportResponseEx"].Response.Records.Record[0].BestInfo
        );
        setPartnerships(
          dados.data["QuodReportResponseEx"].Response.Records.Record[0]
            .EnterpriseData.Partnerships.Partnership
        );
      } catch (error) {
        setErrorQuery(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta"
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <div className="text-lg font-semibold mb-3 title-syncx">
        {query?.name}
      </div>
      <CardQueryDescription
        title="Informações da consulta"
        infos={[
          "Dados Cadastrais",
          "Dados profissionais",
          "Endereços",
          "E-mails",
          "Telefones",
        ]}
        price={selectedPrice}
      >
        <form
          className="flex flex-col gap-2 bg-white p-2 mt-2 rounded-lg shadow-md w-fit"
          onSubmit={handleSubmit}
        >
          <div className="flex gap-2 items-end">
            <div className="flex items-center gap-2">
              <div className="block">
                <Label htmlFor="info" value="CPF:" />
              </div>
              <TextInput
                id="info"
                type="text"
                required
                onChange={handleChange}
                maxLength="14"
                value={info}
              />
            </div>
            <Button
              className=" shadow-lg primary flex items-center justify-center"
              type="submit"
              isProcessing={isLoading}
              processingSpinner={<CgSpinner className="h-5 w-5 animate-spin" />}
              style={{ height: "43px" }}
            >
              <FaSearch />
            </Button>
          </div>
          {error && <small className="text-red-600">{error}</small>}
        </form>
      </CardQueryDescription>
      {errorQuery ? (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorQuery}</span>
        </Alert>
      ) : (
        <div className={`${data || isLoading ? "block" : "hidden"}`}>
          {!isLoading ? (
            <Card>
              <HeaderQuery title={query?.name} />
              <TitleStripe>Dados cadastrais</TitleStripe>
              <div className="flex gap-16 text-sm">
                <div>
                  <div className="mb-3">
                    <span className="font-semibold">Status do CPF: </span>
                    {data?.CPFStatus || "Sem info."}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">Nome completo: </span>
                    {data?.PersonName?.Name?.Full || "Sem info."}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">CPF: </span>
                    {info}
                  </div>

                  <div className="mb-3">
                    <span className="font-semibold">Sexo: </span>
                    {data?.Gender || "Sem info."}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">Data de nascimento: </span>
                    {data?.DOB?.Day}/{data?.DOB?.Month}/{data?.DOB?.Year}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">Nome da mãe: </span>
                    {data?.MotherName?.Full || "Sem info."}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">Celular: </span>
                    {data?.MobilePhoneNumber?.PhoneNumber || "Sem info."}
                  </div>
                </div>
                <div>
                  <div className="mb-3">
                    <span className="font-semibold">E-mail: </span>
                    {data?.Email?.Email || "Sem info."}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">Endereço: </span>
                    {data?.Address?.Street} Nº {data?.Address?.Number}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">Complemento: </span>
                    {data?.Address?.Complement || "Sem info."}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">Bairro: </span>
                    {data?.Address?.Neighborhood || "Sem info."}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">Cidade/UF: </span>
                    {data?.Address?.City || "Sem info."}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">CEP: </span>
                    {data?.Address?.PostalCode || "Sem info."}
                  </div>
                </div>
              </div>

              <div>
                <TitleStripe className="mb-5">
                  Informações profissionais
                </TitleStripe>
                {partnerships?.length > 0 ? (
                  <Timeline>
                    <>
                      {partnerships?.map((partnership, index) => (
                        <Timeline.Item key={index}>
                          <Timeline.Point />
                          <Timeline.Content>
                            <Timeline.Time>
                              Data de início:{" "}
                              {partnership.DateStartPartner?.Day}/
                              {partnership.DateStartPartner?.Month}/
                              {partnership.DateStartPartner?.Year}
                            </Timeline.Time>
                            <Timeline.Title>
                              {partnership.CompanyName}
                            </Timeline.Title>
                            <Timeline.Body>
                              <div className="flex items-center gap-1">
                                <span>CNPJ:</span>
                                <span>{partnership.CNPJ || "Sem info."}</span>
                              </div>
                              <div className="flex items-center gap-1">
                                <span>% de participação:</span>
                                <span>
                                  {partnership.PercentParticipation ||
                                    "Sem info."}
                                </span>
                              </div>
                              <div className="flex items-center gap-1">
                                <span>Status:</span>
                                <span>
                                  {partnership.CNPJStatus?.toLowerCase() ||
                                    "Sem info."}
                                </span>
                              </div>
                              <div className="flex items-center gap-1">
                                <span>Cargo:</span>
                                <span>
                                  {partnership.RelationshipDescription?.toLowerCase() ||
                                    "Sem info."}
                                </span>
                              </div>
                            </Timeline.Body>
                          </Timeline.Content>
                        </Timeline.Item>
                      ))}
                    </>
                  </Timeline>
                ) : (
                  <Alert color="gray" className="text-base mb-5">
                    Nada consta
                  </Alert>
                )}
              </div>

              <div>
                <TitleStripe className="mb-3 -mt-5">
                  Histórico de endereços
                </TitleStripe>
                {data?.AddressHistory?.Address?.length > 0 ? (
                  <>
                    <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                      <Table striped>
                        <Table.Head>
                          <Table.HeadCell className="bg-gray-300">
                            Endereço
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-gray-300">
                            Complemento
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-gray-300">
                            Bairro
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-gray-300">
                            Cidade:
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-gray-300">
                            CEP:
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-gray-300">
                            Data:
                          </Table.HeadCell>
                        </Table.Head>

                        <Table.Body className="divide-y">
                          {data?.AddressHistory?.Address?.map(
                            (address, index) => (
                              <Table.Row
                                key={index}
                                className="bg-white dark:border-gray-700 dark:bg-gray-800"
                              >
                                <Table.Cell className="font-medium text-gray-900 dark:text-white">
                                  {address.Street} Nº {address.Number}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {address.Complement}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {address.Neighborhood}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {address.City}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {address.PostalCode}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {address.DateLastSeen?.Day}/
                                  {address.DateLastSeen?.Month}/
                                  {address.DateLastSeen?.Year}
                                </Table.Cell>
                              </Table.Row>
                            )
                          )}
                        </Table.Body>
                      </Table>
                    </div>
                  </>
                ) : (
                  <Alert>Nada consta</Alert>
                )}
              </div>
              <div>
                <TitleStripe className="mb-3">Histórico de e-mails</TitleStripe>
                {data?.EmailHistory?.Email?.length > 0 ? (
                  <>
                    <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                      <Table striped>
                        <Table.Head>
                          <Table.HeadCell className="bg-gray-300">
                            E-mail
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-gray-300">
                            Data:
                          </Table.HeadCell>
                        </Table.Head>

                        <Table.Body className="divide-y">
                          {data?.EmailHistory?.Email?.map((email, index) => (
                            <Table.Row
                              key={index}
                              className="bg-white dark:border-gray-700 dark:bg-gray-800"
                            >
                              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                {email.Email}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {email.DateLastSeen?.Day}/
                                {email.DateLastSeen?.Month}/
                                {email.DateLastSeen?.Year}
                              </Table.Cell>
                            </Table.Row>
                          ))}
                        </Table.Body>
                      </Table>
                    </div>
                  </>
                ) : (
                  <Alert>Nada consta</Alert>
                )}
              </div>
              <div>
                <TitleStripe className="mb-3">
                  Histórico de números de telefone
                </TitleStripe>
                {data?.PhoneNumberHistory?.PhoneNumber?.length > 0 ? (
                  <>
                    <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                      <Table striped>
                        <Table.Head>
                          <Table.HeadCell className="bg-gray-300">
                            Telefone
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-gray-300">
                            Data:
                          </Table.HeadCell>
                        </Table.Head>

                        <Table.Body className="divide-y">
                          {data?.PhoneNumberHistory?.PhoneNumber?.map(
                            (phoneNumber, index) => (
                              <Table.Row
                                key={index}
                                className="bg-white dark:border-gray-700 dark:bg-gray-800"
                              >
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                  {phoneNumber.PhoneNumber}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {phoneNumber.DateLastSeen?.Day}/
                                  {phoneNumber.DateLastSeen?.Month}/
                                  {phoneNumber.DateLastSeen?.Year}
                                </Table.Cell>
                              </Table.Row>
                            )
                          )}
                        </Table.Body>
                      </Table>
                    </div>
                  </>
                ) : (
                  <Alert>Nada consta</Alert>
                )}
              </div>
              <div>
                <TitleStripe className="mb-3">
                  Histórico de números de celular
                </TitleStripe>
                {data?.MobilePhoneNumberHistory?.MobilePhoneNumber?.length >
                0 ? (
                  <>
                    <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                      <Table striped>
                        <Table.Head>
                          <Table.HeadCell className="bg-gray-300">
                            Celular
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-gray-300">
                            Data:
                          </Table.HeadCell>
                        </Table.Head>

                        <Table.Body className="divide-y">
                          {data?.MobilePhoneNumberHistory?.MobilePhoneNumber?.map(
                            (phoneNumber, index) => (
                              <Table.Row
                                key={index}
                                className="bg-white dark:border-gray-700 dark:bg-gray-800"
                              >
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                  {phoneNumber.PhoneNumber}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {phoneNumber.DateLastSeen?.Day}/
                                  {phoneNumber.DateLastSeen?.Month}/
                                  {phoneNumber.DateLastSeen?.Year}
                                </Table.Cell>
                              </Table.Row>
                            )
                          )}
                        </Table.Body>
                      </Table>
                    </div>
                  </>
                ) : (
                  <Alert>Nada consta</Alert>
                )}
              </div>
            </Card>
          ) : (
            <QueryLoading />
          )}
        </div>
      )}
    </>
  );
};

export default CadastralPFQuery;
