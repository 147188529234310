import React, { useEffect, useState } from "react";
import { QUERIES } from "../config/apiConfig";
import axios from "../config/axiosInstance";
import { uploadPDF } from "../utils/utils";

const usePDF = (componentRef, info, queryId, data) => {
  const [pdfURL, setPdfURL] = useState("");

  const handleClickDownloadPDF = async () => {
    const { data } = await axios.get(
      `${QUERIES.DOWNLOAD_PDF}?fileName=${pdfURL}`
    );
    window.open(data.fileUrl);
  };

  useEffect(() => {
    const upload = async () => {
      const response = await uploadPDF(componentRef, info, queryId);
      setPdfURL(response?.data?.file?.fileName || "");
    };

    setTimeout(() => {
      if (data) {
        upload();
      }
    }, 2000);
  }, [data]);

  return { pdfURL, setPdfURL, handleClickDownloadPDF };
};

export default usePDF;
