import { Navigate } from "react-router-dom";
import { checkIfAuthenticated } from "../utils/utils";

const RequireAuth = ({ children }) => {
  if (!checkIfAuthenticated()) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default RequireAuth;
