const SEX = { 0: "Não informado", 1: "Masculino", 2: "Feminino" };

const CIVIL_STATE = {
  0: "Não informado",
  1: "Casado",
  2: "Solteiro",
  3: "Viúvo",
  4: "Divorciado / Desquitado",
  5: "Separado",
  6: "Companheiro / União Estável",
  9: "Outros",
};

const DEGREE_OF_EDUCATION = {
  0: "Não informado",
  1: "Analfabeto",
  2: "Ensino fundamental incompleto",
  3: "Ensino fundamental completo",
  4: "Ensino médio incompleto",
  5: "Ensino médio completo",
  6: "Superior incompleto",
  7: "Superior completo",
  8: "Pós graduação completa",
  9: "Pós graduação incompleta",
  10: "Mestrado completo",
  11: "Mestrado incompleto",
  12: "Doutorado completo",
  13: "Doutorado incompleto",
};

const SITUATION = {
  "-3": "Não especificado",
  "-2": "Não especificado",
  "-1": "Não especificado",
  0: "Titular falecido",
  1: "Regular",
  2: "Cancelado",
  3: "Pendente de regularização",
  4: "Suspenso",
  5: "Inexistente",
  6: "Dados indisponíveis",
  7: "Nulo",
  9: "Não especificado",
};

const DOCUMENT_ORIGIN = {
    0: "RS",
    1: "DF, GO, MS, MT, TO",
    2: "AC, AM, AP, PA, RO, RR",
    3: "CE, MA, PI",
    4: "AL, PB, PE, RN",
    5: "BA, SE",
    6: "MG",
    7: "ES, RJ",
    8: "SP",
    9: "PR, SC"
}

export const BOA_VISTA_CONTANTS = {
  SEX,
  CIVIL_STATE,
  DEGREE_OF_EDUCATION,
  SITUATION,
  DOCUMENT_ORIGIN
};
