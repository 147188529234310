import {
  Alert,
  Badge,
  Button,
  Card,
  Label,
  Spinner,
  Table,
  TextInput,
  Timeline,
} from "flowbite-react";
import React, { useRef, useState } from "react";
import { FaHandPointDown, FaRegCheckCircle, FaSearch } from "react-icons/fa";
import { IoAlertCircleOutline } from "react-icons/io5";
import {
  formatToReal,
  isValidCNPJ,
  maskCEP,
  maskStateSubscription,
} from "../../utils/utils";
import ReactApexChart from "react-apexcharts";
import axios from "../../config/axiosInstance";
import { QUERIES } from "../../config/apiConfig";
import { HiInformationCircle } from "react-icons/hi";
import HeaderQuery from "./HeaderQuery";
import { MdOutlinePerson } from "react-icons/md";
import { IoMdBusiness, IoMdCheckmarkCircleOutline } from "react-icons/io";
import usePDF from "../../hooks/usePDF";
import TitleStripe from "../common/TitleStripe";
import CardQueryDescription from "../common/CardQueryDescription";
import QueryInput from "../inputs/QueryInput";
import { FiAlertOctagon } from "react-icons/fi";
import SliderToPreview from "../common/SliderToPreview";
import QueryLoading from "../common/QueryLoading";

const DefineLimitePositivoQuery = ({ query, selectedPrice }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [document, setDocument] = useState("");
  const [error, setError] = useState("");
  const [errorData, setErrorData] = useState("");
  const [data, setData] = useState(null);
  const [infoProtestos, setInfoProtestos] = useState(null);
  const [isDoneRequest, setIsDoneRequest] = useState(false);
  const [isLoadingProtestos, setIsLoadingProtestos] = useState(false);
  const componentRef = useRef();
  const { handleClickDownloadPDF, pdfURL, setPdfURL } = usePDF(
    componentRef,
    info,
    query?.id,
    isDoneRequest
  );
  const fetchDefineLimite = async (raw) => {
    return await axios(
      `${QUERIES.GET_DEFINE_LIMITE_POSITIVO}/${info.replace(/\D/g, "")}`
    );
  };

  const fetchInfoProtestos = async (raw) => {
    return await axios(
      `${QUERIES.GET_PROTESTO_NACIONAL}/${info.replace(/\D/g, "")}`
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDocument(info);
    const unmaskedValue = info.replace(/\D/g, "");
    let isValid = true;

    if (!isValidCNPJ(unmaskedValue)) {
      setError("CNPJ inválido!");
      isValid = false;
    }

    if (isValid) {
      try {
        setPdfURL("");
        setData(null);
        setIsLoading(true);
        setInfoProtestos(null);
        setIsLoadingProtestos(true);
        setIsDoneRequest(false);
        setError("");
        setErrorData("");
        const { data: dados } = await fetchDefineLimite();
        setData(dados.data["defineLimitePositivo"]);
        setIsLoading(false);
        const protestos = await fetchInfoProtestos();
        setInfoProtestos(protestos);
        setIsLoadingProtestos(false);
      } catch (error) {
        setErrorData(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta"
        );
        setIsLoading(false);
      } finally {
        setIsDoneRequest(true);
      }
    }
  };

  const calcMarkNegotiation = () => {
    switch (data?.decisao?.descricao?.toLowerCase()) {
      case "negociação não recomendada":
        return "14%";
      case "negociação em análise":
        return "48%";
      case "negociação recomendada":
        return "81%";
      default:
        return false;
    }
  };

  return (
    <div>
      <div className="text-lg font-semibold mb-3 title-syncx">
        {query?.name}
      </div>
      <CardQueryDescription
        title="Informações da consulta"
        infos={[
          "Dados Cadastrais",
          "Score",
          "Faturamento presumido",
          "Falência/Recuperação",
          "Ações cíveis",
          "Pendências financeiras",
          "Protestos",
          "Cheques sem fundos",
          "Cheques sustados",
        ]}
        price={selectedPrice}
      >
        <form
          className="flex flex-col gap-2 bg-white p-2 mt-2 rounded-lg shadow-md w-fit"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col md:flex-row gap-2 items-start md:items-end">
            <QueryInput
              info={info}
              setInfo={setInfo}
              isLoading={isLoading}
              error={error}
              type="CNPJ"
            />
            {!errorData && data && (
              <Button
                className={`w-full md:w-fit items-center shadow-lg secondary ${
                  data && !isLoading ? "flex" : "hidden"
                }`}
                isProcessing={pdfURL === ""}
                disabled={pdfURL === ""}
                onClick={() => handleClickDownloadPDF()}
              >
                Salvar como PDF
              </Button>
            )}
          </div>
        </form>
      </CardQueryDescription>

      <SliderToPreview />

      {errorData ? (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorData}</span>
        </Alert>
      ) : (
        <div
          className={`overflow-x-scroll ${
            data || isLoading ? "block" : "hidden"
          }`}
          ref={componentRef}
        >
          {!isLoading ? (
            <>
              <Card className="card-pdf mt-2 p-5">
                <HeaderQuery title={query?.name} />
                <TitleStripe>Dados Cadastrais</TitleStripe>
                <div className="mt-2">
                  <div className="flex gap-16">
                    <div className="text-sm flex-1">
                      <div className="mb-3 flex-1">
                        <span className="font-semibold">Razão Social: </span>
                        {data?.identificacao_completo?.razao_social ||
                          "Sem info."}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Nome Fantasia: </span>
                        {data?.identificacao_completo?.nome_fantasia || "-"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">
                          Natureza Jurídica:{" "}
                        </span>
                        {data?.identificacao_completo?.natureza_juridica || "-"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">CNPJ/MF: </span>
                        {document}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">I.E.: </span>
                        {data?.identificacao_completo?.inscricao_estadual ||
                          "-"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Segmento: </span>
                        {data?.identificacao_completo?.segmento || "-"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">CNAE Principal: </span>
                        {data?.identificacao_completo?.ramo_atividade_primario
                          ?.atividade || "-"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Fundação: </span>
                        {data?.identificacao_completo?.data_fundacao || "-"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Capital Social: </span>
                        {formatToReal(
                          data?.identificacao_completo?.capital_atual
                        ) || "-"}
                      </div>
                    </div>
                    <div className="text-sm flex-1">
                      <div className="mb-3 flex gap-2 items-center">
                        <span className="font-semibold">Situação: </span>
                        <Badge
                          className="w-fit"
                          color={
                            data?.identificacao_completo?.situacao_cnpj ===
                            "Ativo"
                              ? "success"
                              : "gray"
                          }
                        >
                          {data?.identificacao_completo?.situacao_cnpj}
                        </Badge>
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Endereço: </span>
                        {data?.localizacao_completo?.matriz?.endereco_matriz ||
                          "-"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Bairro: </span>
                        {data?.localizacao_completo?.matriz?.bairro_matriz ||
                          "-"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Cidade/UF: </span>
                        {data?.localizacao_completo?.matriz?.cidade_matriz}/
                        {data?.localizacao_completo?.matriz?.uf_matriz}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">CEP: </span>
                        {maskCEP(
                          data?.localizacao_completo?.matriz?.cep_matriz
                        ) || "-"}
                      </div>
                      <div className="mb-3 mt-12">
                        <span className="font-semibold">
                          Faturamento Anual:{" "}
                        </span>
                        {data?.faturamento_presumido?.faturamento_anual || "-"}
                      </div>
                      <div>{data?.faturamento_presumido?.mensagem}</div>
                    </div>
                  </div>
                </div>
                <TitleStripe>Quadro de Avisos</TitleStripe>
                <div className="container-alerts five-columns">
                  {data?.cheques_sem_fundo?.mensagem ? (
                    <Alert
                      color="success"
                      withBorderAccent
                      className="flex justify-center items-center flex-col"
                    >
                      <IoMdCheckmarkCircleOutline
                        fontSize={30}
                        className="mx-auto mb-1"
                      />
                      <div className="text-center">Quadro societário</div>
                      <div className="text-center font-semibold">
                        Nada consta
                      </div>
                    </Alert>
                  ) : (
                    <Alert
                      color="failure"
                      withBorderAccent
                      className="flex justify-center items-center flex-col"
                    >
                      <FiAlertOctagon fontSize={30} className="mx-auto mb-1" />
                      <div className="text-center">Quadro societário</div>
                      <div className="text-center font-semibold">
                        {data?.cheques_sem_fundo?.total_cheques_sem_fundos}{" "}
                        ocorrências
                      </div>
                    </Alert>
                  )}

                  {data?.cheques_sem_fundo?.mensagem ? (
                    <Alert
                      color="success"
                      withBorderAccent
                      className="flex justify-center items-center flex-col"
                    >
                      <IoMdCheckmarkCircleOutline
                        fontSize={30}
                        className="mx-auto mb-1"
                      />
                      <div className="text-center">Empresas co-ligadas</div>
                      <div className="text-center font-semibold">
                        Nada consta
                      </div>
                    </Alert>
                  ) : (
                    <Alert
                      color="failure"
                      withBorderAccent
                      className="flex justify-center items-center flex-col"
                    >
                      <FiAlertOctagon fontSize={30} className="mx-auto mb-1" />
                      <div className="text-center">Empresas co-ligadas</div>
                      <div className="text-center font-semibold">
                        {data?.cheques_sem_fundo?.total_cheques_sem_fundos}{" "}
                        ocorrências
                      </div>
                    </Alert>
                  )}
                  {data?.cheques_sem_fundo?.mensagem ? (
                    <Alert
                      color="success"
                      withBorderAccent
                      className="flex justify-center items-center flex-col"
                    >
                      <IoMdCheckmarkCircleOutline
                        fontSize={30}
                        className="mx-auto mb-1"
                      />
                      <div className="text-center">Cheques sem fundo</div>
                      <div className="text-center font-semibold">
                        Nada consta
                      </div>
                    </Alert>
                  ) : (
                    <Alert
                      color="failure"
                      withBorderAccent
                      className="flex justify-center items-center flex-col"
                    >
                      <FiAlertOctagon fontSize={30} className="mx-auto mb-1" />
                      <div className="text-center">Cheques sem fundo</div>
                      <div className="text-center font-semibold">
                        {data?.cheques_sem_fundo?.total_cheques_sem_fundos}{" "}
                        ocorrências
                      </div>
                    </Alert>
                  )}

                  {data?.cheques_sustados?.mensagem ? (
                    <Alert
                      color="success"
                      withBorderAccent
                      className="flex justify-center items-center flex-col"
                    >
                      <IoMdCheckmarkCircleOutline
                        fontSize={30}
                        className="mx-auto mb-1"
                      />
                      <div className="text-center">Cheques sustados</div>
                      <div className="text-center font-semibold">
                        Nada consta
                      </div>
                    </Alert>
                  ) : (
                    <Alert
                      color="failure"
                      withBorderAccent
                      className="flex justify-center items-center flex-col"
                    >
                      <FiAlertOctagon fontSize={30} className="mx-auto mb-1" />
                      <div className="text-center">Cheques sustados</div>
                      <div className="text-center font-semibold">
                        {data?.cheques_sustados?.total} ocorrências
                      </div>
                    </Alert>
                  )}

                  {data?.pendencias_restricoes?.mensagem ? (
                    <Alert
                      color="success"
                      withBorderAccent
                      className="flex justify-center items-center flex-col"
                    >
                      <IoMdCheckmarkCircleOutline
                        fontSize={30}
                        className="mx-auto mb-1"
                      />
                      <div className="text-center">Pendências financeiras</div>
                      <div className="text-center font-semibold">
                        Nada consta
                      </div>
                    </Alert>
                  ) : (
                    <Alert
                      color="failure"
                      withBorderAccent
                      className="flex justify-center items-center flex-col"
                    >
                      <FiAlertOctagon fontSize={30} className="mx-auto mb-1" />
                      <div className="text-center">Pendências financeiras</div>
                      <div className="text-center font-semibold">
                        {data?.pendencias_restricoes?.total_pendencias}{" "}
                        ocorrências
                      </div>
                    </Alert>
                  )}

                  {data?.protestos?.mensagem ? (
                    <Alert
                      color="success"
                      withBorderAccent
                      className="flex justify-center items-center flex-col"
                    >
                      <IoMdCheckmarkCircleOutline
                        fontSize={30}
                        className="mx-auto mb-1"
                      />
                      <div className="text-center">Títulos protestados</div>
                      <div className="text-center font-semibold">
                        Nada consta
                      </div>
                    </Alert>
                  ) : (
                    <Alert
                      color="failure"
                      withBorderAccent
                      className="flex justify-center items-center flex-col"
                    >
                      <FiAlertOctagon fontSize={30} className="mx-auto mb-1" />
                      <div className="text-center">Títulos protestados</div>
                      <div className="text-center font-semibold">
                        {data?.protestos?.total_protestos} ocorrências
                      </div>
                    </Alert>
                  )}

                  {data?.acoes_civeis?.mensagem ? (
                    <Alert
                      color="success"
                      withBorderAccent
                      className="flex justify-center items-center flex-col"
                    >
                      <IoMdCheckmarkCircleOutline
                        fontSize={30}
                        className="mx-auto mb-1"
                      />
                      <div className="text-center">Ações cíveis</div>
                      <div className="text-center font-semibold">
                        Nada consta
                      </div>
                    </Alert>
                  ) : (
                    <Alert
                      color="failure"
                      withBorderAccent
                      className="flex justify-center items-center flex-col"
                    >
                      <FiAlertOctagon fontSize={30} className="mx-auto mb-1" />
                      <div className="text-center">Ações cíveis</div>
                      <div className="text-center font-semibold">
                        {data?.acoes_civeis?.total_acoes} ocorrências
                      </div>
                    </Alert>
                  )}

                  {data?.falencias_recuperacao_judicial?.mensagem ? (
                    <Alert
                      color="success"
                      withBorderAccent
                      className="flex justify-center items-center flex-col"
                    >
                      <IoMdCheckmarkCircleOutline
                        fontSize={30}
                        className="mx-auto mb-1"
                      />
                      <div className="text-center">Falência/Recuperação</div>
                      <div className="text-center font-semibold">
                        Nada consta
                      </div>
                    </Alert>
                  ) : (
                    <Alert
                      color="failure"
                      withBorderAccent
                      className="flex justify-center items-center flex-col"
                    >
                      <FiAlertOctagon fontSize={30} className="mx-auto mb-1" />
                      <div className="text-center">Falência/Recuperação</div>
                      <div className="text-center font-semibold">
                        {data?.falencias_recuperacao_judicial?.total_falencia}{" "}
                        ocorrências
                      </div>
                    </Alert>
                  )}

                  {isLoadingProtestos ? (
                    <Alert
                      color="gray"
                      withBorderAccent
                      className="flex justify-center items-center flex-col"
                    >
                      <div className="text-center">
                        <Spinner aria-label="Large spinner example" size="md" />
                      </div>
                      <div className="text-center mt-2">
                        Protestos dir. cartório
                      </div>
                      <div role="status" className="w-full animate-pulse">
                        <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mt-1"></div>
                      </div>
                    </Alert>
                  ) : (
                    <>
                      {+infoProtestos?.data?.data?.CREDCADASTRAL
                        ?.PROTESTO_SINTETICO?.QUANTIDADE_OCORRENCIA === 0 ? (
                        <Alert
                          color="success"
                          withBorderAccent
                          className="flex justify-center items-center flex-col"
                        >
                          <IoMdCheckmarkCircleOutline
                            fontSize={30}
                            className="mx-auto mb-1"
                          />
                          <div className="text-center">
                            Protestos dir. cartório
                          </div>
                          <div className="text-center font-semibold">
                            Nada consta
                          </div>
                        </Alert>
                      ) : (
                        <Alert
                          color="failure"
                          withBorderAccent
                          className="flex justify-center items-center flex-col"
                        >
                          <FiAlertOctagon
                            fontSize={30}
                            className="mx-auto mb-1"
                          />
                          <div className="text-center">
                            Protestos dir. cartório
                          </div>
                          {infoProtestos?.data?.data?.CREDCADASTRAL
                            ?.PROTESTO_SINTETICO ? (
                            <div className="text-center font-semibold">
                              {
                                infoProtestos?.data?.data?.CREDCADASTRAL
                                  ?.PROTESTO_SINTETICO?.QUANTIDADE_OCORRENCIA
                              }{" "}
                              ocorrências
                            </div>
                          ) : (
                            <div className="text-center font-semibold">
                              Erro na consulta
                            </div>
                          )}
                        </Alert>
                      )}
                    </>
                  )}
                </div>

                <TitleStripe>Negociação</TitleStripe>
                {calcMarkNegotiation() ? (
                  <>
                    {" "}
                    <div className="relative mt-10 mb-1">
                      <div className="flex overflow-hidden rounded-lg w-full shadow-md relative">
                        <div
                          className="bg-red-500 p-3 flex-1 text-center text-sm font-semibold text-red-900 outline outline-green-200"
                          style={{
                            background:
                              "linear-gradient(90deg, rgba(255,26,26,1) 0%, rgba(255,238,64,1) 100%)",
                          }}
                        >
                          Não recomendada
                        </div>
                        <div
                          className="bg-yellow-300 p-3 flex-1 text-center text-sm font-semibold text-yellow-900"
                          style={{
                            background:
                              "linear-gradient(90deg, rgba(255,236,64,1) 0%, rgba(255,236,64,1) 68%, rgba(190,221,95,1) 88%, rgba(48,212,113,1) 100%)",
                          }}
                        >
                          Em análise
                        </div>
                        <div
                          className="bg-green-400 p-3 flex-1 text-center text-sm font-semibold text-green-900"
                          style={{
                            background:
                              "linear-gradient(90deg, rgba(50,213,114,1) 0%, rgba(44,175,95,1) 100%)",
                          }}
                        >
                          Recomendada
                        </div>
                      </div>
                      <div
                        className="absolute -top-10"
                        style={{
                          left: `calc(${calcMarkNegotiation()})`,
                        }}
                      >
                        <FaHandPointDown
                          fontSize={30}
                          className="text-gray-500"
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div>{data?.decisao?.descricao}</div>
                )}

                <div>
                  <TitleStripe>Score de Risco</TitleStripe>
                </div>
                <div className="flex gap-2 items-center">
                  <div className="w-3/5 p-4">
                    <ReactApexChart
                      options={{
                        chart: {
                          type: "radialBar",
                          offsetY: -20,
                          sparkline: {
                            enabled: true,
                          },
                        },
                        plotOptions: {
                          radialBar: {
                            startAngle: -90,
                            endAngle: 90,
                            track: {
                              background: "#e7e7e7",
                              strokeWidth: "97%",
                              margin: 5, // margin is in pixels
                              dropShadow: {
                                enabled: true,
                                top: 2,
                                left: 0,
                                color: "#999",
                                opacity: 1,
                                blur: 2,
                              },
                            },
                            dataLabels: {
                              name: {
                                show: false,
                              },
                              value: {
                                offsetY: -2,
                                fontSize: "22px",
                                formatter: function (val) {
                                  return val * 10;
                                },
                              },
                            },
                          },
                        },
                        grid: {
                          padding: {
                            top: -10,
                          },
                        },
                        fill: {
                          type: "solid",
                          gradient: {
                            shade: "light",
                            shadeIntensity: 0.4,
                            inverseColors: false,
                            opacityFrom: 1,
                            opacityTo: 1,
                            stops: [0, 50, 53, 91],
                          },
                          colors: (() => {
                            const score = +data?.score?.score_pj.score;
                            if (score >= 800) {
                              return "#01B000";
                            } else if (score >= 600) {
                              return "#8DED01";
                            } else if (score >= 400) {
                              return "#FDF700";
                            } else if (score >= 200) {
                              return "#FFBC00";
                            } else {
                              return "#c7001b";
                            }
                          })(),
                        },
                      }}
                      series={[+data?.score?.score_pj.score / 10]}
                      type="radialBar"
                    />
                  </div>
                  <div className="w-full">
                    <div className="mb-2">
                      <Alert className="w-fit mb-2">
                        Probabilidade de Inadimplência ={" "}
                        {data?.score?.score_pj?.probabilidade}%
                      </Alert>{" "}
                      {data?.score?.score_pj?.texto}
                    </div>
                  </div>
                </div>
                <Alert color="warning">
                  <span className="font-semibold">Importante:</span> a decisão
                  de aprovação ou não do crédito é de exclusiva responsabilidade
                  do concedente. as informações prestadas pelo provedor tem como
                  objevo subsidiar essas decisões e, em hipótese alguma devem
                  ser ulizadas como justificativa, pelo concedente do crédito,
                  para a tomada da referida decisão.
                </Alert>

                <div>
                  <TitleStripe className="mb-5">Limite de Crédito</TitleStripe>
                  <div className="mt-2">
                    {data?.limite_credito?.score !== "" ? (
                      <Alert
                        className="text-lg font-semibold shadow"
                        withBorderAccent
                        additionalContent={
                          <div className="text-sm font-normal">
                            {data?.limite_credito?.texto}
                          </div>
                        }
                      >
                        {formatToReal(data?.limite_credito?.score)}
                      </Alert>
                    ) : (
                      <Alert className="mt-2" color="failure" withBorderAccent>
                        {data?.limite_credito?.texto}
                      </Alert>
                    )}
                  </div>
                </div>

                <div>
                  <TitleStripe className="mb-5">Quadro Societário</TitleStripe>
                  {!data?.socios?.mensagem ? (
                    <div className="overflow-x-auto mt-3 mb-5 rounded-lg border border-gray-300">
                      <Table striped>
                        <Table.Head className="text-black">
                          <Table.HeadCell
                            className="text-center py-2 px-1"
                            style={{ background: "#76b9fc" }}
                          >
                            Nº
                          </Table.HeadCell>
                          <Table.HeadCell
                            className="py-2"
                            style={{ background: "#76b9fc" }}
                          >
                            Nome
                          </Table.HeadCell>
                          <Table.HeadCell
                            className="text-center py-2 px-1"
                            style={{ background: "#76b9fc" }}
                          >
                            Data <br /> Entrada
                          </Table.HeadCell>
                          <Table.HeadCell
                            className="text-center py-2 px-1"
                            style={{ background: "#76b9fc" }}
                          >
                            Part. (%)
                          </Table.HeadCell>
                          <Table.HeadCell
                            className="text-center py-2 px-1"
                            style={{ background: "#76b9fc" }}
                          >
                            Assina <br /> Empresa?
                            <br /> (S/N)
                          </Table.HeadCell>
                          <Table.HeadCell
                            className="text-center py-2 px-1"
                            style={{ background: "#76b9fc" }}
                          >
                            Débitos
                            <br />
                            (S/N)
                          </Table.HeadCell>
                          <Table.HeadCell
                            className="py-2"
                            style={{ background: "#76b9fc" }}
                          >
                            Pendências
                          </Table.HeadCell>
                        </Table.Head>

                        <Table.Body className="divide-y">
                          {Array.isArray(data?.socios?.socio) ? (
                            <>
                              {data?.socios?.socio?.map((socio, index) => (
                                <Table.Row
                                  key={index}
                                  className="bg-white dark:border-gray-700 dark:bg-gray-800 text-black"
                                >
                                  <Table.Cell className="font-medium dark:text-white text-center py-1 px-1">
                                    {index + 1}
                                  </Table.Cell>
                                  <Table.Cell className=" py-1">
                                    <div>{socio.nome_empresa}</div>
                                    <div style={{ fontSize: "10px" }}>
                                      CPF/CNPJ: {socio.cpf_cnpj}
                                    </div>
                                  </Table.Cell>
                                  <Table.Cell className="text-center py-1 px-1">
                                    {socio.data_entrada}
                                  </Table.Cell>
                                  <Table.Cell className="text-center py-1 px-1 px-1">
                                    {socio.participacao}
                                  </Table.Cell>
                                  <Table.Cell className="text-center py-1">
                                    {socio.assina_empresa}
                                  </Table.Cell>
                                  <Table.Cell className="text-center py-1 px-1">
                                    {socio.indicador_debito?.toLowerCase() ===
                                    "sim" ? (
                                      <div className="text-red-600 mx-auto">
                                        {socio.indicador_debito}
                                      </div>
                                    ) : (
                                      <div className="text-blue-600 mx-auto font-semibold">
                                        {socio.indicador_debito}
                                      </div>
                                    )}
                                  </Table.Cell>
                                  <Table.Cell className="py-1 text-2xs text-black">
                                    {socio?.indicador_debito?.toUpperCase() ===
                                    "SIM" ? (
                                      <>
                                        {socio?.restricoes_documento
                                          ?.cheques_sem_fundo?.quantidade !==
                                          "0" && (
                                          <div className="uppercase">
                                            Cheques Sem Fundos:{" "}
                                            <span className="text-red-500 font-semibold">
                                              {formatToReal(
                                                socio?.restricoes_documento?.cheques_sem_fundo?.valor?.replace(
                                                  ",",
                                                  "."
                                                )
                                              )}
                                            </span>
                                          </div>
                                        )}

                                        {socio?.restricoes_documento?.protestos
                                          ?.quantidade !== "0" && (
                                          <div className="uppercase">
                                            Protestos:{" "}
                                            <span className="text-red-500 font-semibold">
                                              {formatToReal(
                                                socio?.restricoes_documento?.protestos?.valor?.replace(
                                                  ",",
                                                  "."
                                                )
                                              )}
                                            </span>
                                          </div>
                                        )}

                                        {socio?.restricoes_documento
                                          ?.pendencias_restricoes
                                          ?.quantidade !== "0" && (
                                          <div className="uppercase">
                                            Pendências:{" "}
                                            <span className="text-red-500 font-semibold">
                                              {formatToReal(
                                                socio?.restricoes_documento?.pendencias_restricoes?.valor?.replace(
                                                  ",",
                                                  "."
                                                )
                                              )}
                                            </span>
                                          </div>
                                        )}

                                        {socio?.restricoes_documento
                                          ?.acoes_judiciais?.quantidade !==
                                          "0" && (
                                          <div className="uppercase">
                                            Ações cíveis:{" "}
                                            <span className="text-red-500 font-semibold">
                                              {formatToReal(
                                                socio?.restricoes_documento?.acoes_judiciais?.valor?.replace(
                                                  ",",
                                                  "."
                                                )
                                              )}
                                            </span>
                                          </div>
                                        )}

                                        {socio?.restricoes_documento
                                          ?.falencias_recuperacao_judicial
                                          ?.quantidade !== "0" && (
                                          <div className="uppercase">
                                            Rec. Judicial/Falências:{" "}
                                            <span className="text-red-500 font-semibold">
                                              {formatToReal(
                                                socio?.restricoes_documento?.falencias_recuperacao_judicial?.valor?.replace(
                                                  ",",
                                                  "."
                                                )
                                              )}
                                            </span>
                                          </div>
                                        )}
                                      </>
                                    ) : (
                                      <div className="text-blue-500">
                                        Não há débitos
                                      </div>
                                    )}
                                  </Table.Cell>
                                </Table.Row>
                              ))}
                            </>
                          ) : (
                            <>
                              <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800 text-black">
                                <Table.Cell className="font-medium dark:text-white text-center">
                                  {1}
                                </Table.Cell>
                                <Table.Cell>
                                  <div>{data?.socios?.socio?.nome_empresa}</div>
                                  <div style={{ fontSize: "10px" }}>
                                    CPF/CNPJ: {data?.socios?.socio?.cpf_cnpj}
                                  </div>
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {data?.socios?.socio?.data_entrada}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {data?.socios?.socio?.participacao}
                                </Table.Cell>
                                <Table.Cell className="text-center py-1 px-1">
                                  {data?.socios?.socio?.indicador_debito?.toLowerCase() ===
                                  "sim" ? (
                                    <div className="text-red-600 mx-auto">
                                      {data?.socios?.socio?.indicador_debito}
                                    </div>
                                  ) : (
                                    <div className="text-blue-600 mx-auto font-semibold">
                                      {data?.socios?.socio?.indicador_debito}
                                    </div>
                                  )}
                                </Table.Cell>
                                <Table.Cell className="py-1 text-2xs text-black">
                                  {data?.socios?.socio?.indicador_debito?.toUpperCase() ===
                                  "SIM" ? (
                                    <>
                                      {data?.socios?.socio?.restricoes_documento
                                        ?.cheques_sem_fundo?.quantidade !==
                                        "0" && (
                                        <div className="uppercase">
                                          Cheques Sem Fundos:{" "}
                                          <span className="text-red-500 font-semibold">
                                            {formatToReal(
                                              data?.socios?.socio?.restricoes_documento?.cheques_sem_fundo?.valor?.replace(
                                                ",",
                                                "."
                                              )
                                            )}
                                          </span>
                                        </div>
                                      )}

                                      {data?.socios?.socio?.restricoes_documento
                                        ?.protestos?.quantidade !== "0" && (
                                        <div className="uppercase">
                                          Protestos:{" "}
                                          <span className="text-red-500 font-semibold">
                                            {formatToReal(
                                              data?.socios?.socio?.restricoes_documento?.protestos?.valor?.replace(
                                                ",",
                                                "."
                                              )
                                            )}
                                          </span>
                                        </div>
                                      )}

                                      {data?.socios?.socio?.restricoes_documento
                                        ?.pendencias_restricoes?.quantidade !==
                                        "0" && (
                                        <div className="uppercase">
                                          Pendências:{" "}
                                          <span className="text-red-500 font-semibold">
                                            {formatToReal(
                                              data?.socios?.socio?.restricoes_documento?.pendencias_restricoes?.valor?.replace(
                                                ",",
                                                "."
                                              )
                                            )}
                                          </span>
                                        </div>
                                      )}

                                      {data?.socios?.socio?.restricoes_documento
                                        ?.acoes_judiciais?.quantidade !==
                                        "0" && (
                                        <div className="uppercase">
                                          Ações cíveis:{" "}
                                          <span className="text-red-500 font-semibold">
                                            {formatToReal(
                                              data?.socios?.socio?.restricoes_documento?.acoes_judiciais?.valor?.replace(
                                                ",",
                                                "."
                                              )
                                            )}
                                          </span>
                                        </div>
                                      )}

                                      {data?.socios?.socio?.restricoes_documento
                                        ?.falencias_recuperacao_judicial
                                        ?.quantidade !== "0" && (
                                        <div className="uppercase">
                                          Rec. Judicial/Falências:{" "}
                                          <span className="text-red-500 font-semibold">
                                            {formatToReal(
                                              data?.socios?.socio?.restricoes_documento?.falencias_recuperacao_judicial?.valor?.replace(
                                                ",",
                                                "."
                                              )
                                            )}
                                          </span>
                                        </div>
                                      )}
                                    </>
                                  ) : (
                                    <div className="text-blue-500">
                                      Não há débitos
                                    </div>
                                  )}
                                </Table.Cell>
                                <Table.Cell className="py-1 text-2xs text-black">
                                  {data?.socios?.socio?.indicador_debito?.toUpperCase() ===
                                  "SIM" ? (
                                    <>
                                      {data?.socios?.socio?.restricoes_documento
                                        ?.cheques_sem_fundo?.quantidade !==
                                        "0" && (
                                        <div className="uppercase">
                                          Cheques Sem Fundos:{" "}
                                          <span className="text-red-500 font-semibold">
                                            {formatToReal(
                                              data?.socios?.socio?.restricoes_documento?.cheques_sem_fundo?.valor?.replace(
                                                ",",
                                                "."
                                              )
                                            )}
                                          </span>
                                        </div>
                                      )}

                                      {data?.socios?.socio?.restricoes_documento
                                        ?.protestos?.quantidade !== "0" && (
                                        <div className="uppercase">
                                          Protestos:{" "}
                                          <span className="text-red-500 font-semibold">
                                            {formatToReal(
                                              data?.socios?.socio?.restricoes_documento?.protestos?.valor?.replace(
                                                ",",
                                                "."
                                              )
                                            )}
                                          </span>
                                        </div>
                                      )}

                                      {data?.socios?.socio?.restricoes_documento
                                        ?.pendencias_restricoes?.quantidade !==
                                        "0" && (
                                        <div className="uppercase">
                                          Pendências:{" "}
                                          <span className="text-red-500 font-semibold">
                                            {formatToReal(
                                              data?.socios?.socio?.restricoes_documento?.pendencias_restricoes?.valor?.replace(
                                                ",",
                                                "."
                                              )
                                            )}
                                          </span>
                                        </div>
                                      )}

                                      {data?.socios?.socio?.restricoes_documento
                                        ?.acoes_judiciais?.quantidade !==
                                        "0" && (
                                        <div className="uppercase">
                                          Ações cíveis:{" "}
                                          <span className="text-red-500 font-semibold">
                                            {formatToReal(
                                              data?.socios?.socio?.restricoes_documento?.acoes_judiciais?.valor?.replace(
                                                ",",
                                                "."
                                              )
                                            )}
                                          </span>
                                        </div>
                                      )}

                                      {data?.socios?.socio?.restricoes_documento
                                        ?.falencias_recuperacao_judicial
                                        ?.quantidade !== "0" && (
                                        <div className="uppercase">
                                          Rec. Judicial/Falências:{" "}
                                          <span className="text-red-500 font-semibold">
                                            {formatToReal(
                                              data?.socios?.socio?.restricoes_documento?.falencias_recuperacao_judicial?.valor?.replace(
                                                ",",
                                                "."
                                              )
                                            )}
                                          </span>
                                        </div>
                                      )}
                                    </>
                                  ) : (
                                    <div className="text-blue-500">
                                      Não há débitos
                                    </div>
                                  )}
                                </Table.Cell>
                              </Table.Row>
                            </>
                          )}
                        </Table.Body>
                      </Table>
                    </div>
                  ) : (
                    <Card className={`bg-green-200 mt-4 mb-5`}>
                      <div className="flex gap-2 items-center text-gray-600 font-semibold">
                        <div>
                          <IoAlertCircleOutline fontSize={20} />
                        </div>
                        <div>Nada consta</div>
                      </div>
                    </Card>
                  )}
                </div>

                <div>
                  <TitleStripe className="mb-5">
                    {" "}
                    Participações dos Sócios e Administradores em Outras
                    Empresas
                  </TitleStripe>
                  {!data?.participacoes_socios_adm_outras_empresas?.mensagem ? (
                    <>
                      {Array.isArray(
                        data?.participacoes_socios_adm_outras_empresas?.socio
                      ) ? (
                        <>
                          {data?.participacoes_socios_adm_outras_empresas?.socio?.map(
                            (socioP, index) => (
                              <div key={index} className="mt-10">
                                <div className="font-semibold mb-1 text-blue-600 text-center underline">
                                  {socioP.nome_socio}
                                </div>

                                <div className="overflow-x-auto mt-3 mb-5 rounded-lg border border-gray-300">
                                  <Table striped>
                                    <Table.Head className="text-black">
                                      <Table.HeadCell
                                        className="text-center py-2 px-1"
                                        style={{ background: "#76b9fc" }}
                                      >
                                        Nº
                                      </Table.HeadCell>
                                      <Table.HeadCell
                                        className="py-2"
                                        style={{ background: "#76b9fc" }}
                                      >
                                        Denominação Social
                                      </Table.HeadCell>
                                      <Table.HeadCell
                                        className="text-center py-2 px-1"
                                        style={{ background: "#76b9fc" }}
                                      >
                                        Data <br /> Entrada
                                      </Table.HeadCell>
                                      <Table.HeadCell
                                        className="text-center py-2 px-1"
                                        style={{ background: "#76b9fc" }}
                                      >
                                        Part. (%)
                                      </Table.HeadCell>
                                      <Table.HeadCell
                                        className="text-center py-2 px-1"
                                        style={{ background: "#76b9fc" }}
                                      >
                                        Situação
                                      </Table.HeadCell>
                                      <Table.HeadCell
                                        className="py-2"
                                        style={{ background: "#76b9fc" }}
                                      >
                                        Pendências
                                      </Table.HeadCell>
                                    </Table.Head>

                                    <Table.Body className="divide-y">
                                      {Array.isArray(
                                        socioP?.socios_participantes
                                      ) ? (
                                        <>
                                          {socioP?.socios_participantes?.map(
                                            (socio, index) => (
                                              <Table.Row
                                                key={index}
                                                className="bg-white dark:border-gray-700 dark:bg-gray-800 text-black"
                                              >
                                                <Table.Cell className="font-medium dark:text-white text-center py-1 px-1">
                                                  {index + 1}
                                                </Table.Cell>
                                                <Table.Cell className=" py-1 w-96">
                                                  <div>
                                                    {socio.razao_social}
                                                  </div>
                                                  <div
                                                    style={{ fontSize: "10px" }}
                                                  >
                                                    CNPJ: {socio.cnpj}
                                                  </div>
                                                </Table.Cell>
                                                <Table.Cell className="text-center py-1 px-1">
                                                  {socio.entrada}
                                                </Table.Cell>
                                                <Table.Cell className="text-center py-1 px-1 px-1">
                                                  {socio.participacao}
                                                </Table.Cell>
                                                <Table.Cell className="text-center py-1 w-24">
                                                  {socio.situacao_cnpj}
                                                </Table.Cell>
                                                <Table.Cell className="py-1 text-2xs text-black">
                                                  {socio?.indicador_debito?.toUpperCase() ===
                                                  "SIM" ? (
                                                    <>
                                                      {socio
                                                        ?.restricoes_documento
                                                        ?.cheques_sem_fundo
                                                        ?.quantidade !==
                                                        "0" && (
                                                        <div className="uppercase">
                                                          Cheques Sem Fundos:{" "}
                                                          <span className="text-red-500 font-semibold">
                                                            {formatToReal(
                                                              socio?.restricoes_documento?.cheques_sem_fundo?.valor?.replace(
                                                                ",",
                                                                "."
                                                              )
                                                            )}
                                                          </span>
                                                        </div>
                                                      )}

                                                      {socio
                                                        ?.restricoes_documento
                                                        ?.protestos
                                                        ?.quantidade !==
                                                        "0" && (
                                                        <div className="uppercase">
                                                          Protestos:{" "}
                                                          <span className="text-red-500 font-semibold">
                                                            {formatToReal(
                                                              socio?.restricoes_documento?.protestos?.valor?.replace(
                                                                ",",
                                                                "."
                                                              )
                                                            )}
                                                          </span>
                                                        </div>
                                                      )}

                                                      {socio
                                                        ?.restricoes_documento
                                                        ?.pendencias_restricoes
                                                        ?.quantidade !==
                                                        "0" && (
                                                        <div className="uppercase">
                                                          Pendências:{" "}
                                                          <span className="text-red-500 font-semibold">
                                                            {formatToReal(
                                                              socio?.restricoes_documento?.pendencias_restricoes?.valor?.replace(
                                                                ",",
                                                                "."
                                                              )
                                                            )}
                                                          </span>
                                                        </div>
                                                      )}

                                                      {socio
                                                        ?.restricoes_documento
                                                        ?.acoes_judiciais
                                                        ?.quantidade !==
                                                        "0" && (
                                                        <div className="uppercase">
                                                          Ações cíveis:{" "}
                                                          <span className="text-red-500 font-semibold">
                                                            {formatToReal(
                                                              socio?.restricoes_documento?.acoes_judiciais?.valor?.replace(
                                                                ",",
                                                                "."
                                                              )
                                                            )}
                                                          </span>
                                                        </div>
                                                      )}

                                                      {socio
                                                        ?.restricoes_documento
                                                        ?.falencias_recuperacao_judicial
                                                        ?.quantidade !==
                                                        "0" && (
                                                        <div className="uppercase">
                                                          Rec.
                                                          Judicial/Falências:{" "}
                                                          <span className="text-red-500 font-semibold">
                                                            {formatToReal(
                                                              socio?.restricoes_documento?.falencias_recuperacao_judicial?.valor?.replace(
                                                                ",",
                                                                "."
                                                              )
                                                            )}
                                                          </span>
                                                        </div>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <div className="text-blue-500">
                                                      Não há débitos
                                                    </div>
                                                  )}
                                                </Table.Cell>
                                              </Table.Row>
                                            )
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800 text-black">
                                            <Table.Cell className="font-medium dark:text-white text-center py-1 px-1">
                                              {1}
                                            </Table.Cell>
                                            <Table.Cell className=" py-1 w-96">
                                              <div>
                                                {
                                                  socioP?.socios_participantes
                                                    ?.razao_social
                                                }
                                              </div>
                                              <div style={{ fontSize: "10px" }}>
                                                CNPJ:{" "}
                                                {
                                                  socioP?.socios_participantes
                                                    ?.cnpj
                                                }
                                              </div>
                                            </Table.Cell>
                                            <Table.Cell className="text-center py-1 px-1">
                                              {
                                                socioP?.socios_participantes
                                                  ?.entrada
                                              }
                                            </Table.Cell>
                                            <Table.Cell className="text-center py-1 px-1 px-1">
                                              {
                                                socioP?.socios_participantes
                                                  ?.participacao
                                              }
                                            </Table.Cell>
                                            <Table.Cell className="text-center py-1 w-24">
                                              {
                                                socioP?.socios_participantes
                                                  ?.situacao_cnpj
                                              }
                                            </Table.Cell>
                                            <Table.Cell className="py-1 text-2xs text-black">
                                              {socioP?.socios_participantes?.indicador_debito?.toUpperCase() ===
                                              "SIM" ? (
                                                <>
                                                  {socioP?.socios_participantes
                                                    ?.restricoes_documento
                                                    ?.cheques_sem_fundo
                                                    ?.quantidade !== "0" && (
                                                    <div className="uppercase">
                                                      Cheques Sem Fundos:{" "}
                                                      <span className="text-red-500 font-semibold">
                                                        {formatToReal(
                                                          socioP?.socios_participantes?.restricoes_documento?.cheques_sem_fundo?.valor?.replace(
                                                            ",",
                                                            "."
                                                          )
                                                        )}
                                                      </span>
                                                    </div>
                                                  )}

                                                  {socioP?.socios_participantes
                                                    ?.restricoes_documento
                                                    ?.protestos?.quantidade !==
                                                    "0" && (
                                                    <div className="uppercase">
                                                      Protestos:{" "}
                                                      <span className="text-red-500 font-semibold">
                                                        {formatToReal(
                                                          socioP?.socios_participantes?.restricoes_documento?.protestos?.valor?.replace(
                                                            ",",
                                                            "."
                                                          )
                                                        )}
                                                      </span>
                                                    </div>
                                                  )}

                                                  {socioP?.socios_participantes
                                                    ?.restricoes_documento
                                                    ?.pendencias_restricoes
                                                    ?.quantidade !== "0" && (
                                                    <div className="uppercase">
                                                      Pendências:{" "}
                                                      <span className="text-red-500 font-semibold">
                                                        {formatToReal(
                                                          socioP?.socios_participantes?.restricoes_documento?.pendencias_restricoes?.valor?.replace(
                                                            ",",
                                                            "."
                                                          )
                                                        )}
                                                      </span>
                                                    </div>
                                                  )}

                                                  {socioP?.socios_participantes
                                                    ?.restricoes_documento
                                                    ?.acoes_judiciais
                                                    ?.quantidade !== "0" && (
                                                    <div className="uppercase">
                                                      Ações cíveis:{" "}
                                                      <span className="text-red-500 font-semibold">
                                                        {formatToReal(
                                                          socioP?.socios_participantes?.restricoes_documento?.acoes_judiciais?.valor?.replace(
                                                            ",",
                                                            "."
                                                          )
                                                        )}
                                                      </span>
                                                    </div>
                                                  )}

                                                  {socioP?.socios_participantes
                                                    ?.restricoes_documento
                                                    ?.falencias_recuperacao_judicial
                                                    ?.quantidade !== "0" && (
                                                    <div className="uppercase">
                                                      Rec. Judicial/Falências:{" "}
                                                      <span className="text-red-500 font-semibold">
                                                        {formatToReal(
                                                          socioP?.socios_participantes?.restricoes_documento?.falencias_recuperacao_judicial?.valor?.replace(
                                                            ",",
                                                            "."
                                                          )
                                                        )}
                                                      </span>
                                                    </div>
                                                  )}
                                                </>
                                              ) : (
                                                <div className="text-blue-500">
                                                  Não há débitos
                                                </div>
                                              )}
                                            </Table.Cell>
                                          </Table.Row>
                                        </>
                                      )}
                                    </Table.Body>
                                  </Table>
                                </div>
                              </div>
                            )
                          )}
                        </>
                      ) : (
                        <>
                          <div className="mt-10">
                            <div className="font-semibold mb-1 text-blue-600 text-center underline">
                              {
                                data?.participacoes_socios_adm_outras_empresas
                                  ?.socio.nome_socio
                              }
                            </div>

                            <div className="overflow-x-auto mt-3 mb-5 rounded-lg border border-gray-300">
                              <Table striped>
                                <Table.Head className="text-black">
                                  <Table.HeadCell
                                    className="text-center py-2 px-1"
                                    style={{ background: "#76b9fc" }}
                                  >
                                    Nº
                                  </Table.HeadCell>
                                  <Table.HeadCell
                                    className="py-2"
                                    style={{ background: "#76b9fc" }}
                                  >
                                    Denominação Social
                                  </Table.HeadCell>
                                  <Table.HeadCell
                                    className="text-center py-2 px-1"
                                    style={{ background: "#76b9fc" }}
                                  >
                                    Data <br /> Entrada
                                  </Table.HeadCell>
                                  <Table.HeadCell
                                    className="text-center py-2 px-1"
                                    style={{ background: "#76b9fc" }}
                                  >
                                    Part. (%)
                                  </Table.HeadCell>
                                  <Table.HeadCell
                                    className="text-center py-2 px-1"
                                    style={{ background: "#76b9fc" }}
                                  >
                                    Situação
                                  </Table.HeadCell>
                                  <Table.HeadCell
                                    className="py-2"
                                    style={{ background: "#76b9fc" }}
                                  >
                                    Pendências
                                  </Table.HeadCell>
                                </Table.Head>

                                <Table.Body className="divide-y">
                                  {Array.isArray(
                                    data
                                      ?.participacoes_socios_adm_outras_empresas
                                      ?.socio?.socios_participantes
                                  ) ? (
                                    <>
                                      {data?.participacoes_socios_adm_outras_empresas?.socio?.socios_participantes?.map(
                                        (socio, index) => (
                                          <Table.Row
                                            key={index}
                                            className="bg-white dark:border-gray-700 dark:bg-gray-800 text-black"
                                          >
                                            <Table.Cell className="font-medium dark:text-white text-center py-1 px-1">
                                              {index + 1}
                                            </Table.Cell>
                                            <Table.Cell className=" py-1 w-96">
                                              <div>{socio.razao_social}</div>
                                              <div style={{ fontSize: "10px" }}>
                                                CNPJ: {socio.cnpj}
                                              </div>
                                            </Table.Cell>
                                            <Table.Cell className="text-center py-1 px-1">
                                              {socio.entrada}
                                            </Table.Cell>
                                            <Table.Cell className="text-center py-1 px-1 px-1">
                                              {socio.participacao}
                                            </Table.Cell>
                                            <Table.Cell className="text-center py-1 w-24">
                                              {socio.situacao_cnpj}
                                            </Table.Cell>
                                            <Table.Cell className="py-1 text-2xs text-black">
                                              {socio?.indicador_debito?.toUpperCase() ===
                                              "SIM" ? (
                                                <>
                                                  {socio?.restricoes_documento
                                                    ?.cheques_sem_fundo
                                                    ?.quantidade !== "0" && (
                                                    <div className="uppercase">
                                                      Cheques Sem Fundos:{" "}
                                                      <span className="text-red-500 font-semibold">
                                                        {formatToReal(
                                                          socio?.restricoes_documento?.cheques_sem_fundo?.valor?.replace(
                                                            ",",
                                                            "."
                                                          )
                                                        )}
                                                      </span>
                                                    </div>
                                                  )}

                                                  {socio?.restricoes_documento
                                                    ?.protestos?.quantidade !==
                                                    "0" && (
                                                    <div className="uppercase">
                                                      Protestos:{" "}
                                                      <span className="text-red-500 font-semibold">
                                                        {formatToReal(
                                                          socio?.restricoes_documento?.protestos?.valor?.replace(
                                                            ",",
                                                            "."
                                                          )
                                                        )}
                                                      </span>
                                                    </div>
                                                  )}

                                                  {socio?.restricoes_documento
                                                    ?.pendencias_restricoes
                                                    ?.quantidade !== "0" && (
                                                    <div className="uppercase">
                                                      Pendências:{" "}
                                                      <span className="text-red-500 font-semibold">
                                                        {formatToReal(
                                                          socio?.restricoes_documento?.pendencias_restricoes?.valor?.replace(
                                                            ",",
                                                            "."
                                                          )
                                                        )}
                                                      </span>
                                                    </div>
                                                  )}

                                                  {socio?.restricoes_documento
                                                    ?.acoes_judiciais
                                                    ?.quantidade !== "0" && (
                                                    <div className="uppercase">
                                                      Ações cíveis:{" "}
                                                      <span className="text-red-500 font-semibold">
                                                        {formatToReal(
                                                          socio?.restricoes_documento?.acoes_judiciais?.valor?.replace(
                                                            ",",
                                                            "."
                                                          )
                                                        )}
                                                      </span>
                                                    </div>
                                                  )}

                                                  {socio?.restricoes_documento
                                                    ?.falencias_recuperacao_judicial
                                                    ?.quantidade !== "0" && (
                                                    <div className="uppercase">
                                                      Rec. Judicial/Falências:{" "}
                                                      <span className="text-red-500 font-semibold">
                                                        {formatToReal(
                                                          socio?.restricoes_documento?.falencias_recuperacao_judicial?.valor?.replace(
                                                            ",",
                                                            "."
                                                          )
                                                        )}
                                                      </span>
                                                    </div>
                                                  )}
                                                </>
                                              ) : (
                                                <div className="text-blue-500">
                                                  Não há débitos
                                                </div>
                                              )}
                                            </Table.Cell>
                                          </Table.Row>
                                        )
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800 text-black">
                                        <Table.Cell className="font-medium dark:text-white text-center py-1 px-1">
                                          {1}
                                        </Table.Cell>
                                        <Table.Cell className=" py-1 w-96">
                                          <div>
                                            {
                                              data
                                                ?.participacoes_socios_adm_outras_empresas
                                                ?.socio?.socios_participantes
                                                ?.razao_social
                                            }
                                          </div>
                                          <div style={{ fontSize: "10px" }}>
                                            CNPJ:{" "}
                                            {
                                              data
                                                ?.participacoes_socios_adm_outras_empresas
                                                ?.socio?.socios_participantes
                                                ?.cnpj
                                            }
                                          </div>
                                        </Table.Cell>
                                        <Table.Cell className="text-center py-1 px-1">
                                          {
                                            data
                                              ?.participacoes_socios_adm_outras_empresas
                                              ?.socio?.socios_participantes
                                              ?.entrada
                                          }
                                        </Table.Cell>
                                        <Table.Cell className="text-center py-1 px-1 px-1">
                                          {
                                            data
                                              ?.participacoes_socios_adm_outras_empresas
                                              ?.socio?.socios_participantes
                                              ?.participacao
                                          }
                                        </Table.Cell>
                                        <Table.Cell className="text-center py-1 w-24">
                                          {
                                            data
                                              ?.participacoes_socios_adm_outras_empresas
                                              ?.socio?.socios_participantes
                                              ?.situacao_cnpj
                                          }
                                        </Table.Cell>
                                        <Table.Cell className="py-1 text-2xs text-black">
                                          {data?.participacoes_socios_adm_outras_empresas?.socio?.socios_participantes.indicador_debito?.toUpperCase() ===
                                          "SIM" ? (
                                            <>
                                              {data
                                                ?.participacoes_socios_adm_outras_empresas
                                                ?.socio?.socios_participantes
                                                .restricoes_documento
                                                ?.cheques_sem_fundo
                                                ?.quantidade !== "0" && (
                                                <div className="uppercase">
                                                  Cheques Sem Fundos:{" "}
                                                  <span className="text-red-500 font-semibold">
                                                    {formatToReal(
                                                      data?.participacoes_socios_adm_outras_empresas?.socio?.socios_participantes.restricoes_documento?.cheques_sem_fundo?.valor?.replace(
                                                        ",",
                                                        "."
                                                      )
                                                    )}
                                                  </span>
                                                </div>
                                              )}

                                              {data
                                                ?.participacoes_socios_adm_outras_empresas
                                                ?.socio?.socios_participantes
                                                .restricoes_documento?.protestos
                                                ?.quantidade !== "0" && (
                                                <div className="uppercase">
                                                  Protestos:{" "}
                                                  <span className="text-red-500 font-semibold">
                                                    {formatToReal(
                                                      data?.participacoes_socios_adm_outras_empresas?.socio?.socios_participantes.restricoes_documento?.protestos?.valor?.replace(
                                                        ",",
                                                        "."
                                                      )
                                                    )}
                                                  </span>
                                                </div>
                                              )}

                                              {data
                                                ?.participacoes_socios_adm_outras_empresas
                                                ?.socio?.socios_participantes
                                                .restricoes_documento
                                                ?.pendencias_restricoes
                                                ?.quantidade !== "0" && (
                                                <div className="uppercase">
                                                  Pendências:{" "}
                                                  <span className="text-red-500 font-semibold">
                                                    {formatToReal(
                                                      data?.participacoes_socios_adm_outras_empresas?.socio?.socios_participantes.restricoes_documento?.pendencias_restricoes?.valor?.replace(
                                                        ",",
                                                        "."
                                                      )
                                                    )}
                                                  </span>
                                                </div>
                                              )}

                                              {data
                                                ?.participacoes_socios_adm_outras_empresas
                                                ?.socio?.socios_participantes
                                                .restricoes_documento
                                                ?.acoes_judiciais
                                                ?.quantidade !== "0" && (
                                                <div className="uppercase">
                                                  Ações cíveis:{" "}
                                                  <span className="text-red-500 font-semibold">
                                                    {formatToReal(
                                                      data?.participacoes_socios_adm_outras_empresas?.socio?.socios_participantes.restricoes_documento?.acoes_judiciais?.valor?.replace(
                                                        ",",
                                                        "."
                                                      )
                                                    )}
                                                  </span>
                                                </div>
                                              )}

                                              {data
                                                ?.participacoes_socios_adm_outras_empresas
                                                ?.socio?.socios_participantes
                                                .restricoes_documento
                                                ?.falencias_recuperacao_judicial
                                                ?.quantidade !== "0" && (
                                                <div className="uppercase">
                                                  Rec. Judicial/Falências:{" "}
                                                  <span className="text-red-500 font-semibold">
                                                    {formatToReal(
                                                      data?.participacoes_socios_adm_outras_empresas?.socio?.socios_participantes.restricoes_documento?.falencias_recuperacao_judicial?.valor?.replace(
                                                        ",",
                                                        "."
                                                      )
                                                    )}
                                                  </span>
                                                </div>
                                              )}
                                            </>
                                          ) : (
                                            <div className="text-blue-500">
                                              Não há débitos
                                            </div>
                                          )}
                                        </Table.Cell>
                                      </Table.Row>
                                    </>
                                  )}
                                </Table.Body>
                              </Table>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <Card className={`bg-green-200 mt-4 mb-5`}>
                      <div className="flex gap-2 items-center text-gray-600 font-semibold">
                        <div>
                          <IoAlertCircleOutline fontSize={20} />
                        </div>
                        <div>Nada consta</div>
                      </div>
                    </Card>
                  )}
                </div>

                <div>
                  <TitleStripe className="mb-5">Crédito Obtido</TitleStripe>
                  {data?.credito_obtido ? (
                    <>
                      <div className="mt-4 font-semibold">
                        Pontuação:{" "}
                        <Badge className="w-fit inline" color="gray">
                          {data?.credito_obtido?.pontuacao}
                        </Badge>
                      </div>
                      <div className="mt-2 pt-5 pb-2 px-3 overflow-hidden">
                        <Timeline horizontal className="flex gap-8 md:gap-0">
                          {data?.credito_obtido?.lista_indicador_comportamento?.item_indicador
                            ?.reverse()
                            ?.map((pagamento, index) => (
                              <Timeline.Item key={index}>
                                <Timeline.Point />
                                <Timeline.Content className="-ml-2">
                                  <Timeline.Time className="text-xs">
                                    {pagamento.mes_ano}
                                  </Timeline.Time>
                                  <Timeline.Title className="text-xs">
                                    Score:{" "}
                                    <Badge className="w-fit block min-w-10 text-center">
                                      {pagamento.pontuacao_mensal}
                                    </Badge>
                                  </Timeline.Title>
                                </Timeline.Content>
                              </Timeline.Item>
                            ))}
                        </Timeline>
                      </div>{" "}
                    </>
                  ) : (
                    <Alert className="mt-4" withBorderAccent>
                      Sem informação
                    </Alert>
                  )}
                </div>

                <div>
                  <TitleStripe className="mb-5">Pagamento Pontual</TitleStripe>
                  {data?.pagamento_pontual ? (
                    <>
                      <div className="mt-4 font-semibold">
                        Pontuação:{" "}
                        <Badge className="w-fit inline" color="gray">
                          {data?.pagamento_pontual?.pontuacao}
                        </Badge>
                      </div>
                      <div className="mt-2 pt-5 pb-2 px-3 overflow-x-hidden">
                        <Timeline horizontal className="flex gap-8 md:gap-0">
                          {data?.pagamento_pontual?.lista_indicador_comportamento?.item_indicador
                            ?.reverse()
                            ?.map((pagamento, index) => (
                              <Timeline.Item key={index}>
                                <Timeline.Point />
                                <Timeline.Content className="-ml-2">
                                  <Timeline.Time className="text-xs">
                                    {pagamento.mes_ano}
                                  </Timeline.Time>
                                  <Timeline.Title className="text-xs">
                                    Score:{" "}
                                    <Badge className="w-fit block min-w-10 text-center">
                                      {pagamento.pontuacao_mensal}
                                    </Badge>
                                  </Timeline.Title>
                                </Timeline.Content>
                              </Timeline.Item>
                            ))}
                        </Timeline>
                      </div>
                    </>
                  ) : (
                    <Alert className="mt-4" withBorderAccent>
                      Sem informação
                    </Alert>
                  )}
                </div>

                <div>
                  <TitleStripe className="mb-5">Pagamento Atrasado</TitleStripe>
                  {data?.pagamento_atrasado ? (
                    <>
                      {" "}
                      <div className="mt-2 pt-5 pb-2 px-3 overflow-hidden">
                        <Timeline horizontal className="flex gap-8 md:gap-0">
                          {data?.pagamento_atrasado?.atraso_medio_dias?.lista_indicador_comportamento?.item_indicador
                            ?.reverse()
                            ?.map((pagamento, index) => (
                              <Timeline.Item key={index}>
                                <Timeline.Point />
                                <Timeline.Content className="-ml-2">
                                  <Timeline.Time className="text-xs">
                                    {pagamento.mes_ano}
                                  </Timeline.Time>
                                  <Timeline.Title className="text-xs">
                                    Score:{" "}
                                    <Badge className="w-fit block min-w-10 text-center">
                                      {pagamento.pontuacao_mensal}
                                    </Badge>
                                  </Timeline.Title>
                                </Timeline.Content>
                              </Timeline.Item>
                            ))}
                        </Timeline>
                      </div>
                    </>
                  ) : (
                    <Alert className="mt-4" withBorderAccent>
                      Sem informação
                    </Alert>
                  )}
                </div>

                <div>
                  <TitleStripe className="mb-5">Compromissos</TitleStripe>
                  {data?.compromissos ? (
                    <>
                      <div className="mt-4 font-semibold">
                        Pontuação:{" "}
                        <Badge className="w-fit inline" color="gray">
                          {data?.compromissos?.pontuacao}
                        </Badge>
                      </div>
                      <div className="mt-2 pt-5 pb-2 px-3 overflow-hidden">
                        <Timeline horizontal className="flex gap-8 md:gap-0">
                          {data?.compromissos?.lista_indicador_comportamento?.item_indicador
                            ?.reverse()
                            ?.map((pagamento, index) => (
                              <Timeline.Item key={index}>
                                <Timeline.Point />
                                <Timeline.Content className="-ml-2">
                                  <Timeline.Time className="text-xs">
                                    {pagamento.mes_ano}
                                  </Timeline.Time>
                                  <Timeline.Title className="text-xs">
                                    Score:{" "}
                                    <Badge className="w-fit block min-w-10 text-center">
                                      {pagamento.pontuacao_mensal}
                                    </Badge>
                                  </Timeline.Title>
                                </Timeline.Content>
                              </Timeline.Item>
                            ))}
                        </Timeline>
                      </div>
                    </>
                  ) : (
                    <Alert className="mt-4" withBorderAccent>
                      Sem informação
                    </Alert>
                  )}
                </div>

                <div>
                  <TitleStripe className="mb-5">
                    Comprometimento Futuro
                  </TitleStripe>
                  {data?.comprometimento_futuro ? (
                    <>
                      <div className="font-semibold mt-2">Compromissos</div>
                      <div className="mt-2 pt-5 pb-2 px-3 overflow-x-scroll"></div>
                      <Timeline horizontal className="flex gap-8 md:gap-0">
                        {data?.comprometimento_futuro?.lista_compromissos?.compromisso?.map(
                          (comprometimento, index) => (
                            <Timeline.Item key={index}>
                              <Timeline.Point />
                              <Timeline.Content className="-ml-2">
                                <Timeline.Time className="text-xs">
                                  {comprometimento.periodo}
                                </Timeline.Time>
                                <Timeline.Title className="text-xs">
                                  Score:{" "}
                                  <Badge className="w-fit block min-w-10 text-center">
                                    {comprometimento.pontuacao}
                                  </Badge>
                                </Timeline.Title>
                              </Timeline.Content>
                            </Timeline.Item>
                          )
                        )}
                      </Timeline>

                      <div className="font-semibold mt-5">Crédito</div>
                      <div className="mt-2 pt-5 pb-2 px-3 overflow-x-scroll">
                        <Timeline horizontal className="flex gap-8 md:gap-0">
                          {data?.comprometimento_futuro?.lista_credito?.credito?.map(
                            (comprometimento, index) => (
                              <Timeline.Item key={index}>
                                <Timeline.Point />
                                <Timeline.Content className="-ml-2">
                                  <Timeline.Time className="text-xs">
                                    {comprometimento.periodo}
                                  </Timeline.Time>
                                  <Timeline.Title className="text-xs">
                                    Score:{" "}
                                    <Badge className="w-fit block min-w-10 text-center">
                                      {comprometimento.pontuacao}
                                    </Badge>
                                  </Timeline.Title>
                                </Timeline.Content>
                              </Timeline.Item>
                            )
                          )}
                        </Timeline>
                      </div>
                    </>
                  ) : (
                    <Alert className="mt-4" withBorderAccent>
                      Sem informação
                    </Alert>
                  )}
                </div>

                <div>
                  <TitleStripe className="mb-5">
                    Pendências Financeiras
                  </TitleStripe>
                  {data?.pendencias_restricoes?.ultimas_ocorrencias
                    ?.pendencia_restricao ? (
                    <>
                      <div className="flex gap-6">
                        <div className="flex gap-2 items-center">
                          <span className="font-semibold text-red-700">
                            Quantidade:{" "}
                          </span>
                          <Badge color="failure" className="w-fit">
                            {data?.pendencias_restricoes?.total_pendencias}
                          </Badge>
                        </div>
                        <div className="flex gap-2 items-center">
                          <span className="font-semibold text-red-700">
                            Último vencimento:{" "}
                          </span>
                          <Badge color="failure" className="w-fit">
                            {data?.pendencias_restricoes?.data_maior}
                          </Badge>
                        </div>
                        <div className="flex gap-2 items-center">
                          <span className="font-semibold text-red-700">
                            Valor Total:{" "}
                          </span>
                          <Badge color="failure" className="w-fit">
                            {formatToReal(data?.pendencias_restricoes?.valor)}
                          </Badge>
                        </div>
                      </div>
                      <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                        <Table striped>
                          <Table.Head>
                            <Table.HeadCell className="text-center bg-red-300">
                              Vencimento
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Informante
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Valor
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Tipo:
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Cidade:
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Documento origem:
                            </Table.HeadCell>
                          </Table.Head>

                          <Table.Body className="divide-y">
                            {Array.isArray(
                              data?.pendencias_restricoes.ultimas_ocorrencias
                                ?.pendencia_restricao
                            ) ? (
                              <>
                                {data?.pendencias_restricoes.ultimas_ocorrencias?.pendencia_restricao?.map(
                                  (ocorrencia, index) => (
                                    <Table.Row
                                      key={index}
                                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                    >
                                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                        {ocorrencia.data_debito}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {ocorrencia.informante}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {formatToReal(ocorrencia.valor)}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {ocorrencia.tipo}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {ocorrencia.cidade}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {ocorrencia.documento_origem}
                                      </Table.Cell>
                                    </Table.Row>
                                  )
                                )}
                              </>
                            ) : (
                              <>
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                    {
                                      data?.pendencias_restricoes
                                        .ultimas_ocorrencias
                                        ?.pendencia_restricao?.data_debito
                                    }
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {
                                      data?.pendencias_restricoes
                                        .ultimas_ocorrencias
                                        ?.pendencia_restricao?.informante
                                    }
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {formatToReal(
                                      data?.pendencias_restricoes
                                        .ultimas_ocorrencias
                                        ?.pendencia_restricao?.valor
                                    )}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {
                                      data?.pendencias_restricoes
                                        .ultimas_ocorrencias
                                        ?.pendencia_restricao?.tipo
                                    }
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {
                                      data?.pendencias_restricoes
                                        .ultimas_ocorrencias
                                        ?.pendencia_restricao?.cidade
                                    }
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {
                                      data?.pendencias_restricoes
                                        .ultimas_ocorrencias
                                        ?.pendencia_restricao?.documento_origem
                                    }
                                  </Table.Cell>
                                </Table.Row>
                              </>
                            )}
                          </Table.Body>
                        </Table>
                      </div>
                    </>
                  ) : (
                    <Card className={`bg-green-200 mt-4`}>
                      <div className="flex gap-2 items-center text-gray-600 font-semibold">
                        <div>
                          <IoAlertCircleOutline fontSize={20} />
                        </div>
                        <div>Nada consta</div>
                      </div>
                    </Card>
                  )}
                </div>
                <div>
                  <TitleStripe className="mb-5">Protestos</TitleStripe>
                  {!!data?.protestos?.total_protestos ? (
                    <>
                      <div className="flex gap-6">
                        <div className="flex items-center gap-2">
                          <span className="font-semibold text-red-700">
                            Quantidade:{" "}
                          </span>
                          <Badge color="failure">
                            {data?.protestos?.total_protestos}
                          </Badge>
                        </div>
                        <div className="flex items-center gap-2">
                          <span className="font-semibold text-red-700">
                            Primeiro valor:{" "}
                          </span>
                          <Badge color="failure">
                            {formatToReal(data?.protestos?.valor_primeiro)}
                          </Badge>
                        </div>
                        <div className="flex items-center gap-2">
                          <span className="font-semibold text-red-700">
                            Valor Total:{" "}
                          </span>
                          <Badge color="failure">
                            {formatToReal(data?.protestos?.valor_total)}
                          </Badge>
                        </div>
                      </div>
                      <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                        <Table striped>
                          <Table.Head>
                            <Table.HeadCell className="text-center bg-red-300">
                              Data
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Valor
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Cartório
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Cidade
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Estado
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Vencimento
                            </Table.HeadCell>
                          </Table.Head>

                          <Table.Body className="divide-y">
                            {Array.isArray(
                              data?.protestos?.ultimas_ocorrencias?.protesto
                            ) ? (
                              <>
                                {data?.protestos?.ultimas_ocorrencias?.protesto.map(
                                  (protesto, index) => (
                                    <Table.Row
                                      key={index}
                                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                    >
                                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                        {protesto.data}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {formatToReal(protesto.valor)}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {protesto.cartorio}º cartório
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {protesto.cidade}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {protesto.estado}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {protesto.vencimento}
                                      </Table.Cell>
                                    </Table.Row>
                                  )
                                )}
                              </>
                            ) : (
                              <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                  {
                                    data?.protestos?.ultimas_ocorrencias
                                      ?.protesto?.data
                                  }
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {formatToReal(
                                    data?.protestos?.ultimas_ocorrencias
                                      ?.protesto?.valor
                                  )}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {
                                    data?.protestos?.ultimas_ocorrencias
                                      ?.protesto?.cartorio
                                  }
                                  º cartório
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {
                                    data?.protestos?.ultimas_ocorrencias
                                      ?.protesto?.cidade
                                  }
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {
                                    data?.protestos?.ultimas_ocorrencias
                                      ?.protesto?.estado
                                  }
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {
                                    data?.protestos?.ultimas_ocorrencias
                                      ?.protesto?.vencimento
                                  }
                                </Table.Cell>
                              </Table.Row>
                            )}
                          </Table.Body>
                        </Table>
                      </div>
                    </>
                  ) : (
                    <Card className={`bg-green-200 mt-4`}>
                      <div className="flex gap-2 items-center text-gray-600 font-semibold">
                        <div>
                          <IoAlertCircleOutline fontSize={20} />
                        </div>
                        <div>Nada consta</div>
                      </div>
                    </Card>
                  )}
                </div>

                <div>
                  <TitleStripe className="mb-5">
                    Protestos Direto do Cartório
                  </TitleStripe>
                  {!isLoadingProtestos ? (
                    <>
                      {+infoProtestos?.data?.data?.CREDCADASTRAL
                        ?.PROTESTO_SINTETICO?.QUANTIDADE_OCORRENCIA > 0 ? (
                        <>
                          <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                            <Table striped>
                              <Table.Head>
                                <Table.HeadCell className="bg-red-300">
                                  Cartório
                                </Table.HeadCell>
                                <Table.HeadCell className="bg-red-300">
                                  Credor
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-red-300">
                                  Telefone
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-red-300">
                                  Valor
                                </Table.HeadCell>
                              </Table.Head>

                              <Table.Body className="divide-y text-sm">
                                {infoProtestos?.data?.data?.CREDCADASTRAL?.PROTESTO_SINTETICO?.OCORRENCIAS?.map(
                                  (protesto, index) => (
                                    <Table.Row
                                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                      key={index}
                                    >
                                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                        {protesto?.CARTORIO}
                                      </Table.Cell>
                                      <Table.Cell>
                                        {protesto?.CREDOR}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {protesto?.TELEFONE}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {formatToReal(protesto?.VALOR)}
                                      </Table.Cell>
                                    </Table.Row>
                                  )
                                )}
                              </Table.Body>
                            </Table>
                          </div>
                        </>
                      ) : (
                        <Card className={`bg-green-200  mb-3`}>
                          <div className="flex gap-2 items-center text-gray-600 font-semibold">
                            <div>
                              <FaRegCheckCircle fontSize={20} />
                            </div>
                            <div>Nada consta</div>
                          </div>
                        </Card>
                      )}
                    </>
                  ) : (
                    <div role="status" className="w-full animate-pulse">
                      <div className="h-7 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-4"></div>{" "}
                    </div>
                  )}
                </div>

                <div>
                  <TitleStripe className="mb-5">Ações Cíveis</TitleStripe>
                  {!!data?.acoes_civeis?.total_acoes ? (
                    <>
                      <div className="flex gap-6">
                        <div className="flex items-center gap-2">
                          <span className="font-semibold text-red-700">
                            Quantidade:{" "}
                          </span>
                          <Badge color="failure">
                            {data?.acoes_civeis?.total_acoes}
                          </Badge>
                        </div>
                        <div className="flex items-center gap-2">
                          <span className="font-semibold text-red-700">
                            Primeiro valor:{" "}
                          </span>
                          <Badge color="failure">
                            {formatToReal(data?.acoes_civeis?.valor_primeiro)}
                          </Badge>
                        </div>
                        <div className="flex items-center gap-2">
                          <span className="font-semibold text-red-700">
                            Valor Total:{" "}
                          </span>
                          <Badge color="failure">
                            {formatToReal(data?.acoes_civeis?.valor_total)}
                          </Badge>
                        </div>
                      </div>
                      <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                        <Table striped>
                          <Table.Head>
                            <Table.HeadCell className="text-center bg-red-300">
                              Requerido
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Ação
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Documento
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Foro
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Processo
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Autor
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Distribuição
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Valor
                            </Table.HeadCell>
                          </Table.Head>

                          <Table.Body className="divide-y">
                            {Array.isArray(
                              data?.acoes_civeis?.ultimas_ocorrencias
                                ?.acao_civel
                            ) ? (
                              <>
                                {data?.acoes_civeis?.ultimas_ocorrencias?.acao_civel.map(
                                  (acao, index) => (
                                    <Table.Row
                                      key={index}
                                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                    >
                                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                        {acao.requerido}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {acao.acao}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {acao.documento}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {acao.foro}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {acao.processo}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {acao.autor}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {acao.distribuicao}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {formatToReal(acao.valor)}
                                      </Table.Cell>
                                    </Table.Row>
                                  )
                                )}
                              </>
                            ) : (
                              <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                  {
                                    data?.protestos?.ultimas_ocorrencias
                                      ?.protesto?.data
                                  }
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {formatToReal(
                                    data?.protestos?.ultimas_ocorrencias
                                      ?.protesto?.valor
                                  )}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {
                                    data?.protestos?.ultimas_ocorrencias
                                      ?.protesto?.cartorio
                                  }
                                  º cartório
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {
                                    data?.protestos?.ultimas_ocorrencias
                                      ?.protesto?.cidade
                                  }
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {
                                    data?.protestos?.ultimas_ocorrencias
                                      ?.protesto?.estado
                                  }
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {
                                    data?.protestos?.ultimas_ocorrencias
                                      ?.protesto?.vencimento
                                  }
                                </Table.Cell>
                              </Table.Row>
                            )}
                          </Table.Body>
                        </Table>
                      </div>
                    </>
                  ) : (
                    <Card className={`bg-green-200 mt-4`}>
                      <div className="flex gap-2 items-center text-gray-600 font-semibold">
                        <div>
                          <IoAlertCircleOutline fontSize={20} />
                        </div>
                        <div>Nada consta</div>
                      </div>
                    </Card>
                  )}
                </div>
                <div>
                  <TitleStripe className="mb-5">Cheques Sem Fundos</TitleStripe>
                  {!!data?.cheques_sem_fundo?.total_cheques_sem_fundos ? (
                    <>
                      <div className="flex gap-6">
                        <div className="flex items-center gap-2">
                          <span className="font-semibold text-red-700">
                            Quantidade:{" "}
                          </span>
                          <Badge color="failure">
                            {data?.cheques_sem_fundo?.total_cheques_sem_fundos}
                          </Badge>
                        </div>
                        <div className="flex items-center gap-2">
                          <span className="font-semibold text-red-700">
                            Total de nomes relacionados:{" "}
                          </span>
                          <Badge color="failure">
                            {data?.cheques_sem_fundo?.total_nomes_relacionados}
                          </Badge>
                        </div>
                      </div>
                      <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                        <Table striped>
                          <Table.Head>
                            <Table.HeadCell className="text-center bg-red-300">
                              Data
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Agência
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Nome do banco
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Quantidade
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Motivo
                            </Table.HeadCell>
                          </Table.Head>

                          <Table.Body className="divide-y">
                            {Array.isArray(
                              data?.cheques_sem_fundo?.ultimas_ocorrencias
                                ?.cheque_sem_fundo
                            ) ? (
                              <>
                                {data?.cheques_sem_fundo?.ultimas_ocorrencias?.cheque_sem_fundo.map(
                                  (cheque, index) => (
                                    <Table.Row
                                      key={index}
                                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                    >
                                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                        {cheque.data_ultimo}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {cheque.agencia}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {cheque.nome_banco}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {cheque.quantidade}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {cheque.motivo}
                                      </Table.Cell>
                                    </Table.Row>
                                  )
                                )}
                              </>
                            ) : (
                              <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                  {
                                    data?.cheques_sem_fundo?.ultimas_ocorrencias
                                      ?.cheque_sem_fundo?.data_ultimo
                                  }
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {
                                    data?.cheques_sem_fundo?.ultimas_ocorrencias
                                      ?.cheque_sem_fundo?.agencia
                                  }
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {
                                    data?.cheques_sem_fundo?.ultimas_ocorrencias
                                      ?.cheque_sem_fundo?.nome_banco
                                  }
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {
                                    data?.cheques_sem_fundo?.ultimas_ocorrencias
                                      ?.cheque_sem_fundo?.quantidade
                                  }
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {
                                    data?.cheques_sem_fundo?.ultimas_ocorrencias
                                      ?.cheque_sem_fundo?.motivo
                                  }
                                </Table.Cell>
                              </Table.Row>
                            )}
                          </Table.Body>
                        </Table>
                      </div>
                    </>
                  ) : (
                    <Card className={`bg-green-200 mt-4`}>
                      <div className="flex gap-2 items-center text-gray-600 font-semibold">
                        <div>
                          <IoAlertCircleOutline fontSize={20} />
                        </div>
                        <div>Nada consta</div>
                      </div>
                    </Card>
                  )}
                </div>
                <div>
                  <TitleStripe className="mb-5">Cheques Sustados:</TitleStripe>
                  {data?.cheques_sustados?.ultimas_ocorrencias?.cheques_sustado
                    ?.length > 0 ? (
                    <>
                      <div className="flex gap-6 flex-wrap">
                        <div className="flex gap-1 items-center">
                          <span className="font-semibold text-red-700">
                            Período:{" "}
                          </span>
                          <Badge color="failure">
                            {" "}
                            {data?.cheques_sustados?.periodo_inicial} até
                            {data?.cheques_sustados?.periodo_final}
                          </Badge>
                        </div>
                        <div className="flex gap-1 items-center">
                          <span className="font-semibold text-red-700">
                            Quantidade:{" "}
                          </span>
                          <Badge color="failure">
                            {data?.cheques_sustados?.total}
                          </Badge>
                        </div>
                      </div>
                      <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                        <Table striped>
                          <Table.Head>
                            <Table.HeadCell className="text-center bg-red-300">
                              Data
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Banco
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Agência
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Cheque
                            </Table.HeadCell>
                          </Table.Head>

                          <Table.Body className="divide-y">
                            {data?.cheques_sustados?.ultimas_ocorrencias?.cheques_sustado.map(
                              (cheque, index) => (
                                <Table.Row
                                  key={index}
                                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                >
                                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                    {cheque.data}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {cheque.banco}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {cheque.agencia}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {cheque.cheques}
                                  </Table.Cell>
                                </Table.Row>
                              )
                            )}
                          </Table.Body>
                        </Table>
                      </div>
                    </>
                  ) : (
                    <Card className={`bg-green-200 mt-4`}>
                      <div className="flex gap-2 items-center text-gray-600 font-semibold">
                        <div>
                          <IoAlertCircleOutline fontSize={20} />
                        </div>
                        <div>Nada consta</div>
                      </div>
                    </Card>
                  )}
                </div>

                <div>
                  <TitleStripe className="mb-5">
                    Histórico de Consultas
                  </TitleStripe>

                  {data?.consultas?.ultimas_consultas?.consulta?.length > 0 ? (
                    <>
                      <div className="flex gap-6">
                        <div>
                          <span className="font-semibold">
                            Quantidade Total:{" "}
                          </span>
                          {data?.consultas?.quantidade_total}
                        </div>
                        <div>
                          <span className="font-semibold">Período: </span>
                          {data?.consultas?.periodo_inicial} até{" "}
                          {data?.consultas?.periodo_final}
                        </div>
                      </div>
                      <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                        <Table striped>
                          <Table.Head>
                            <Table.HeadCell className="text-center bg-gray-300">
                              Data
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-gray-300">
                              Empresa
                            </Table.HeadCell>
                          </Table.Head>

                          <Table.Body className="divide-y">
                            {data?.consultas?.ultimas_consultas?.consulta.map(
                              (consult, index) => (
                                <Table.Row
                                  key={index}
                                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                >
                                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                    {consult.data}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {consult.razao_social}
                                  </Table.Cell>
                                </Table.Row>
                              )
                            )}
                          </Table.Body>
                        </Table>
                      </div>
                    </>
                  ) : (
                    <Card className={`bg-green-200 mt-4`}>
                      <div className="flex gap-2 items-center text-gray-600 font-semibold">
                        <div>
                          <IoAlertCircleOutline fontSize={20} />
                        </div>
                        <div>Nada consta</div>
                      </div>
                    </Card>
                  )}
                </div>
                <div className="mt-10 italic border border-gray-200 p-2">
                  <span className="font-semibold">Observações:</span> as
                  informações evidenciadas em “Dados Cadastrais Básicos” são
                  provenientes de base de dados oficial da Receita Federal do
                  Brasil. Para maior detalhamento e cruzamento com outras fontes
                  de dados cadastrais, acessar a consulta “Dados Cadastrais” no
                  painel de consultas.
                </div>
              </Card>
            </>
          ) : (
            <QueryLoading />
          )}
        </div>
      )}
    </div>
  );
};

export default DefineLimitePositivoQuery;
