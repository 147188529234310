import { Icon } from "@mui/material";

const EmptyTable = ({ icon, children }) => {
  return (
    <div className="border-dashed py-10 px-4 border-2 border-gray-300 rounded-md text-center flex-col flex items-center mb-6 mt-6">
      <Icon className="color-primary">{icon}</Icon>
      <span className="text-sm">
        {children}
      </span>
    </div>
  );
};

export default EmptyTable;
