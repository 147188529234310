import {
  Alert,
  Badge,
  Button,
  Card,
  Label,
  Spinner,
  Table,
  TextInput,
} from "flowbite-react";
import React, { useEffect, useRef, useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { FaRegCheckCircle, FaSearch } from "react-icons/fa";
import { IoAlertCircleOutline } from "react-icons/io5";
import { formatToReal, isValidCPF } from "../../utils/utils";
import ReactApexChart from "react-apexcharts";
import axios from "../../config/axiosInstance";
import { QUERIES } from "../../config/apiConfig";
import { maskCPF } from "../../utils/masks";
import { HiInformationCircle } from "react-icons/hi";
import { BOA_VISTA_CONTANTS } from "../../utils/boa-vista-variables";
import HeaderQuery from "./HeaderQuery";
import usePDF from "../../hooks/usePDF";
import TitleStripe from "../common/TitleStripe";
import CardQueryDescription from "../common/CardQueryDescription";
import QueryInput from "../inputs/QueryInput";
import { FiAlertOctagon } from "react-icons/fi";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import SliderToPreview from "../common/SliderToPreview";
import QueryLoading from "../common/QueryLoading";

const PositivoPlusCPFQuery = ({ query, selectedPrice }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [document, setDocument] = useState("");
  const [infoProtestos, setInfoProtestos] = useState(null);
  const [isDoneRequest, setIsDoneRequest] = useState(false);
  const [isLoadingProtestos, setIsLoadingProtestos] = useState(false);
  const [error, setError] = useState("");
  const [errorQuery, setErrorQuery] = useState("");
  const [data, setData] = useState(null);
  const componentRef = useRef();
  const { handleClickDownloadPDF, pdfURL, setPdfURL } = usePDF(
    componentRef,
    info,
    query?.id,
    isDoneRequest
  );

  const fetchInfo = async (raw) => {
    return await axios(
      `${QUERIES.GET_ACERTA_ESSENCIAL}/${info.replace(/\D/g, "")}`
    );
  };

  const fetchInfoProtestos = async (raw) => {
    return await axios(
      `${QUERIES.GET_PROTESTO_NACIONAL}/${info.replace(/\D/g, "")}`
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDocument(info);

    const unmaskedValue = info.replace(/\D/g, "");
    let isValid = true;

    if (!isValidCPF(unmaskedValue)) {
      setError("CPF inválido!");
      isValid = false;
    }

    if (isValid) {
      try {
        setPdfURL("");
        setData(null);
        setInfoProtestos(null);
        setIsLoading(true);
        setIsLoadingProtestos(true);
        setError("");
        setErrorQuery("");
        setIsDoneRequest(false);
        const { data: dados } = await fetchInfo();
        setData(dados.data["essencial"]);
        setIsLoading(false);
        const protestos = await fetchInfoProtestos();
        setInfoProtestos(protestos);
        setIsLoadingProtestos(false);
      } catch (error) {
        setErrorQuery(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta"
        );
        setIsLoading(false);
      } finally {
        setIsDoneRequest(true);
      }
    }
  };

  return (
    <>
      <div className="text-lg font-semibold mb-3 title-syncx">
        {query?.name}
      </div>
      <CardQueryDescription
        title="Informações da consulta"
        infos={[
          "Dados Cadastrais",
          "Score",
          "Pendências financeiras",
          "Protestos",
          "Cheques sem fundos",
          "Cheques sustados",
        ]}
        price={selectedPrice}
      >
        <form
          className="flex flex-col gap-2 bg-white p-2 rounded-lg shadow-md w-fit"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col md:flex-row gap-2 items-end">
            <QueryInput
              info={info}
              setInfo={setInfo}
              isLoading={isLoading}
              error={error}
              type="CPF"
            />
            <div>
              {!errorQuery && data && (
                <Button
                  className={`w-fit shadow-lg secondary ${
                    data && !isLoading ? "block" : "hidden"
                  }`}
                  isProcessing={pdfURL === ""}
                  disabled={pdfURL === ""}
                  onClick={() => handleClickDownloadPDF()}
                >
                  Salvar como PDF
                </Button>
              )}
            </div>
          </div>
        </form>
      </CardQueryDescription>

      <SliderToPreview />

      {errorQuery ? (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorQuery}</span>
        </Alert>
      ) : (
        <>
          <div
            className={`overflow-x-scroll ${
              data || isLoading ? "block" : "hidden"
            }`}
            ref={componentRef}
          >
            {!isLoading ? (
              <>
                <Card className="mt-2 p-5 card-pdf">
                  <HeaderQuery title={query?.name} />

                  <TitleStripe>Dados Cadastrais</TitleStripe>
                  <div className="flex gap-16">
                    <div className="text-sm">
                      <div className="mb-3">
                        <span className="font-semibold">Nome completo: </span>
                        {data?.identificacao?.nome}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">
                          Título de eleitor:{" "}
                        </span>
                        {data?.identificacao?.tituloEleitor || "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Nome da mãe: </span>
                        {data?.identificacao?.nomeMae || "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">
                          Grau de instrução:{" "}
                        </span>
                        {data?.identificacao?.grauInstrucao
                          ? BOA_VISTA_CONTANTS.DEGREE_OF_EDUCATION[
                              data?.identificacao.grauInstrucao
                            ]
                          : "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">
                          Data de nascimento:{" "}
                        </span>
                        {data?.identificacao?.dataNascimento || "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">RG: </span>
                        {data?.identificacao?.numeroRG || "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">
                          Número de dependentes:{" "}
                        </span>
                        {data?.identificacao?.numeroDependentes ||
                          "Indisponível"}
                      </div>
                    </div>
                    <div className="text-sm">
                      <div className="mb-3">
                        <span className="font-semibold">CPF: </span>
                        {document}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Situação: </span>
                        {data?.identificacao?.situacaoReceita
                          ? BOA_VISTA_CONTANTS.SITUATION[
                              data?.identificacao.situacaoReceita
                            ]
                          : "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Origem CPF: </span>
                        {data?.identificacao?.regiaoCpf
                          ? BOA_VISTA_CONTANTS.DOCUMENT_ORIGIN[
                              data?.identificacao.regiaoCpf
                            ]
                          : "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">
                          Data de atualização:{" "}
                        </span>
                        {data?.identificacao?.dataAtualizacao || "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Sexo: </span>
                        {data?.identificacao?.sexoConsultado
                          ? BOA_VISTA_CONTANTS.SEX[
                              data?.identificacao.sexoConsultado
                            ]
                          : "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Estado Civil: </span>
                        {data?.identificacao?.estadoCivil
                          ? BOA_VISTA_CONTANTS.CIVIL_STATE[
                              data?.identificacao.estadoCivil
                            ]
                          : "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Nacionalidade: </span>
                        {data?.identificacao?.cidadeNascimento ||
                          "Indisponível"}
                      </div>
                    </div>
                  </div>

                  <TitleStripe>Quadro de avisos</TitleStripe>
                  <div className="container-alerts five-columns">
                    {data?.resumo_ocorrencias_de_debitos?.registro === "N" ? (
                      <Alert
                        color="success"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <IoMdCheckmarkCircleOutline
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">Débitos</div>
                        <div className="text-center font-semibold">
                          Nada consta
                        </div>
                      </Alert>
                    ) : (
                      <Alert
                        color="failure"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <FiAlertOctagon
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">Débitos</div>
                        <div className="text-center font-semibold">
                          R$
                          {data?.resumo_ocorrencias_de_debitos?.valorAcomulado}
                        </div>
                      </Alert>
                    )}
                    {data?.resumo_devolucoes_informada_pelo_usuario?.registro ==
                    "N" ? (
                      <Alert
                        color="success"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <IoMdCheckmarkCircleOutline
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">Cheques sustados</div>
                        <div className="text-center font-semibold">
                          Nada consta
                        </div>
                      </Alert>
                    ) : (
                      <Alert
                        color="failure"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <FiAlertOctagon
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">Cheques sustados</div>
                        <div className="text-center font-semibold">
                          {
                            +data?.resumo_devolucoes_informada_pelo_usuario
                              ?.totalDevolucoes
                          }{" "}
                          ocorrências
                        </div>
                      </Alert>
                    )}
                    {data?.resumo_devolucoes_informadas_pelo_ccf?.registro ==
                    "N" ? (
                      <Alert
                        color="success"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <IoMdCheckmarkCircleOutline
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">Cheques sem fundos</div>
                        <div className="text-center font-semibold">
                          Nada consta
                        </div>
                      </Alert>
                    ) : (
                      <Alert
                        color="failure"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <FiAlertOctagon
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">Cheques sem fundos</div>
                        <div className="text-center font-semibold">
                          {
                            data?.resumo_devolucoes_informadas_pelo_ccf
                              ?.totalDevolucoes
                          }{" "}
                          ocorrências
                        </div>
                      </Alert>
                    )}
                    {data?.resumo_titulos_protestados?.registro == "N" ? (
                      <Alert
                        color="success"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <IoMdCheckmarkCircleOutline
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">Títulos protestados</div>
                        <div className="text-center font-semibold">
                          Nada consta
                        </div>
                      </Alert>
                    ) : (
                      <Alert
                        color="failure"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <FiAlertOctagon
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">Títulos protestados</div>
                        <div className="text-center font-semibold">
                          {data?.resumo_titulos_protestados?.total} ocorrências
                        </div>
                      </Alert>
                    )}

                    {isLoadingProtestos ? (
                      <Alert
                        color="gray"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <div className="text-center">
                          <Spinner
                            aria-label="Large spinner example"
                            size="md"
                          />
                        </div>
                        <div className="text-center mt-2">
                          Protestos dir. cartório
                        </div>
                        <div role="status" className="w-full animate-pulse">
                          <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mt-1"></div>
                        </div>
                      </Alert>
                    ) : (
                      <>
                        {+infoProtestos?.data?.data?.CREDCADASTRAL
                          ?.PROTESTO_SINTETICO?.QUANTIDADE_OCORRENCIA === 0 ? (
                          <Alert
                            color="success"
                            withBorderAccent
                            className="flex justify-center items-center flex-col"
                          >
                            <IoMdCheckmarkCircleOutline
                              fontSize={30}
                              className="mx-auto mb-1"
                            />
                            <div className="text-center">
                              Protestos dir. cartório
                            </div>
                            <div className="text-center font-semibold">
                              Nada consta
                            </div>
                          </Alert>
                        ) : (
                          <Alert
                            color="failure"
                            withBorderAccent
                            className="flex justify-center items-center flex-col"
                          >
                            <FiAlertOctagon
                              fontSize={30}
                              className="mx-auto mb-1"
                            />
                            <div className="text-center">
                              Protestos dir. cartório
                            </div>
                            <div className="text-center font-semibold">
                              {infoProtestos?.data?.data?.CREDCADASTRAL
                                ?.PROTESTO_SINTETICO ? (
                                <>
                                  {" "}
                                  {
                                    infoProtestos?.data?.data?.CREDCADASTRAL
                                      ?.PROTESTO_SINTETICO
                                      ?.QUANTIDADE_OCORRENCIA
                                  }{" "}
                                  ocorrências
                                </>
                              ) : (
                                "Erro na consulta"
                              )}
                            </div>
                          </Alert>
                        )}
                      </>
                    )}
                  </div>
                  <div>
                    <TitleStripe>
                      Score de risco |{" "}
                      {data?.score_classificacao_varios_modelos[0]?.nomeScore?.toLowerCase()}
                    </TitleStripe>
                  </div>
                  <div className="flex gap-2 items-center">
                    <div className="w-3/5 p-4">
                      <ReactApexChart
                        options={{
                          chart: {
                            type: "radialBar",
                            offsetY: -20,
                            sparkline: {
                              enabled: true,
                            },
                          },
                          plotOptions: {
                            radialBar: {
                              startAngle: -90,
                              endAngle: 90,
                              track: {
                                background: "#e7e7e7",
                                strokeWidth: "97%",
                                margin: 5, // margin is in pixels
                                dropShadow: {
                                  enabled: true,
                                  top: 2,
                                  left: 0,
                                  color: "#999",
                                  opacity: 1,
                                  blur: 2,
                                },
                              },
                              dataLabels: {
                                name: {
                                  show: false,
                                },
                                value: {
                                  offsetY: -2,
                                  fontSize: "22px",
                                  formatter: function (val) {
                                    return val * 10;
                                  },
                                },
                              },
                            },
                          },
                          grid: {
                            padding: {
                              top: -10,
                            },
                          },
                          fill: {
                            type: "solid",
                            gradient: {
                              shade: "light",
                              shadeIntensity: 0.4,
                              inverseColors: false,
                              opacityFrom: 1,
                              opacityTo: 1,
                              stops: [0, 50, 53, 91],
                            },
                            colors: (() => {
                              const score =
                                +data?.score_classificacao_varios_modelos[0]
                                  ?.score;
                              if (score >= 800) {
                                return "#01B000";
                              } else if (score >= 600) {
                                return "#8DED01";
                              } else if (score >= 400) {
                                return "#FDF700";
                              } else if (score >= 200) {
                                return "#FFBC00";
                              } else {
                                return "#c7001b";
                              }
                            })(),
                          },
                        }}
                        series={[
                          +data?.score_classificacao_varios_modelos[0]?.score /
                            10,
                        ]}
                        type="radialBar"
                      />
                    </div>
                    <div className="w-full">
                      <div className="mb-2">
                        <Alert className="w-fit mb-2">
                          Probabilidade de indadimplência ={" "}
                          {+data?.score_classificacao_varios_modelos[0]
                            ?.probabilidade / 100 || 0}
                          %
                        </Alert>{" "}
                        {data?.score_classificacao_varios_modelos[0]?.texto}
                      </div>
                    </div>
                  </div>
                  <Alert color="warning">
                    <span className="font-semibold">Importante:</span> a decisão
                    de aprovação ou não do crédito é de exclusiva
                    responsabilidade do concedente. as informações prestadas
                    pelo provedor tem como objevo subsidiar essas decisões e, em
                    hipótese alguma devem ser ulizadas como justificativa, pelo
                    concedente do crédito, para a tomada da referida decisão.
                  </Alert>
                  <div>
                    <TitleStripe className="mb-5">Renda Presumida</TitleStripe>
                    <div className="mb-3">
                      <span className="font-semibold">Faixa: </span>
                      <Badge
                        className="w-fit inline-block text-base"
                        color="green"
                      >
                        {data?.score_classificacao_varios_modelos[1]?.texto}
                      </Badge>
                    </div>
                    <div className="mb-3">
                      <span className="font-semibold">Descrição: </span>
                      {
                        data?.score_classificacao_varios_modelos[1]
                          ?.descricaoNatureza
                      }
                    </div>
                    <Alert>
                      <span className="font-semibold">Observações: </span>
                      {data?.score_classificacao_varios_modelos[1]?.texto2}
                    </Alert>
                  </div>

                  <div>
                    <TitleStripe className="mb-5">
                      Pendências financeiras
                    </TitleStripe>

                    {data?.resumo_ocorrencias_de_debitos?.registro === "S" ? (
                      <>
                        <div className="flex gap-6">
                          <div className="flex gap-2 items-center">
                            <span className="font-semibold text-red-700">
                              Quantidade:{" "}
                            </span>
                            <Badge color="failure" className="w-fit">
                              {
                                data?.resumo_ocorrencias_de_debitos
                                  ?.totalDevedor
                              }
                            </Badge>
                          </div>
                          <div className="flex gap-2 items-center">
                            <span className="font-semibold text-red-700">
                              Valor Total:{" "}
                            </span>
                            <Badge color="failure" className="w-fit">
                              R$
                              {
                                data?.resumo_ocorrencias_de_debitos
                                  ?.valorAcomulado
                              }
                            </Badge>
                          </div>
                        </div>
                        <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                          <Table striped>
                            <Table.Head>
                              <Table.HeadCell className="text-center bg-red-300">
                                Data
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Informante
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Cidade
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Situação
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Contrato
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Valor
                              </Table.HeadCell>
                            </Table.Head>

                            <Table.Body className="divide-y">
                              {Array.isArray(data?.debitos) ? (
                                <>
                                  {data?.debitos?.map((debito, index) => (
                                    <Table.Row
                                      key={index}
                                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                    >
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                        {debito.dataOcorrencia}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {debito.informante}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {debito.cidade}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {debito.situacao}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {debito.contrato}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {debito.moeda}
                                        {debito.valor}
                                      </Table.Cell>
                                    </Table.Row>
                                  ))}
                                </>
                              ) : (
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                  <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                    {data?.debitos?.dataOcorrencia}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {data?.debitos?.informante}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {data?.debitos?.cidade}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {data?.debitos?.situacao}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {data?.debitos?.contrato}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {data?.debitos?.moeda}
                                    {data?.debitos?.valor}
                                  </Table.Cell>
                                </Table.Row>
                              )}
                            </Table.Body>
                          </Table>
                        </div>
                      </>
                    ) : (
                      <Card className={`bg-green-200 mt-4`}>
                        <div className="flex gap-2 items-center text-gray-600 font-semibold">
                          <div>
                            <IoAlertCircleOutline fontSize={20} />
                          </div>
                          <div>Nada consta</div>
                        </div>
                      </Card>
                    )}

                    <div>
                      <TitleStripe className="mb-5">
                        Cheques sustados
                      </TitleStripe>

                      {data?.resumo_devolucoes_informada_pelo_usuario
                        ?.registro === "S" ? (
                        <>
                          <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                            <Table striped>
                              <Table.Head>
                                <Table.HeadCell className="text-center bg-red-300">
                                  Nome
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-red-300">
                                  Nome do banco
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-red-300">
                                  Agência
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-red-300">
                                  Documento
                                </Table.HeadCell>
                              </Table.Head>

                              <Table.Body className="divide-y">
                                {Array.isArray(
                                  data?.cheques_sustados_pelo_motivo_21
                                ) ? (
                                  <>
                                    {data?.cheques_sustados_pelo_motivo_21?.map(
                                      (cheque, index) => (
                                        <Table.Row
                                          key={index}
                                          className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                        >
                                          <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                            {cheque.nome}
                                          </Table.Cell>
                                          <Table.Cell className="text-center">
                                            {cheque.nomeBanco}
                                          </Table.Cell>
                                          <Table.Cell className="text-center">
                                            {cheque.agencia}
                                          </Table.Cell>
                                          <Table.Cell className="text-center">
                                            {cheque.numeroDocumento}
                                          </Table.Cell>
                                        </Table.Row>
                                      )
                                    )}
                                  </>
                                ) : (
                                  <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                      {
                                        data?.cheques_sustados_pelo_motivo_21
                                          ?.nome
                                      }
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {
                                        data?.cheques_sustados_pelo_motivo_21
                                          ?.nomeBanco
                                      }
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {
                                        data?.cheques_sustados_pelo_motivo_21
                                          ?.agencia
                                      }
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {
                                        data?.cheques_sustados_pelo_motivo_21
                                          ?.numeroDocumento
                                      }
                                    </Table.Cell>
                                  </Table.Row>
                                )}
                              </Table.Body>
                            </Table>
                          </div>
                        </>
                      ) : (
                        <Card className={`bg-green-200`}>
                          <div className="flex gap-2 items-center text-gray-600 font-semibold">
                            <div>
                              <IoAlertCircleOutline fontSize={20} />
                            </div>
                            <div>Nada consta</div>
                          </div>
                        </Card>
                      )}
                    </div>
                    <div>
                      <TitleStripe className="mb-5">
                        Cheques sem fundos
                      </TitleStripe>

                      {data?.resumo_devolucoes_informadas_pelo_ccf?.registro ===
                      "S" ? (
                        <>
                          <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                            <Table striped>
                              <Table.Head>
                                <Table.HeadCell className="text-center bg-red-300">
                                  Nome
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-red-300">
                                  Nome do banco
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-red-300">
                                  Agência
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-red-300">
                                  Documento
                                </Table.HeadCell>
                              </Table.Head>

                              <Table.Body className="divide-y">
                                {Array.isArray(
                                  data?.relacao_devolucoes_informadas_pelo_ccf
                                ) ? (
                                  <>
                                    {data?.relacao_devolucoes_informadas_pelo_ccf?.map(
                                      (cheque, index) => (
                                        <Table.Row
                                          key={index}
                                          className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                        >
                                          <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                            {cheque.nome}
                                          </Table.Cell>
                                          <Table.Cell className="text-center">
                                            {cheque.nomeBanco}
                                          </Table.Cell>
                                          <Table.Cell className="text-center">
                                            {cheque.agencia}
                                          </Table.Cell>
                                          <Table.Cell className="text-center">
                                            {cheque.numeroDocumento}
                                          </Table.Cell>
                                        </Table.Row>
                                      )
                                    )}
                                  </>
                                ) : (
                                  <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                      {
                                        data
                                          ?.relacao_devolucoes_informadas_pelo_ccf
                                          ?.nome
                                      }
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {
                                        data
                                          ?.relacao_devolucoes_informadas_pelo_ccf
                                          ?.nomeBanco
                                      }
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {
                                        data
                                          ?.relacao_devolucoes_informadas_pelo_ccf
                                          ?.agencia
                                      }
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {
                                        data
                                          ?.relacao_devolucoes_informadas_pelo_ccf
                                          ?.numeroDocumento
                                      }
                                    </Table.Cell>
                                  </Table.Row>
                                )}
                              </Table.Body>
                            </Table>
                          </div>
                        </>
                      ) : (
                        <Card className={`bg-green-200`}>
                          <div className="flex gap-2 items-center text-gray-600 font-semibold">
                            <div>
                              <IoAlertCircleOutline fontSize={20} />
                            </div>
                            <div>Nada consta</div>
                          </div>
                        </Card>
                      )}
                    </div>

                    <div>
                      <TitleStripe className="mb-5">
                        Títulos protestados
                      </TitleStripe>

                      {data?.resumo_titulos_protestados?.registro === "S" ? (
                        <>
                          <div className="flex gap-6">
                            <div className="flex gap-2 items-center">
                              <span className="font-semibold text-red-700">
                                Quantidade:{" "}
                              </span>
                              <Badge color="failure" className="w-fit">
                                {data?.resumo_titulos_protestados?.total}
                              </Badge>
                            </div>
                            <div className="flex gap-2 items-center">
                              <span className="font-semibold text-red-700">
                                Valor Total:{" "}
                              </span>
                              <Badge color="failure" className="w-fit">
                                R$
                                {
                                  data?.resumo_titulos_protestados
                                    ?.valorAcumulado
                                }
                              </Badge>
                            </div>
                          </div>
                          <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                            <Table striped>
                              <Table.Head>
                                <Table.HeadCell className="text-center bg-red-300">
                                  Data
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-red-300">
                                  Cartório
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-red-300">
                                  Cidade
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-red-300">
                                  Tipo da ocorrência
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-red-300">
                                  Valor
                                </Table.HeadCell>
                              </Table.Head>

                              <Table.Body className="divide-y">
                                {Array.isArray(data?.titulos_protestados) ? (
                                  <>
                                    {data?.titulos_protestados?.map(
                                      (titulo, index) => (
                                        <Table.Row
                                          key={index}
                                          className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                        >
                                          <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                            {titulo.dataOcorrencia}
                                          </Table.Cell>
                                          <Table.Cell className="text-center">
                                            {titulo.cartorio}
                                          </Table.Cell>
                                          <Table.Cell className="text-center">
                                            {titulo.cidade}
                                          </Table.Cell>
                                          <Table.Cell className="text-center">
                                            {titulo.tipoOcorrencia}
                                          </Table.Cell>
                                          <Table.Cell className="text-center">
                                            {titulo.moeda}
                                            {titulo.valor}
                                          </Table.Cell>
                                        </Table.Row>
                                      )
                                    )}
                                  </>
                                ) : (
                                  <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                      {
                                        data?.titulos_protestados
                                          ?.dataOcorrencia
                                      }
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {data?.titulos_protestados?.cartorio}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {data?.titulos_protestados?.cidade}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {
                                        data?.titulos_protestados
                                          ?.tipoOcorrencia
                                      }
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {data?.titulos_protestados?.moeda}
                                      {data?.titulos_protestados?.valor}
                                    </Table.Cell>
                                  </Table.Row>
                                )}
                              </Table.Body>
                            </Table>
                          </div>
                        </>
                      ) : (
                        <Card className={`bg-green-200 mt-4`}>
                          <div className="flex gap-2 items-center text-gray-600 font-semibold">
                            <div>
                              <IoAlertCircleOutline fontSize={20} />
                            </div>
                            <div>Nada consta</div>
                          </div>
                        </Card>
                      )}
                    </div>
                  </div>

                  <div>
                    <TitleStripe className="mb-5">
                      Protestos direto do cartório
                    </TitleStripe>
                    {!isLoadingProtestos ? (
                      <>
                        {+infoProtestos?.data?.data?.CREDCADASTRAL
                          ?.PROTESTO_SINTETICO?.QUANTIDADE_OCORRENCIA > 0 ? (
                          <>
                            <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                              <Table striped>
                                <Table.Head>
                                  <Table.HeadCell className="bg-red-300">
                                    Cartório
                                  </Table.HeadCell>
                                  <Table.HeadCell className="bg-red-300">
                                    Credor
                                  </Table.HeadCell>
                                  <Table.HeadCell className="text-center bg-red-300">
                                    Telefone
                                  </Table.HeadCell>
                                  <Table.HeadCell className="text-center bg-red-300">
                                    Valor
                                  </Table.HeadCell>
                                </Table.Head>

                                <Table.Body className="divide-y text-sm">
                                  {infoProtestos?.data?.data?.CREDCADASTRAL?.PROTESTO_SINTETICO?.OCORRENCIAS?.map(
                                    (protesto, index) => (
                                      <Table.Row
                                        className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                        key={index}
                                      >
                                        <Table.Cell className="font-medium text-gray-900 dark:text-white">
                                          {protesto?.CARTORIO}
                                        </Table.Cell>
                                        <Table.Cell>
                                          {protesto?.CREDOR}
                                        </Table.Cell>
                                        <Table.Cell className="text-center">
                                          {protesto?.TELEFONE}
                                        </Table.Cell>
                                        <Table.Cell className="text-center">
                                          {formatToReal(protesto?.VALOR)}
                                        </Table.Cell>
                                      </Table.Row>
                                    )
                                  )}
                                </Table.Body>
                              </Table>
                            </div>
                          </>
                        ) : (
                          <Card className={`bg-green-200  mb-3`}>
                            <div className="flex gap-2 items-center text-gray-600 font-semibold">
                              <div>
                                <FaRegCheckCircle fontSize={20} />
                              </div>
                              <div>Nada consta</div>
                            </div>
                          </Card>
                        )}
                      </>
                    ) : (
                      <div role="status" className="w-full animate-pulse">
                        <div className="h-7 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-4"></div>{" "}
                      </div>
                    )}
                  </div>

                  <div>
                    <TitleStripe className="mb-5">
                      Últimas consultas
                    </TitleStripe>

                    {data?.resumoConsultas_anteriores_90_dias?.registro ===
                    "S" ? (
                      <>
                        <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                          <Table striped>
                            <Table.Head>
                              <Table.HeadCell className="text-center bg-gray-300">
                                Data
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-gray-300">
                                Informante
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-gray-300">
                                Tipo de ocorrência
                              </Table.HeadCell>
                            </Table.Head>

                            <Table.Body className="divide-y">
                              {Array.isArray(data?.consultas_anteriores) ? (
                                <>
                                  {" "}
                                  {data?.consultas_anteriores?.map(
                                    (consult, index) => (
                                      <Table.Row
                                        key={index}
                                        className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                      >
                                        <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                          {consult.data}
                                        </Table.Cell>
                                        <Table.Cell className="text-center">
                                          {consult.informante}
                                        </Table.Cell>
                                        <Table.Cell className="text-center">
                                          {consult.tipoOcorrencia}
                                        </Table.Cell>
                                      </Table.Row>
                                    )
                                  )}
                                </>
                              ) : (
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                  <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                    {data?.consultas_anteriores?.data}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {data?.consultas_anteriores?.informante}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {data?.consultas_anteriores?.tipoOcorrencia}
                                  </Table.Cell>
                                </Table.Row>
                              )}
                            </Table.Body>
                          </Table>
                        </div>
                      </>
                    ) : (
                      <Card className={`bg-green-200 mt-4`}>
                        <div className="flex gap-2 items-center text-gray-600 font-semibold">
                          <div>
                            <IoAlertCircleOutline fontSize={20} />
                          </div>
                          <div>Nada consta</div>
                        </div>
                      </Card>
                    )}
                  </div>
                  <div className="mt-10 italic border border-gray-200 p-2">
                    <span className="font-semibold">Observações:</span> as
                    informações evidenciadas em “Dados Cadastrais Básicos” são
                    provenientes de base de dados oficial da Receita Federal do
                    Brasil. Para maior detalhamento e cruzamento com outras
                    fontes de dados cadastrais, acessar a consulta “Dados
                    Cadastrais” no painel de consultas.
                  </div>
                </Card>
              </>
            ) : (
              <QueryLoading />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default PositivoPlusCPFQuery;
