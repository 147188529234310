import { Alert, Button, Card, Table, Timeline } from "flowbite-react";
import React, { useEffect, useRef, useState } from "react";
import { IoAlertCircleOutline } from "react-icons/io5";
import {
  formatToReal,
  isValidCNPJ,
  isValidCPF,
  uploadPDF,
} from "../../utils/utils";
import axios from "../../config/axiosInstance";
import { QUERIES } from "../../config/apiConfig";
import { HiInformationCircle } from "react-icons/hi";
import HeaderQuery from "./HeaderQuery";
import usePDF from "../../hooks/usePDF";
import TitleStripe from "../common/TitleStripe";
import CardQueryDescription from "../common/CardQueryDescription";
import QueryInput from "../inputs/QueryInput";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { FiAlertOctagon } from "react-icons/fi";
import SliderToPreview from "../common/SliderToPreview";
import QueryLoading from "../common/QueryLoading";

const CompletaPlusQuery = ({ query, selectedPrice }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [error, setError] = useState("");
  const [errorData, setErrorData] = useState("");
  const [data, setData] = useState(null);
  const [document, setDocument] = useState("");
  const componentRef = useRef();
  const { handleClickDownloadPDF, pdfURL, setPdfURL } = usePDF(
    componentRef,
    info,
    query?.id,
    data
  );

  const fetchInfo = async (type) => {
    return await axios(
      `${QUERIES.GET_COMPLETA_LUS}/${info.replace(/\D/g, "")}`
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setDocument(info);
    const unmaskedValue = info.replace(/\D/g, "");
    let isValid = true;
    let type = "F";

    if (unmaskedValue.length <= 11) {
      if (!isValidCPF(unmaskedValue)) {
        setError("CPF inválido!");
        isValid = false;
      }
    } else {
      if (!isValidCNPJ(unmaskedValue)) {
        setError("CNPJ inválido!");
        isValid = false;
      }
      type = "J";
    }

    if (isValid) {
      try {
        setPdfURL("");
        setData(null);
        setIsLoading(true);
        setErrorData("");
        const { data: dados } = await fetchInfo(type);
        setData(dados.data);
      } catch (error) {
        setErrorData(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta"
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <div className="text-lg font-semibold mb-3 title-syncx">
        {query?.name}
      </div>

      <CardQueryDescription
        title="Informações da consulta"
        infos={[
          "PEFIN/REFIN",
          "Pendências",
          "Protestos",
          "Cheques sem fundos",
          "Cadin",
        ]}
        price={selectedPrice}
      >
        <form
          className="flex flex-col gap-2 bg-white p-2 mt-5 rounded-lg shadow-md w-fit"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col md:flex-row gap-2 items-end">
            <QueryInput
              info={info}
              setInfo={setInfo}
              isLoading={isLoading}
              error={error}
              type="CPF/CNPJ"
            />
            {!errorData && data && (
              <Button
                className={`w-fit shadow-lg secondary ${
                  data && !isLoading ? "block" : "hidden"
                }`}
                isProcessing={pdfURL === ""}
                disabled={pdfURL === ""}
                onClick={() => handleClickDownloadPDF()}
              >
                Salvar como PDF
              </Button>
            )}
          </div>
        </form>
      </CardQueryDescription>

      <SliderToPreview />

      {errorData && (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorData}</span>
        </Alert>
      )}
      {!errorData && (
        <div
          className={`overflow-x-scroll ${
            data || isLoading ? "block" : "hidden"
          }`}
          ref={componentRef}
        >
          {!isLoading ? (
            <Card className="mt-2 card-pdf">
              <HeaderQuery title={query?.name} />
              <div className="title-syncx">Quadro de avisos</div>
              <div className="container-alerts five-columns">
                {data?.scpc?.length === 0 ? (
                  <Alert
                    color="success"
                    withBorderAccent
                    className="flex justify-center items-center flex-col"
                  >
                    <IoMdCheckmarkCircleOutline
                      fontSize={30}
                      className="mx-auto mb-1"
                    />
                    <div className="text-center">SCPC</div>
                    <div className="text-center font-semibold">Nada consta</div>
                  </Alert>
                ) : (
                  <Alert
                    color="failure"
                    withBorderAccent
                    className="flex justify-center items-center flex-col"
                  >
                    <FiAlertOctagon fontSize={30} className="mx-auto mb-1" />
                    <div className="text-center">SCPC</div>
                    <div className="text-center font-semibold">
                      {data?.scpc?.length} ocorrências
                    </div>
                  </Alert>
                )}

                {data?.cadin?.length === 0 ? (
                  <Alert
                    color="success"
                    withBorderAccent
                    className="flex justify-center items-center flex-col"
                  >
                    <IoMdCheckmarkCircleOutline
                      fontSize={30}
                      className="mx-auto mb-1"
                    />
                    <div className="text-center">CADIN</div>
                    <div className="text-center font-semibold">Nada consta</div>
                  </Alert>
                ) : (
                  <Alert
                    color="failure"
                    withBorderAccent
                    className="flex justify-center items-center flex-col"
                  >
                    <FiAlertOctagon fontSize={30} className="mx-auto mb-1" />
                    <div className="text-center">CADIN</div>
                    <div className="text-center font-semibold">
                      {data?.cadin?.length} ocorrências
                    </div>
                  </Alert>
                )}

                {data?.protestos?.length === 0 ? (
                  <Alert
                    color="success"
                    withBorderAccent
                    className="flex justify-center items-center flex-col"
                  >
                    <IoMdCheckmarkCircleOutline
                      fontSize={30}
                      className="mx-auto mb-1"
                    />
                    <div className="text-center">Protestos</div>
                    <div className="text-center font-semibold">Nada consta</div>
                  </Alert>
                ) : (
                  <Alert
                    color="failure"
                    withBorderAccent
                    className="flex justify-center items-center flex-col"
                  >
                    <FiAlertOctagon fontSize={30} className="mx-auto mb-1" />
                    <div className="text-center">Protestos</div>
                    <div className="text-center font-semibold">
                      {data?.protestos?.length} ocorrências
                    </div>
                  </Alert>
                )}

                {data?.refin_pefin?.length === 0 ? (
                  <Alert
                    color="success"
                    withBorderAccent
                    className="flex justify-center items-center flex-col"
                  >
                    <IoMdCheckmarkCircleOutline
                      fontSize={30}
                      className="mx-auto mb-1"
                    />
                    <div className="text-center">Refin/Pefin</div>
                    <div className="text-center font-semibold">Nada consta</div>
                  </Alert>
                ) : (
                  <Alert
                    color="failure"
                    withBorderAccent
                    className="flex justify-center items-center flex-col"
                  >
                    <FiAlertOctagon fontSize={30} className="mx-auto mb-1" />
                    <div className="text-center">Refin/Pefin</div>
                    <div className="text-center font-semibold">
                      {data?.refin_pefin?.length} ocorrências
                    </div>
                  </Alert>
                )}

                {data?.cheques?.length === 0 ? (
                  <Alert
                    color="success"
                    withBorderAccent
                    className="flex justify-center items-center flex-col"
                  >
                    <IoMdCheckmarkCircleOutline
                      fontSize={30}
                      className="mx-auto mb-1"
                    />
                    <div className="text-center">Cheques sem fundo</div>
                    <div className="text-center font-semibold">Nada consta</div>
                  </Alert>
                ) : (
                  <Alert
                    color="failure"
                    withBorderAccent
                    className="flex justify-center items-center flex-col"
                  >
                    <FiAlertOctagon fontSize={30} className="mx-auto mb-1" />
                    <div className="text-center">Cheques sem fundo</div>
                    <div className="text-center font-semibold">
                      {data?.cheques?.length} ocorrências
                    </div>
                  </Alert>
                )}
              </div>

              <TitleStripe>Informações:</TitleStripe>
              <div className="flex gap-20">
                <div className="flex-1">
                  <div className="mb-3">
                    <span className="font-semibold">Documento: </span>
                    {document}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">Tipo: </span>
                    {document?.length === 14 ? "Física" : "Jurídica"}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">Nome: </span>
                    {data?.nome}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">Data: </span>
                    {data?.data}
                  </div>
                </div>
              </div>

              <div>
                <TitleStripe>Quadro societário</TitleStripe>
                {data?.empresas?.length > 0 ? (
                  <Timeline className="mt-4">
                    {data?.empresas?.map((empresa, index) => (
                      <Timeline.Item key={index}>
                        <Timeline.Point />
                        <Timeline.Content>
                          <Timeline.Time>CNPJ: {empresa.cnpj}</Timeline.Time>
                          <Timeline.Title>
                            {empresa.nome_empresa}
                          </Timeline.Title>
                          <Timeline.Body>
                            <div>Razão Social: {empresa.razaosocial}</div>
                            <div>Participação: {empresa.porcentagem}%</div>
                            <div>Cidade: {empresa.cidade} </div>
                          </Timeline.Body>
                        </Timeline.Content>
                      </Timeline.Item>
                    ))}
                  </Timeline>
                ) : (
                  <Card className={`bg-green-200 mt-4`}>
                    <div className="flex gap-2 items-center text-gray-600 font-semibold">
                      <div>
                        <IoAlertCircleOutline fontSize={20} />
                      </div>
                      <div>Nada consta</div>
                    </div>
                  </Card>
                )}
              </div>

              <div>
                <TitleStripe className="mb-5">SCPC</TitleStripe>
                {data?.scpc?.length > 0 ? (
                  <>
                    <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                      <Table striped>
                        <Table.Head>
                          <Table.HeadCell className="bg-red-300">
                            Data
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-red-300">
                            Nome
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-red-300">
                            Contrato
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-red-300">
                            Cidade
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-red-300">
                            Valor
                          </Table.HeadCell>
                        </Table.Head>

                        <Table.Body className="divide-y">
                          {data?.scpc?.map((scpc, index) => (
                            <Table.Row
                              className="bg-white dark:border-gray-700 dark:bg-gray-800"
                              key={index}
                            >
                              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white font-semibold">
                                {scpc.data}
                              </Table.Cell>
                              <Table.Cell className="text-center font-semibold">
                                {scpc.nome}
                              </Table.Cell>
                              <Table.Cell className="text-center font-semibold">
                                {scpc.contrato}
                              </Table.Cell>
                              <Table.Cell className="text-center font-semibold">
                                {scpc.cidade}
                              </Table.Cell>
                              <Table.Cell className="text-center font-semibold">
                                {scpc.valor}
                              </Table.Cell>
                            </Table.Row>
                          ))}
                        </Table.Body>
                      </Table>
                    </div>
                  </>
                ) : (
                  <Card className={`bg-green-200 mt-4`}>
                    <div className="flex gap-2 items-center text-gray-600 font-semibold">
                      <div>
                        <IoAlertCircleOutline fontSize={20} />
                      </div>
                      <div>Nada consta</div>
                    </div>
                  </Card>
                )}
              </div>

              <div>
                <TitleStripe className="mb-5">CADIN</TitleStripe>
                {data?.cadin?.length > 0 ? (
                  <>
                    <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                      <Table striped>
                        <Table.Head>
                          <Table.HeadCell className="bg-red-300">
                            Sequência
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-red-300">
                            Sigla Credor
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-red-300">
                            Credor
                          </Table.HeadCell>
                        </Table.Head>

                        <Table.Body className="divide-y">
                          {data?.cadin?.map((cadin, index) => (
                            <Table.Row
                              className="bg-white dark:border-gray-700 dark:bg-gray-800"
                              key={index}
                            >
                              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white font-semibold">
                                {cadin.sequencia}
                              </Table.Cell>
                              <Table.Cell className="text-center font-semibold">
                                {cadin.sigla_credor}
                              </Table.Cell>
                              <Table.Cell className="text-center font-semibold">
                                {cadin.nome_credor}
                              </Table.Cell>
                            </Table.Row>
                          ))}
                        </Table.Body>
                      </Table>
                    </div>
                  </>
                ) : (
                  <Card className={`bg-green-200 mt-4`}>
                    <div className="flex gap-2 items-center text-gray-600 font-semibold">
                      <div>
                        <IoAlertCircleOutline fontSize={20} />
                      </div>
                      <div>Nada consta</div>
                    </div>
                  </Card>
                )}
              </div>

              <div>
                <TitleStripe className="mb-5">Protestos</TitleStripe>
                {data?.protestos?.length > 0 ? (
                  <>
                    <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                      <Table striped>
                        <Table.Head>
                          <Table.HeadCell className="bg-red-300">
                            Data
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-red-300">
                            Nome
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-red-300">
                            Cidade
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-red-300">
                            Valor
                          </Table.HeadCell>
                        </Table.Head>

                        <Table.Body className="divide-y">
                          {data?.protestos?.map((protesto, index) => (
                            <Table.Row
                              className="bg-white dark:border-gray-700 dark:bg-gray-800"
                              key={index}
                            >
                              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white font-semibold">
                                {protesto.data}
                              </Table.Cell>
                              <Table.Cell className="text-center font-semibold">
                                {protesto.cartorio}
                              </Table.Cell>
                              <Table.Cell className="text-center font-semibold">
                                {protesto.cidade}
                              </Table.Cell>
                              <Table.Cell className="text-center font-semibold">
                                {protesto.valor}
                              </Table.Cell>
                            </Table.Row>
                          ))}
                        </Table.Body>
                      </Table>
                    </div>
                  </>
                ) : (
                  <Card className={`bg-green-200 mt-4`}>
                    <div className="flex gap-2 items-center text-gray-600 font-semibold">
                      <div>
                        <IoAlertCircleOutline fontSize={20} />
                      </div>
                      <div>Nada consta</div>
                    </div>
                  </Card>
                )}
              </div>

              <div>
                <TitleStripe className="mb-5">Refin/Pefin</TitleStripe>
                {data?.refin_pefin?.length > 0 ? (
                  <>
                    <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                      <Table striped>
                        <Table.Head>
                          <Table.HeadCell className="bg-red-300">
                            Data
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-red-300">
                            Empresa
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-red-300">
                            Tipo
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-red-300">
                            Contrato
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-red-300">
                            Valor
                          </Table.HeadCell>
                        </Table.Head>

                        <Table.Body className="divide-y">
                          {data?.refin_pefin?.map((refinPefin, index) => (
                            <Table.Row
                              className="bg-white dark:border-gray-700 dark:bg-gray-800"
                              key={index}
                            >
                              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white font-semibold">
                                {refinPefin.data}
                              </Table.Cell>
                              <Table.Cell className="text-center font-semibold">
                                {refinPefin.empresa}
                              </Table.Cell>
                              <Table.Cell className="text-center font-semibold">
                                {refinPefin.tipo}
                              </Table.Cell>
                              <Table.Cell className="text-center font-semibold">
                                {refinPefin.contrato}
                              </Table.Cell>
                              <Table.Cell className="text-center font-semibold">
                                {refinPefin.valor}
                              </Table.Cell>
                            </Table.Row>
                          ))}
                        </Table.Body>
                      </Table>
                    </div>
                  </>
                ) : (
                  <Card className={`bg-green-200 mt-4`}>
                    <div className="flex gap-2 items-center text-gray-600 font-semibold">
                      <div>
                        <IoAlertCircleOutline fontSize={20} />
                      </div>
                      <div>Nada consta</div>
                    </div>
                  </Card>
                )}
              </div>

              <div>
                <TitleStripe className="mb-5">Cheques sem fundo</TitleStripe>
                {data?.cheques?.length > 0 ? (
                  <>
                    <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                      <Table striped>
                        <Table.Head>
                          <Table.HeadCell className="bg-red-300">
                            Data
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-red-300">
                            Nome
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-red-300">
                            Contrato
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-red-300">
                            Cidade
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-red-300">
                            Valor
                          </Table.HeadCell>
                        </Table.Head>

                        <Table.Body className="divide-y">
                          {data?.cheques?.map((cheque, index) => (
                            <Table.Row
                              className="bg-white dark:border-gray-700 dark:bg-gray-800"
                              key={index}
                            >
                              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white font-semibold">
                                {cheque.data}
                              </Table.Cell>
                              <Table.Cell className="text-center font-semibold">
                                {cheque.nome}
                              </Table.Cell>
                              <Table.Cell className="text-center font-semibold">
                                {cheque.contrato}
                              </Table.Cell>
                              <Table.Cell className="text-center font-semibold">
                                {cheque.cidade}
                              </Table.Cell>
                              <Table.Cell className="text-center font-semibold">
                                {cheque.valor}
                              </Table.Cell>
                            </Table.Row>
                          ))}
                        </Table.Body>
                      </Table>
                    </div>
                  </>
                ) : (
                  <Card className={`bg-green-200 mt-4`}>
                    <div className="flex gap-2 items-center text-gray-600 font-semibold">
                      <div>
                        <IoAlertCircleOutline fontSize={20} />
                      </div>
                      <div>Nada consta</div>
                    </div>
                  </Card>
                )}
              </div>

              <div className="mt-10 italic border border-gray-200 p-2">
                <span className="font-semibold">Observações:</span> as
                informações evidenciadas em “Dados Cadastrais Básicos” são
                provenientes de base de dados oficial da Receita Federal do
                Brasil. Para maior detalhamento e cruzamento com outras fontes
                de dados cadastrais, acessar a consulta “Dados Cadastrais” no
                painel de consultas.
              </div>
            </Card>
          ) : (
            <QueryLoading />
          )}
        </div>
      )}
    </>
  );
};

export default CompletaPlusQuery;
