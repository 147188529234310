import { useRef } from "react";
import { FaUser } from "react-icons/fa";

const ImageInput = ({
  image,
  setImage,
  previewUrl,
  setPreviewUrl,
  className,
  ...props
}) => {
  const fileInputRef = useRef(null);

  const handleClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setTimeout(() => {
          setPreviewUrl(reader.result);
        }, 200);
      };
      reader.readAsDataURL(file);
      setImage(file);
    }
  };

  return (
    <>
      {previewUrl ? (
        <img
          className="mx-auto rounded-full shadow-md"
          src={previewUrl}
          style={{ height: "100px", width: "100px", objectFit: "cover" }}
        />
      ) : (
        <div
          className="mx-auto rounded-full shadow-md bg-gray-200 flex items-center justify-center"
          style={{ height: "100px", width: "100px" }}
        >
          <FaUser fontSize="large" />
        </div>
      )}
      <input
        type="file"
        onChange={handleImageChange}
        className="hidden"
        ref={fileInputRef}
        accept=".png, .jpg, .jpeg"
      />
      <button
        onClick={handleClick}
        className="bg-gray-600 mx-auto mt-3 rounded-md px-2 py-1 text-xs text-white block shadow-md"
      >
        Trocar imagem
      </button>
      <small className="block mt-2 text-center italic text-gray-600 text-xs">Formato da imagem: 200x200</small>
    </>
  );
};

export default ImageInput;
