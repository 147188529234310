import Input from "../inputs/Input";
import { AiOutlineLoading } from "react-icons/ai";
import { Alert, Button } from "flowbite-react";
import useCEP from "../../hooks/useCEP";
import { useForm } from "react-hook-form";
import { AVATAR, USERS } from "../../config/apiConfig";
import axios from "../../config/axiosInstance";
import { useEffect, useState } from "react";
import CPFInput from "../inputs/CPFInput";
import PhoneInput from "../inputs/PhoneInput";
import { resetValuesForm } from "../../utils/utils";
import SelectInput from "../inputs/SelectInput";
import { HiInformationCircle } from "react-icons/hi";
import ImageInput from "../inputs/ImageInput";
import { useApplication } from "../../contexts/ApplicationContext";

const UserForm = ({ user, fetchUsers, onClose, isOpen, type = "CREATE" }) => {
  const { userPermission } = useApplication();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [key, setKey] = useState(0);
  const [error, setError] = useState(null);
  const [avatarImg, setAvatarImg] = useState("");
  const [previewUrl, setPreviewUrl] = useState(null);
  const {
    register,
    getValues,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (type === "EDIT" && user) {
      Object.keys(user).forEach((key) => {
        setValue(key, user[key]);
      });
      setPreviewUrl(user?.imageUrl);
      setAvatarImg(user?.avatar);
      setKey((prev) => prev + 1);
    } else if (type === "CREATE") {
      resetValuesForm(["cpf", "phoneNumber"], setValue, reset);
      setPreviewUrl("");
      setAvatarImg("");
    }
    setValue("password", "");
    setError(null);
  }, [user, isOpen]);

  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);

      // if (previewUrl.includes("data:image/")) {
      //   const formData = new FormData();
      //   formData.append("image", avatarImg);
      //   const { data: avatar } = await axios.post(
      //     `${AVATAR.UPLOAD}?avatar=${user?.avatar || ""}`,
      //     formData,
      //     {
      //       headers: {
      //         "Content-Type": "multipart/form-data",
      //       },
      //     }
      //   );
      //   data.avatar = avatar.imageName;
      // }

      // if (user?.email !== data.email) {
      //   const responseEmail = await axios.get(
      //     `${USERS.GET_USER}/email/${data.email}`
      //   );

      //   if (responseEmail.status === "error") {
      //     throw new Error("E-mail já cadastrado");
      //   }
      // }

      if (type === "CREATE") {
        await axios.post(USERS.GET_USER, data);
      } else {
        if (data.password === "") delete data.password;

        await axios.put(`${USERS.GET_USER}/${user.id}`, data);
      }

      onClose(false);

      fetchUsers();

      if (type === "CREATE")
        resetValuesForm(["cpf", "phoneNumber"], setValue, reset);
    } catch (error) {
      setError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const {
    cep,
    setCep,
    address,
    loading: loadingCEP,
    error: errorCEP,
  } = useCEP();

  const onClickSearchCEP = (e) => {
    setCep(getValues("addressPostalCode"));
  };

  useEffect(() => {
    setValue("address", address?.logradouro);
    setValue("addressNeighborhood", address?.bairro);
    setValue("addressCity", address?.localidade);
  }, [address]);

  return (
    <form className="mb-6" onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-6">
        <ImageInput
          image={avatarImg}
          setImage={setAvatarImg}
          previewUrl={previewUrl}
          setPreviewUrl={setPreviewUrl}
        />
      </div>
      <div className="mb-6">
        <Input
          type="text"
          label="Nome *"
          name="name"
          required={true}
          register={register}
          error={errors?.name && "Este campo é obrigatório"}
        />
      </div>

      <div className="mb-6">
        <PhoneInput
          type="text"
          label="Telefone *"
          name="phoneNumber"
          required={true}
          register={register}
          error={errors?.phoneNumber && "Este campo é obrigatório"}
        />
      </div>

      <div className="mb-6">
        <CPFInput
          type="text"
          label="Cpf"
          name="cpf"
          required={false}
          register={register}
          value={watch("cpf") || ""}
        />
      </div>

      <div className="mb-6 flex gap-2">
        <Input
          type="text"
          label="CEP"
          name="addressPostalCode"
          required={false}
          register={register}
          className="w-full"
        />
        <Button
          onClick={onClickSearchCEP}
          className="flex align-center whitespace-nowrap primary"
          style={{ width: "220px", paddingTop: "4px" }}
          isProcessing={loadingCEP}
          processingSpinner={
            <AiOutlineLoading className="h-6 w-6 animate-spin" />
          }
        >
          Buscar CEP
        </Button>
      </div>
      <div className="mb-6">
        <Input
          type="text"
          label="Endereço"
          name="address"
          required={false}
          register={register}
        />
      </div>
      <div className="mb-6">
        <div className="flex gap-4">
          <Input
            type="text"
            label="Número"
            name="addressNumber"
            required={false}
            register={register}
            className="w-full"
          />
          <Input
            type="text"
            label="Bairro"
            name="addressNeighborhood"
            required={false}
            register={register}
            className="w-full"
          />
        </div>
      </div>
      <div className="mb-6">
        <div className="flex gap-4">
          <Input
            type="text"
            label="Cidade"
            name="addressCity"
            required={false}
            register={register}
            className="w-full"
          />
        </div>
      </div>
      <div className="mb-6">
        <div className="flex flex-col md:flex-row gap-4">
          <Input
            type="date"
            label="Data de nascimento"
            name="birthday"
            required={false}
            register={register}
            className="w-full"
          />
          <div className="w-full relative">
            <select
              id="gender"
              name="gender"
              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              {...register("gender")}
            >
              <option value="">Selecione o gênero</option>
              <option value="M">Masculino</option>
              <option value="F">Feminino</option>
            </select>
            <label
              htmlFor="gender"
              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
            >
              Sexo
            </label>
          </div>
        </div>
      </div>

      <div className="mb-6">
        <Input
          type="email"
          label="E-mail *"
          name="email"
          required={true}
          register={register}
          error={errors?.email && "Este campo é obrigatório"}
        />
        {errors.email && <span>{errors.email.message}</span>}
      </div>
      {["ADMIN", "POWER_USER"].includes(userPermission) && (
        <div className="mb-6">
          <Input
            type="password"
            label={`Senha ${type === "CREATE" ? "*" : ""}`}
            name="password"
            required={type === "CREATE"}
            register={register}
          />
        </div>
      )}

      {["ADMIN", "POWER_USER"].includes(userPermission) && (
        <div className="mb-6">
          <SelectInput
            register={register}
            name="permissionId"
            label="Permissão de usuário"
          >
            <option value="ADMIN">Administrador</option>
            <option value="USER">Usuário</option>
          </SelectInput>
        </div>
      )}

      <Button
        type="submit"
        className="w-full mb-48 md:mb-4 primary"
        isProcessing={isSubmitting}
        processingSpinner={
          <AiOutlineLoading className="h-6 w-6 animate-spin" />
        }
        disabled={isSubmitting}
      >
        {type === "CREATE" ? "Criar" : "Atualizar"} usuário
      </Button>

      {error && (
        <Alert color="failure" icon={HiInformationCircle}>
          {error?.config?.method !== "get"
            ? `Erro ao {error.config.method === "put" ? "atualizar" : "criar"}{" "}
          usuário`
            : "E-mail já cadastrado"}
        </Alert>
      )}
    </form>
  );
};

export default UserForm;
