import React, { useEffect, useState } from "react";

const useCEP = () => {
  const [cep, setCep] = useState("");
  const [address, setAddress] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAddress = async () => {
      if (cep.length === 8) {
        setLoading(true);
        try {
          const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
          if (!response.ok) {
            throw new Error("CEP não encontrado");
          }
          const data = await response.json();
          setAddress(data);
          setError(null);
        } catch (error) {
          setError(error.message);
          setAddress(null);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchAddress();
  }, [cep]);

  return { cep, setCep, address, loading, error };
};

export default useCEP;
