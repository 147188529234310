import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import Icon from "@mui/material/Icon";
import { Avatar, Badge, Button, Dropdown, Timeline } from "flowbite-react";
import { permissionChecker, removeToken } from "../utils/permissions";
import { useApplication } from "../contexts/ApplicationContext";
import { calculateExpirationPeriodTest, formatToReal } from "../utils/utils";
import { IoIosArrowDown, IoMdRefresh } from "react-icons/io";
import { MdOutlinePayments } from "react-icons/md";
import ModalAddCredit from "./modals/ModalAddCredit";

const Sidebar = () => {
  const { user, userPermission, refetchUser } = useApplication();
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const [titlePage, setTitlePage] = useState("Dashboard");
  const sidebarRef = useRef(null);
  const menuBtnRef = useRef(null);
  const [expandedMenus, setExpandedMenus] = useState({});
  const [isOpenModalAddCredit, setIsOpenModalAddCredit] = useState(false);
  const daysLeft = calculateExpirationPeriodTest(
    user?.Company?.planExpirationDate
  );

  const handleToggleSidebar = (linkName = "") => {
    setIsOpen(!isOpen);
    if (linkName !== "") setTitlePage(linkName);
  };

  const toggleMenu = (menuName) => {
    setExpandedMenus((prevState) => ({
      ...prevState,
      [menuName]: !prevState[menuName],
    }));
  };

  const handleRefresh = () => {
    refetchUser();
  };

  const menuLinks = [
    {
      name: "Dashboard",
      link: "/dashboard",
      icon: "equalizer",
      permission: ["USER", "USER_CALENDARS"],
      isCollapsible: false,
      typeCompany: ["ALL"],
    },
    {
      name: "Consultas",
      link: "/queries",
      icon: "manage_search",
      permission: ["USER"],
      isCollapsible: true,
      typeCompany: ["ALL"],
      children: [
        {
          name: "Histórico",
          link: "/queries/details",
          icon: "manage_search",
          permission: ["USER"],
          isCollapsible: false,
          typeCompany: ["ALL"],
        },
        {
          name: "Créditos",
          link: "/queries/credits",
          icon: "manage_search",
          permission: ["USER"],
          isCollapsible: false,
          typeCompany: ["ALL"],
        },
        {
          name: "Cadastrais",
          link: "/queries/cadastrals",
          icon: "manage_search",
          permission: ["USER"],
          isCollapsible: false,
          typeCompany: ["ALL"],
        },
        {
          name: "Veiculares",
          link: "/queries/autos",
          icon: "manage_search",
          permission: ["USER"],
          isCollapsible: false,
        },
      ],
    },
    {
      name: "Relatórios",
      link: "/reports",
      icon: "grading",
      permission: ["USER"],
      isCollapsible: true,
      typeCompany: ["ALL"],
      children: [
        {
          name: "Simplificado",
          link: "/reports/simplified",
          icon: "manage_search",
          permission: ["USER"],
          isCollapsible: false,
          typeCompany: ["ALL"],
        },
        {
          name: "Detalhado",
          link: "/reports/detailed",
          icon: "manage_search",
          permission: ["USER"],
          isCollapsible: false,
          typeCompany: ["ALL"],
        },
      ],
    },
    {
      name: "Usuários",
      link: "/users",
      icon: "group",
      permission: ["ADMIN", "POWER_USER"],
      isCollapsible: false,
      typeCompany: ["ALL"],
    },
    {
      name: "Documentação",
      link: "/docs",
      icon: "article",
      permission: ["USER"],
      isCollapsible: false,
      typeCompany: ["ALL"],
    },
    {
      name: "Pagamentos",
      link: "/invoices",
      icon: "request_quote",
      permission: ["USER"],
      isCollapsible: false,
      typeCompany: ["ALL"],
    },
    {
      name: "Administrativo",
      link: "/support",
      icon: "support_agent",
      permission: ["ADMIN"],
      isCollapsible: true,
      typeCompany: ["REPRESENTANTE", "ADMIN"],
      children: [
        {
          name: "Auditorias",
          link: "/audits",
          icon: "history",
          permission: ["POWER_USER"],
          isCollapsible: false,
          typeCompany: ["ADMIN"],
        },
        {
          name: "Representantes",
          link: "/admin/representatives",
          icon: "manage_search",
          permission: ["ADMIN"],
          isCollapsible: false,
          typeCompany: ["REPRESENTANTE", "ADMIN"],
        },
        {
          name: "Clientes",
          link: "/admin/customers",
          icon: "manage_search",
          permission: ["ADMIN"],
          isCollapsible: false,
          typeCompany: ["REPRESENTANTE", "ADMIN"],
        },
        {
          name: "Consultas",
          link: "/admin/queries",
          icon: "manage_search",
          permission: ["POWER_USER"],
          isCollapsible: false,
          typeCompany: ["ADMIN"],
        },
        {
          name: "Histórico de consultas",
          link: "/admin/queries-history",
          icon: "manage_search",
          permission: ["ADMIN"],
          isCollapsible: false,
          typeCompany: ["REPRESENTANTE", "ADMIN"],
        },
        {
          name: "Pagamentos",
          link: "/admin/payments",
          icon: "manage_search",
          permission: ["ADMIN"],
          isCollapsible: false,
          typeCompany: ["REPRESENTANTE", "ADMIN"],
        },
      ],
    },
  ];

  const closeSidebar = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        !menuBtnRef.current.contains(event.target) &&
        isOpen
      ) {
        closeSidebar();
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [location.pathname, isOpen]);

  useEffect(() => {
    setTitlePage(
      menuLinks.find((link) => link.link === location.pathname)?.name ||
        "Dashboard"
    );
  }, []);

  return (
    <>
      <aside
        id="logo-sidebar"
        ref={sidebarRef}
        className={`fixed top-0 left-0 z-40 w-64 h-screen transition-transform bg-white border-r sm:translate-x-0 ${
          isOpen ? "" : "-translate-x-full"
        } dark:bg-gray-800 dark:border-gray-700`}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 pb-4 overflow-y-auto bg-white dark:bg-gray-800 pt-3 no-scrollbar">
          <img
            src="/assets/logo_acesso.png"
            alt="Logo da Relinq"
            style={{ height: "50px" }}
            className="mx-auto mb-4"
          />
          {user?.Company?.companyTypeId === "REPRESENTANTE" && (
            <Badge className="w-fit mx-auto -mt-2 mb-5" color="blue">
              Representante
            </Badge>
          )}

          {user?.Company?.typePayment === "PRE" && (
            <div className="flex gap-2 justify-center items-center mb-4">
              <Badge className="w-fit shadow text-sm">
                <div>Saldo: {formatToReal(user?.Company?.credit || 0)}</div>
              </Badge>
              <button
                className="rounded shadow bg-primary text-white px-2 py-1"
                onClick={() => setIsOpenModalAddCredit(true)}
              >
                <MdOutlinePayments fontSize={18} />
              </button>
              <button
                className="rounded shadow bg-white text-white px-2 py-1"
                onClick={handleRefresh}
              >
                <IoMdRefresh fontSize={18} className="color-primary" />
              </button>
            </div>
          )}

          {daysLeft > 0 && (
            <ul className="space-y-2 font-medium">
              {menuLinks.map((menuLink, index) => (
                <React.Fragment key={index}>
                  {permissionChecker(
                    menuLink.permission,
                    userPermission,
                    user,
                    menuLink.typeCompany
                  ) && (
                    <li className={`relative rounded-lg`}>
                      {!menuLink.isCollapsible ? (
                        <Link
                          to={menuLink.link}
                          className={`flex items-center p-2 text-gray-900 group rounded-lg ${
                            location.pathname === menuLink.link &&
                            "active shadow-lg"
                          }`}
                          onClick={() => handleToggleSidebar(menuLink.name)}
                        >
                          <Icon>{menuLink.icon}</Icon>
                          <span className="ms-3">{menuLink.name}</span>
                        </Link>
                      ) : (
                        <div
                          className="flex justify-between items-center cursor-pointer"
                          onClick={() => toggleMenu(menuLink.name)}
                        >
                          <div
                            className="flex items-center gap-3 p-2"
                            style={{ color: "var(--bg-100)" }}
                          >
                            <Icon>{menuLink.icon}</Icon>
                            <span>{menuLink.name}</span>
                          </div>
                          <IoIosArrowDown
                            className={`mr-2 ${
                              expandedMenus[menuLink.name] && "rotate-180"
                            } transition text-white`}
                          />
                        </div>
                      )}

                      {menuLink?.children &&
                        menuLink.children.length > 0 &&
                        expandedMenus[menuLink.name] && (
                          <ul className="mt-2 py-2 rounded-lg mx-2 bg-white flex flex-col gap-2">
                            {menuLink.children
                              .filter((item) =>
                                permissionChecker(
                                  item.permission,
                                  userPermission,
                                  user,
                                  menuLink.typeCompany
                                )
                              )
                              .map((item, index) => (
                                <li
                                  key={index}
                                  className="text-sm rounded-lg px-2"
                                >
                                  <Link
                                    to={item.link}
                                    className={`flex items-center p-2 group text-gray-500 rounded-lg ${
                                      location.pathname === item.link &&
                                      "active-submenu shadow-lg text-white"
                                    }`}
                                    onClick={() =>
                                      handleToggleSidebar(
                                        `${menuLink.name} / ${item.name}`
                                      )
                                    }
                                  >
                                    <span
                                      className={`ms-3 text-gray-500 ${
                                        location.pathname === item.link &&
                                        "text-white"
                                      }`}
                                    >
                                      {item.name}
                                    </span>
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        )}
                    </li>
                  )}
                </React.Fragment>
              ))}
            </ul>
          )}
        </div>
      </aside>
      <div className="sm:ml-64 mt-0">
        <div className="container mx-auto">
          <div className="mainbar px-5">
            <div className="flex justify-between items-center">
              <div className="flex gap-2 items-center">
                {/* <img
                  src="/assets/logo_mini.png"
                  style={{ height: "23px", marginTop: "-3px" }}
                /> */}
                <h2 className="font-bold">{titlePage}</h2>
              </div>
              <div className="flex items-center gap-3">
                <h2 className="hidden md:block">Bem vindo, {user?.name}</h2>
                <Dropdown
                  label={<Avatar rounded size="sm" img={user?.imageUrl} />}
                  arrowIcon={false}
                  inline
                >
                  <Dropdown.Header>
                    <span className="block truncate text-sm font-medium">
                      {user?.email}
                    </span>
                  </Dropdown.Header>
                  <Dropdown.Item className="py-0 px-0">
                    <Link
                      to="/profile"
                      className="w-full py-2 text-left px-4"
                      onClick={() => setTitlePage("Meu Perfil")}
                    >
                      Meu perfil
                    </Link>
                  </Dropdown.Item>

                  <Dropdown.Divider />
                  <Dropdown.Item className="py-0 px-0">
                    <a
                      target="_blank"
                      href="https://wa.me/+5515996730404"
                      className="w-full py-2 text-left px-4"
                      rel="noreferrer"
                    >
                      Entre em contato
                    </a>
                  </Dropdown.Item>
                  <Dropdown.Item className="py-0 px-0">
                    <Link
                      to="/lgpd"
                      className="w-full py-2 text-left px-4"
                      onClick={() => setTitlePage("Aviso LGPD")}
                    >
                      Aviso LGDP
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={removeToken}>Sair</Dropdown.Item>
                </Dropdown>
                <button
                  ref={menuBtnRef}
                  type="button"
                  onClick={() => handleToggleSidebar()}
                  className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden focus:outline-none dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 md:hidden"
                >
                  <span className="sr-only">Abrir sidebar</span>
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    fill="#fff"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clipRule="evenodd"
                      fillRule="evenodd"
                      d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalAddCredit
        isOpen={isOpenModalAddCredit}
        setOpenModal={setIsOpenModalAddCredit}
      />
    </>
  );
};

export default Sidebar;
