import { FaArrowsLeftRight } from "react-icons/fa6";

const SliderToPreview = () => {
  return (
    <small
      className="gap-2 items-center mt-5 mb-1 flex md:hidden justify-center"
      style={{ fontSize: 10 }}
    >
      <span>Deslize para o lado para visualizar todas as informações</span>{" "}
      <FaArrowsLeftRight />
    </small>
  );
};

export default SliderToPreview;
