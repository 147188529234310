import { FaFilter } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";

const FilterToolbar = ({
  children,
  className,
  title = "Filtros:",
  icon,
  isVisible,
  setIsVisible,
  ...props
}) => {
  return (
    <form className={`flex flex-col md:flex-row gap-0 md:gap-3 mb-1 md:mb-0 w-full md:w-fit bg-secondary rounded-md px-3 py-4 md:py-2 shadow-md items-start md:items-center`} {...props}>
      <div
        className="text-sm flex justify-between items-center w-full md:w-fit"
        onClick={() => setIsVisible((prev) => !prev)}
      >
        <div className="text-white flex items-center gap-1">
          {icon || <FaFilter />}
          <div>{title}</div>
        </div>
        <IoIosArrowDown
          className={`block md:hidden text-white transition ${
            isVisible ? "rotate-180" : ""
          }`}
        />
      </div>
      <div
        className={`flex flex-col md:flex-row gap-3 md:mb-0 w-full md:w-fit overflow-hidden cursor-pointer duration-300 ease-in-out ${
          isVisible ? "max-h-auto mt-4" : "max-h-0 md:max-h-screen"
        }`}
      >
        {children}
      </div>
    </form>
  );
};

export default FilterToolbar;
