import { jwtDecode } from "jwt-decode";

export const permissionChecker = (
  permissions,
  userPermission,
  user,
  typeCompany
) => {
  try {
    if (
      user.Company.companyTypeId === "CLIENTE" &&
      !typeCompany.includes("ALL")
    ) {
      return false;
    }

    if (
      permissions.includes("POWER_USER") &&
      userPermission.includes("ADMIN") &&
      user.Company.companyId
    ) {
      return false;
    }

    if (["ADMIN", "POWER_USER"].includes(userPermission)) return true;

    return permissions.includes(userPermission);
  } catch (error) {
    return false;
  }
};

export const getUserId = () => {
  try {
    const token = localStorage.getItem("token");
    const { user } = jwtDecode(token);

    return user.id;
  } catch (error) {
    return 0;
  }
};

export const removeToken = () => {
  localStorage.removeItem("token");
  window.location.href = "/login";
};
