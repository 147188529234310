import { Alert, Badge, Button, Card, Spinner, Table } from "flowbite-react";
import React, { useEffect, useRef, useState } from "react";
import { FaRegCheckCircle, FaSearch } from "react-icons/fa";
import { IoAlertCircleOutline } from "react-icons/io5";
import { formatToReal, isValidCNPJ } from "../../utils/utils";
import ReactApexChart from "react-apexcharts";
import axios from "../../config/axiosInstance";
import { QUERIES } from "../../config/apiConfig";
import { HiInformationCircle } from "react-icons/hi";
import HeaderQuery from "./HeaderQuery";
import usePDF from "../../hooks/usePDF";
import TitleStripe from "../common/TitleStripe";
import CardQueryDescription from "../common/CardQueryDescription";
import QueryInput from "../inputs/QueryInput";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { FiAlertOctagon } from "react-icons/fi";
import SliderToPreview from "../common/SliderToPreview";
import QueryLoading from "../common/QueryLoading";

const PositivoPlusCNPJQuery = ({ query, selectedPrice }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [document, setDocument] = useState("");
  const [error, setError] = useState("");
  const [errorData, setErrorData] = useState("");
  const [data, setData] = useState(null);
  const [isLoadingProtestos, setIsLoadingProtestos] = useState(false);
  const [infoProtestos, setInfoProtestos] = useState(null);
  const [isDoneRequest, setIsDoneRequest] = useState(false);
  const componentRef = useRef();
  const { handleClickDownloadPDF, pdfURL, setPdfURL } = usePDF(
    componentRef,
    info,
    query?.id,
    isDoneRequest
  );

  const fetchDefineRisco = async (raw) => {
    return await axios(
      `${QUERIES.GET_DEFINE_RISCO}/${info.replace(/\D/g, "")}`
    );
  };

  const fetchInfoProtestos = async (raw) => {
    return await axios(
      `${QUERIES.GET_PROTESTO_NACIONAL}/${info.replace(/\D/g, "")}`
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDocument(info);

    const unmaskedValue = info.replace(/\D/g, "");
    let isValid = true;

    if (!isValidCNPJ(unmaskedValue)) {
      setError("CNPJ inválido!");
      isValid = false;
    }

    if (isValid) {
      try {
        setPdfURL("");
        setData(null);
        setIsLoading(true);
        setError("");
        setErrorData("");
        setInfoProtestos(null);
        setIsLoadingProtestos(true);
        setIsDoneRequest(false);
        const { data: dados } = await fetchDefineRisco();
        setData(dados.data["defineRisco"]);
        setIsLoading(false);
        const protestos = await fetchInfoProtestos();
        setInfoProtestos(protestos);
        setIsLoadingProtestos(false);
      } catch (error) {
        setErrorData(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta"
        );
        setIsLoading(false);
      } finally {
        setIsDoneRequest(true);
      }
    }
  };

  return (
    <div>
      <div className="text-lg font-semibold mb-3 title-syncx">
        {query?.name}
      </div>

      <CardQueryDescription
        title="Informações da consulta"
        infos={[
          "Dados Cadastrais",
          "Score",
          "Pendências financeiras",
          "Protestos",
          "Cheques sem fundos",
          "Cheques sustados",
        ]}
        price={selectedPrice}
      >
        <form
          className="flex flex-col gap-2 bg-white p-2 mt-2 rounded-lg shadow-md w-fit"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col md:flex-row gap-2 items-end">
            <QueryInput
              info={info}
              setInfo={setInfo}
              isLoading={isLoading}
              error={error}
              type="CNPJ"
            />
            <div>
              {!errorData && data && (
                <Button
                  className={`w-fit shadow-lg secondary ${
                    data && !isLoading ? "block" : "hidden"
                  }`}
                  isProcessing={pdfURL === ""}
                  disabled={pdfURL === ""}
                  onClick={() => handleClickDownloadPDF()}
                >
                  Salvar como PDF
                </Button>
              )}
            </div>
          </div>
        </form>
      </CardQueryDescription>

      <SliderToPreview />

      {errorData ? (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorData}</span>
        </Alert>
      ) : (
        <>
          <div
            className={`overflow-x-scroll ${
              data || isLoading ? "block" : "hidden"
            }`}
            ref={componentRef}
          >
            {!isLoading ? (
              <>
                <Card className="mt-2 p-4 card-pdf">
                  <HeaderQuery title={query?.name} />

                  <TitleStripe>Dados Cadastrais</TitleStripe>
                  <div className="flex flex-row gap-16">
                    <div className="text-sm">
                      <div className="mb-3">
                        <span className="font-semibold">Razão Social: </span>
                        {data?.identificacao_basico?.razao_social}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Nome Fantasia: </span>
                        {data?.identificacao_basico?.nome_fantasia}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">
                          Natureza Jurídica:{" "}
                        </span>
                        {data?.identificacao_basico?.natureza_juridica}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">CNPJ/MF:</span>
                        {document}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">I.E.: </span>
                        {data?.identificacao_basico?.inscricao_estadual}
                      </div>

                      <div className="mb-3">
                        <span className="font-semibold">CNAE Principal: </span>
                        {data?.identificacao_basico?.ramo_atividade.atividade}
                      </div>

                      <div className="mb-3">
                        <span className="font-semibold">Fundação: </span>
                        {data?.identificacao_basico?.data_fundacao}
                      </div>
                    </div>
                    <div className="text-sm">
                      <div className="mb-3 flex gap-2 items-center">
                        <span className="font-semibold">Situação: </span>
                        <Badge
                          className="w-fit"
                          color={
                            data?.identificacao_basico?.situacao_cnpj ===
                            "Ativo"
                              ? "success"
                              : "gray"
                          }
                        >
                          {data?.identificacao_basico?.situacao_cnpj}
                        </Badge>
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Endereço: </span>
                        {data?.localizacao_basico?.logradouro}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Bairro: </span>
                        {data?.localizacao_basico?.bairro}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Cidade/UF: </span>
                        {data?.localizacao_basico?.cidade}/
                        {data?.localizacao_basico?.uf}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">CEP: </span>
                        {data?.localizacao_basico?.cep}
                      </div>
                    </div>
                  </div>

                  <TitleStripe>Quadro de avisos</TitleStripe>

                  <div className="container-alerts five-columns">
                    {data?.cheques_sem_fundo?.mensagem ? (
                      <Alert
                        color="success"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <IoMdCheckmarkCircleOutline
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">Cheques sem fundo</div>
                        <div className="text-center font-semibold">
                          Nada consta
                        </div>
                      </Alert>
                    ) : (
                      <Alert
                        color="failure"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <FiAlertOctagon
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">Cheques sem fundo</div>
                        <div className="text-center font-semibold">
                          {data?.cheques_sem_fundo?.total_cheques_sem_fundos}{" "}
                          ocorrências
                        </div>
                      </Alert>
                    )}

                    {data?.cheques_sustados?.mensagem ? (
                      <Alert
                        color="success"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <IoMdCheckmarkCircleOutline
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">Cheques sustados</div>
                        <div className="text-center font-semibold">
                          Nada consta
                        </div>
                      </Alert>
                    ) : (
                      <Alert
                        color="failure"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <FiAlertOctagon
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">Cheques sustados</div>
                        <div className="text-center font-semibold">
                          {data?.cheques_sustados?.total} ocorrências
                        </div>
                      </Alert>
                    )}

                    {data?.pendencias_restricoes?.mensagem ? (
                      <Alert
                        color="success"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <IoMdCheckmarkCircleOutline
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">
                          Pendências financeiras
                        </div>
                        <div className="text-center font-semibold">
                          Nada consta
                        </div>
                      </Alert>
                    ) : (
                      <Alert
                        color="failure"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <FiAlertOctagon
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">
                          Pendências financeiras
                        </div>
                        <div className="text-center font-semibold">
                          {data?.pendencias_restricoes?.total_pendencias}{" "}
                          ocorrências
                        </div>
                      </Alert>
                    )}

                    {data?.protestos?.mensagem ? (
                      <Alert
                        color="success"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <IoMdCheckmarkCircleOutline
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">Títulos protestados</div>
                        <div className="text-center font-semibold">
                          Nada consta
                        </div>
                      </Alert>
                    ) : (
                      <Alert
                        color="failure"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <FiAlertOctagon
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">Títulos protestados</div>
                        <div className="text-center font-semibold">
                          {data?.protestos?.total_protestos} ocorrências
                        </div>
                      </Alert>
                    )}

                    {isLoadingProtestos ? (
                      <Alert
                        color="gray"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <div className="text-center">
                          <Spinner
                            aria-label="Large spinner example"
                            size="md"
                          />
                        </div>
                        <div className="text-center mt-2">
                          Protestos dir. cartório
                        </div>
                        <div role="status" className="w-full animate-pulse">
                          <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mt-1"></div>
                        </div>
                      </Alert>
                    ) : (
                      <>
                        {+infoProtestos?.data?.data?.CREDCADASTRAL
                          ?.PROTESTO_SINTETICO?.QUANTIDADE_OCORRENCIA === 0 ? (
                          <Alert
                            color="success"
                            withBorderAccent
                            className="flex justify-center items-center flex-col"
                          >
                            <IoMdCheckmarkCircleOutline
                              fontSize={30}
                              className="mx-auto mb-1"
                            />
                            <div className="text-center">
                              Protestos dir. cartório
                            </div>
                            <div className="text-center font-semibold">
                              Nada consta
                            </div>
                          </Alert>
                        ) : (
                          <Alert
                            color="failure"
                            withBorderAccent
                            className="flex justify-center items-center flex-col"
                          >
                            <FiAlertOctagon
                              fontSize={30}
                              className="mx-auto mb-1"
                            />
                            <div className="text-center">
                              Protestos dir. cartório
                            </div>
                            <div className="text-center font-semibold">
                              {infoProtestos?.data?.data?.CREDCADASTRAL
                                ?.PROTESTO_SINTETICO ? (
                                <>
                                  {
                                    infoProtestos?.data?.data?.CREDCADASTRAL
                                      ?.PROTESTO_SINTETICO
                                      ?.QUANTIDADE_OCORRENCIA
                                  }{" "}
                                  ocorrências
                                </>
                              ) : (
                                "Erro na consulta"
                              )}
                            </div>
                          </Alert>
                        )}
                      </>
                    )}
                  </div>
                  <div>
                    <TitleStripe>Score de risco</TitleStripe>
                  </div>
                  <div className="flex gap-2 flex-row items-center">
                    <div className="w-3/5 p-4">
                      <ReactApexChart
                        options={{
                          chart: {
                            type: "radialBar",
                            offsetY: -20,
                            sparkline: {
                              enabled: true,
                            },
                          },
                          plotOptions: {
                            radialBar: {
                              startAngle: -90,
                              endAngle: 90,
                              track: {
                                background: "#e7e7e7",
                                strokeWidth: "97%",
                                margin: 5, // margin is in pixels
                                dropShadow: {
                                  enabled: true,
                                  top: 2,
                                  left: 0,
                                  color: "#999",
                                  opacity: 1,
                                  blur: 2,
                                },
                              },
                              dataLabels: {
                                name: {
                                  show: false,
                                },
                                value: {
                                  offsetY: -2,
                                  fontSize: "22px",
                                  formatter: function (val) {
                                    return val * 10;
                                  },
                                },
                              },
                            },
                          },
                          grid: {
                            padding: {
                              top: -10,
                            },
                          },
                          fill: {
                            type: "solid",
                            gradient: {
                              shade: "light",
                              shadeIntensity: 0.4,
                              inverseColors: false,
                              opacityFrom: 1,
                              opacityTo: 1,
                              stops: [0, 50, 53, 91],
                            },
                            colors: (() => {
                              const score = +data?.score?.score_pj.score;
                              if (score >= 800) {
                                return "#01B000";
                              } else if (score >= 600) {
                                return "#8DED01";
                              } else if (score >= 400) {
                                return "#FDF700";
                              } else if (score >= 200) {
                                return "#FFBC00";
                              } else {
                                return "#c7001b";
                              }
                            })(),
                          },
                        }}
                        series={[+data?.score?.score_pj.score / 10]}
                        type="radialBar"
                      />
                    </div>
                    <div className="w-full">
                      <div className="mb-2">
                        <Alert className="w-fit mb-2">
                          Probabilidade de indadimplência ={" "}
                          {data?.score?.score_pj?.probabilidade}%
                        </Alert>{" "}
                        {data?.score?.score_pj?.texto}
                      </div>
                    </div>
                  </div>
                  <Alert color="warning">
                    <span className="font-semibold">Importante:</span> a decisão
                    de aprovação ou não do crédito é de exclusiva
                    responsabilidade do concedente. as informações prestadas
                    pelo provedor tem como objevo subsidiar essas decisões e, em
                    hipótese alguma devem ser ulizadas como justificativa, pelo
                    concedente do crédito, para a tomada da referida decisão.
                  </Alert>
                  <div>
                    <TitleStripe>Pendências financeiras</TitleStripe>

                    {data?.pendencias_restricoes?.ultimas_ocorrencias
                      ?.pendencia_restricao?.length > 0 ? (
                      <>
                        <div className="flex flex-row gap-6">
                          <div className="flex gap-2 items-center">
                            <span className="font-semibold">Quantidade: </span>
                            <Badge color="failure" className="w-fit">
                              {data?.pendencias_restricoes?.total_pendencias}
                            </Badge>
                          </div>
                          <div className="flex gap-2 items-center">
                            <span className="font-semibold">
                              Último vencimento:{" "}
                            </span>
                            <Badge color="failure" className="w-fit">
                              {data?.pendencias_restricoes?.data_maior}
                            </Badge>
                          </div>
                          <div className="flex gap-2 items-center">
                            <span className="font-semibold">Valor Total: </span>
                            <Badge color="failure" className="w-fit">
                              {formatToReal(data?.pendencias_restricoes?.valor)}
                            </Badge>
                          </div>
                        </div>
                        <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                          <Table striped>
                            <Table.Head>
                              <Table.HeadCell className="text-center bg-red-300">
                                Vencimento
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Informante
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Valor
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Tipo:
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Cidade:
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Documento origem:
                              </Table.HeadCell>
                            </Table.Head>

                            <Table.Body className="divide-y">
                              {Array.isArray(
                                data?.pendencias_restricoes.ultimas_ocorrencias
                                  ?.pendencia_restricao
                              ) ? (
                                <>
                                  {data?.pendencias_restricoes.ultimas_ocorrencias?.pendencia_restricao?.map(
                                    (ocorrencia, index) => (
                                      <Table.Row
                                        key={index}
                                        className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                      >
                                        <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                          {ocorrencia.data_debito}
                                        </Table.Cell>
                                        <Table.Cell className="text-center">
                                          {ocorrencia.informante}
                                        </Table.Cell>
                                        <Table.Cell className="text-center">
                                          {formatToReal(ocorrencia.valor)}
                                        </Table.Cell>
                                        <Table.Cell className="text-center">
                                          {ocorrencia.tipo}
                                        </Table.Cell>
                                        <Table.Cell className="text-center">
                                          {ocorrencia.cidade}
                                        </Table.Cell>
                                        <Table.Cell className="text-center">
                                          {ocorrencia.documento_origem}
                                        </Table.Cell>
                                      </Table.Row>
                                    )
                                  )}
                                </>
                              ) : (
                                <>
                                  <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                      {
                                        data?.pendencias_restricoes
                                          .ultimas_ocorrencias
                                          ?.pendencia_restricao?.data_debito
                                      }
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {
                                        data?.pendencias_restricoes
                                          .ultimas_ocorrencias
                                          ?.pendencia_restricao?.informante
                                      }
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {formatToReal(
                                        data?.pendencias_restricoes
                                          .ultimas_ocorrencias
                                          ?.pendencia_restricao?.valor
                                      )}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {
                                        data?.pendencias_restricoes
                                          .ultimas_ocorrencias
                                          ?.pendencia_restricao?.tipo
                                      }
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {
                                        data?.pendencias_restricoes
                                          .ultimas_ocorrencias
                                          ?.pendencia_restricao?.cidade
                                      }
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {
                                        data?.pendencias_restricoes
                                          .ultimas_ocorrencias
                                          ?.pendencia_restricao
                                          ?.documento_origem
                                      }
                                    </Table.Cell>
                                  </Table.Row>
                                </>
                              )}
                            </Table.Body>
                          </Table>
                        </div>
                      </>
                    ) : (
                      <Card className={`bg-green-200 mt-4`}>
                        <div className="flex gap-2 items-center text-gray-600 font-semibold">
                          <div>
                            <FaRegCheckCircle fontSize={20} />
                          </div>
                          <div>Nada consta</div>
                        </div>
                      </Card>
                    )}
                  </div>
                  <div>
                    <TitleStripe className="mb-5">Protestos</TitleStripe>
                    {!!data?.protestos?.total_protestos ? (
                      <>
                        <div className="flex flex-row gap-6">
                          <div className="flex items-center gap-2">
                            <span className="font-semibold text-red-700">
                              Quantidade:{" "}
                            </span>
                            <Badge color="failure">
                              {data?.protestos?.total_protestos}
                            </Badge>
                          </div>
                          <div className="flex items-center gap-2">
                            <span className="font-semibold text-red-700">
                              Primeiro valor:{" "}
                            </span>
                            <Badge color="failure">
                              {formatToReal(data?.protestos?.valor_primeiro)}
                            </Badge>
                          </div>
                          <div className="flex items-center gap-2">
                            <span className="font-semibold text-red-700">
                              Valor Total:{" "}
                            </span>
                            <Badge color="failure">
                              {formatToReal(data?.protestos?.valor_total)}
                            </Badge>
                          </div>
                        </div>
                        <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                          <Table striped>
                            <Table.Head>
                              <Table.HeadCell className="text-center bg-red-300">
                                Data
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Valor
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Cartório
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Cidade
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Estado
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Vencimento:
                              </Table.HeadCell>
                            </Table.Head>

                            <Table.Body className="divide-y">
                              {Array.isArray(
                                data?.protestos?.ultimas_ocorrencias?.protesto
                              ) ? (
                                <>
                                  {data?.protestos?.ultimas_ocorrencias?.protesto.map(
                                    (protesto, index) => (
                                      <Table.Row
                                        key={index}
                                        className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                      >
                                        <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                          {protesto.data}
                                        </Table.Cell>
                                        <Table.Cell className="text-center">
                                          {formatToReal(protesto.valor)}
                                        </Table.Cell>
                                        <Table.Cell className="text-center">
                                          {protesto.cartorio}º cartório
                                        </Table.Cell>
                                        <Table.Cell className="text-center">
                                          {protesto.cidade}
                                        </Table.Cell>
                                        <Table.Cell className="text-center">
                                          {protesto.estado}
                                        </Table.Cell>
                                        <Table.Cell className="text-center">
                                          {protesto.vencimento}
                                        </Table.Cell>
                                      </Table.Row>
                                    )
                                  )}
                                </>
                              ) : (
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                  <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                    {
                                      data?.protestos?.ultimas_ocorrencias
                                        ?.protesto?.data
                                    }
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {formatToReal(
                                      data?.protestos?.ultimas_ocorrencias
                                        ?.protesto?.valor
                                    )}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {
                                      data?.protestos?.ultimas_ocorrencias
                                        ?.protesto?.cartorio
                                    }
                                    º cartório
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {
                                      data?.protestos?.ultimas_ocorrencias
                                        ?.protesto?.cidade
                                    }
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {
                                      data?.protestos?.ultimas_ocorrencias
                                        ?.protesto?.estado
                                    }
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {
                                      data?.protestos?.ultimas_ocorrencias
                                        ?.protesto?.vencimento
                                    }
                                  </Table.Cell>
                                </Table.Row>
                              )}
                            </Table.Body>
                          </Table>
                        </div>
                      </>
                    ) : (
                      <Card className={`bg-green-200 mt-4`}>
                        <div className="flex gap-2 items-center text-gray-600 font-semibold">
                          <div>
                            <FaRegCheckCircle fontSize={20} />
                          </div>
                          <div>Nada consta</div>
                        </div>
                      </Card>
                    )}
                  </div>

                  <div>
                    <TitleStripe className="mb-5">
                      Protestos direto do cartório
                    </TitleStripe>
                    {!isLoadingProtestos ? (
                      <>
                        {+infoProtestos?.data?.data?.CREDCADASTRAL
                          ?.PROTESTO_SINTETICO?.QUANTIDADE_OCORRENCIA > 0 ? (
                          <>
                            <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                              <Table striped>
                                <Table.Head>
                                  <Table.HeadCell className="bg-red-300">
                                    Cartório
                                  </Table.HeadCell>
                                  <Table.HeadCell className="bg-red-300">
                                    Credor
                                  </Table.HeadCell>
                                  <Table.HeadCell className="text-center bg-red-300">
                                    Telefone
                                  </Table.HeadCell>
                                  <Table.HeadCell className="text-center bg-red-300">
                                    Valor
                                  </Table.HeadCell>
                                </Table.Head>

                                <Table.Body className="divide-y text-sm">
                                  {infoProtestos?.data?.data?.CREDCADASTRAL?.PROTESTO_SINTETICO?.OCORRENCIAS?.map(
                                    (protesto, index) => (
                                      <Table.Row
                                        className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                        key={index}
                                      >
                                        <Table.Cell className="font-medium text-gray-900 dark:text-white">
                                          {protesto?.CARTORIO}
                                        </Table.Cell>
                                        <Table.Cell>
                                          {protesto?.CREDOR}
                                        </Table.Cell>
                                        <Table.Cell className="text-center">
                                          {protesto?.TELEFONE}
                                        </Table.Cell>
                                        <Table.Cell className="text-center">
                                          {formatToReal(protesto?.VALOR)}
                                        </Table.Cell>
                                      </Table.Row>
                                    )
                                  )}
                                </Table.Body>
                              </Table>
                            </div>
                          </>
                        ) : (
                          <Card className={`bg-green-200  mb-3`}>
                            <div className="flex gap-2 items-center text-gray-600 font-semibold">
                              <div>
                                <FaRegCheckCircle fontSize={20} />
                              </div>
                              <div>Nada consta</div>
                            </div>
                          </Card>
                        )}
                      </>
                    ) : (
                      <div role="status" className="w-full animate-pulse">
                        <div className="h-7 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-4"></div>{" "}
                      </div>
                    )}
                  </div>
                  <div>
                    <TitleStripe className="mb-5">
                      Cheques sem fundos
                    </TitleStripe>

                    {!!data?.cheques_sem_fundo?.total_cheques_sem_fundos ? (
                      <>
                        <div className="flex gap-6">
                          <div className="flex items-center gap-2">
                            <span className="font-semibold text-red-700">
                              Quantidade:{" "}
                            </span>
                            <Badge color="failure">
                              {
                                data?.cheques_sem_fundo
                                  ?.total_cheques_sem_fundos
                              }
                            </Badge>
                          </div>
                          <div className="flex items-center gap-2">
                            <span className="font-semibold text-red-700">
                              Total de nomes relacionados:{" "}
                            </span>
                            <Badge color="failure">
                              {
                                data?.cheques_sem_fundo
                                  ?.total_nomes_relacionados
                              }
                            </Badge>
                          </div>
                        </div>
                        <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                          <Table striped>
                            <Table.Head>
                              <Table.HeadCell className="text-center bg-red-300">
                                Data
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Agência
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Nome do banco
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Quantidade
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Motivo
                              </Table.HeadCell>
                            </Table.Head>

                            <Table.Body className="divide-y">
                              {Array.isArray(
                                data?.cheques_sem_fundo?.ultimas_ocorrencias
                                  ?.cheque_sem_fundo
                              ) ? (
                                <>
                                  {data?.cheques_sem_fundo?.ultimas_ocorrencias?.cheque_sem_fundo.map(
                                    (cheque, index) => (
                                      <Table.Row
                                        key={index}
                                        className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                      >
                                        <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                          {cheque.data_ultimo}
                                        </Table.Cell>
                                        <Table.Cell className="text-center">
                                          {cheque.agencia}
                                        </Table.Cell>
                                        <Table.Cell className="text-center">
                                          {cheque.nome_banco}
                                        </Table.Cell>
                                        <Table.Cell className="text-center">
                                          {cheque.quantidade}
                                        </Table.Cell>
                                        <Table.Cell className="text-center">
                                          {cheque.motivo}
                                        </Table.Cell>
                                      </Table.Row>
                                    )
                                  )}
                                </>
                              ) : (
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                  <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                    {
                                      data?.cheques_sem_fundo
                                        ?.ultimas_ocorrencias?.cheque_sem_fundo
                                        ?.data_ultimo
                                    }
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {
                                      data?.cheques_sem_fundo
                                        ?.ultimas_ocorrencias?.cheque_sem_fundo
                                        ?.agencia
                                    }
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {
                                      data?.cheques_sem_fundo
                                        ?.ultimas_ocorrencias?.cheque_sem_fundo
                                        ?.nome_banco
                                    }
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {
                                      data?.cheques_sem_fundo
                                        ?.ultimas_ocorrencias?.cheque_sem_fundo
                                        ?.quantidade
                                    }
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {
                                      data?.cheques_sem_fundo
                                        ?.ultimas_ocorrencias?.cheque_sem_fundo
                                        ?.motivo
                                    }
                                  </Table.Cell>
                                </Table.Row>
                              )}
                            </Table.Body>
                          </Table>
                        </div>
                      </>
                    ) : (
                      <Card className={`bg-green-200 mt-4`}>
                        <div className="flex gap-2 items-center text-gray-600 font-semibold">
                          <div>
                            <FaRegCheckCircle fontSize={20} />
                          </div>
                          <div>Nada consta</div>
                        </div>
                      </Card>
                    )}
                  </div>
                  <div>
                    <TitleStripe className="mb-5">Cheques sustados</TitleStripe>
                    {data?.cheques_sustados?.ultimas_ocorrencias
                      ?.cheques_sustado?.length > 0 ? (
                      <>
                        <div className="flex gap-6 flex-wrap">
                          <div className="flex items-center gap-2">
                            <span className="font-semibold text-red-700">
                              Período:{" "}
                            </span>
                            <Badge color="failure">
                              {data?.cheques_sustados?.periodo_inicial} até{" "}
                              {data?.cheques_sustados?.periodo_final}
                            </Badge>
                          </div>
                          <div className="flex items-center gap-2">
                            <span className="font-semibold text-red-700">
                              Quantidade:{" "}
                            </span>
                            <Badge color="failure">
                              {data?.cheques_sustados?.total}
                            </Badge>
                          </div>
                        </div>
                        <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                          <Table striped>
                            <Table.Head>
                              <Table.HeadCell className="text-center bg-red-300">
                                Data
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Banco
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Agência
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Cheque
                              </Table.HeadCell>
                            </Table.Head>

                            <Table.Body className="divide-y">
                              {data?.cheques_sustados?.ultimas_ocorrencias?.cheques_sustado.map(
                                (cheque, index) => (
                                  <Table.Row
                                    key={index}
                                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                  >
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                      {cheque.data}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {cheque.banco}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {cheque.agencia}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {cheque.cheques}
                                    </Table.Cell>
                                  </Table.Row>
                                )
                              )}
                            </Table.Body>
                          </Table>
                        </div>
                      </>
                    ) : (
                      <Card className={`bg-green-200 mt-4`}>
                        <div className="flex gap-2 items-center text-gray-600 font-semibold">
                          <div>
                            <FaRegCheckCircle fontSize={20} />
                          </div>
                          <div>Nada consta</div>
                        </div>
                      </Card>
                    )}
                  </div>

                  <div>
                    <TitleStripe className="mb-5">
                      Histórico de consultas
                    </TitleStripe>

                    {data?.consultas?.ultimas_consultas?.consulta?.length >
                    0 ? (
                      <>
                        <div className="flex gap-6">
                          <div>
                            <span className="font-semibold">
                              Quantidade Total:{" "}
                            </span>
                            {data?.consultas?.quantidade_total}
                          </div>
                          <div>
                            <span className="font-semibold">Período: </span>
                            {data?.consultas?.periodo_inicial} até{" "}
                            {data?.consultas?.periodo_final}
                          </div>
                        </div>
                        <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                          <Table striped>
                            <Table.Head>
                              <Table.HeadCell className="text-center bg-gray-300">
                                Data
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-gray-300">
                                Empresa
                              </Table.HeadCell>
                            </Table.Head>

                            <Table.Body className="divide-y">
                              {Array.isArray(
                                data?.consultas?.ultimas_consultas?.consulta
                              ) ? (
                                <>
                                  {data?.consultas?.ultimas_consultas?.consulta?.map(
                                    (consult, index) => (
                                      <Table.Row
                                        key={index}
                                        className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                      >
                                        <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                          {consult.data}
                                        </Table.Cell>
                                        <Table.Cell className="text-center">
                                          {consult.razao_social}
                                        </Table.Cell>
                                      </Table.Row>
                                    )
                                  )}
                                </>
                              ) : (
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                  <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                    {
                                      data?.consultas?.ultimas_consultas
                                        ?.consulta?.data
                                    }
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {
                                      data?.consultas?.ultimas_consultas
                                        ?.consulta?.razao_social
                                    }
                                  </Table.Cell>
                                </Table.Row>
                              )}
                            </Table.Body>
                          </Table>
                        </div>
                      </>
                    ) : (
                      <Card className={`bg-green-200 mt-4`}>
                        <div className="flex gap-2 items-center text-gray-600 font-semibold">
                          <div>
                            <IoAlertCircleOutline fontSize={20} />
                          </div>
                          <div>Nada consta</div>
                        </div>
                      </Card>
                    )}
                  </div>

                  <div className="mt-10 italic border border-gray-200 p-2">
                    <span className="font-semibold">Observações:</span> as
                    informações evidenciadas em “Dados Cadastrais Básicos” são
                    provenientes de base de dados oficial da Receita Federal do
                    Brasil. Para maior detalhamento e cruzamento com outras
                    fontes de dados cadastrais, acessar a consulta “Dados
                    Cadastrais” no painel de consultas.
                  </div>
                </Card>
              </>
            ) : (
              <QueryLoading />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default PositivoPlusCNPJQuery;
