import {
  Alert,
  Badge,
  Button,
  Card,
  Label,
  Spinner,
  Table,
  Timeline,
} from "flowbite-react";
import React, { useEffect, useRef, useState } from "react";
import { IoAlertCircleOutline } from "react-icons/io5";
import { formatToReal, isValidCPF } from "../../utils/utils";
import axios from "../../config/axiosInstance";
import { QUERIES } from "../../config/apiConfig";
import { HiInformationCircle } from "react-icons/hi";
import HeaderQuery from "./HeaderQuery";
import usePDF from "../../hooks/usePDF";
import TitleStripe from "../common/TitleStripe";
import CardQueryDescription from "../common/CardQueryDescription";
import QueryInput from "../inputs/QueryInput";
import { FiAlertOctagon } from "react-icons/fi";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import SliderToPreview from "../common/SliderToPreview";
import QueryLoading from "../common/QueryLoading";

const CreditoPremiumCPFQuery = ({ query, selectedPrice }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [document, setDocument] = useState("");
  const [error, setError] = useState("");
  const [errorQuery, setErrorQuery] = useState("");
  const [data, setData] = useState(null);
  const componentRef = useRef();
  const { handleClickDownloadPDF, pdfURL, setPdfURL } = usePDF(
    componentRef,
    info,
    query?.id,
    data
  );

  const fetchInfo = async (raw) => {
    return await axios(
      `${QUERIES.GET_CREDITO_PREMIUM_CPF}/${info.replace(/\D/g, "")}`
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDocument(info);

    const unmaskedValue = info.replace(/\D/g, "");
    let isValid = true;

    if (!isValidCPF(unmaskedValue)) {
      setError("CPF inválido!");
      isValid = false;
    }

    if (isValid) {
      try {
        setPdfURL("");
        setData(null);
        setIsLoading(true);
        setError("");
        setErrorQuery("");
        const { data: dados } = await fetchInfo();
        setData(dados.data);
      } catch (error) {
        setErrorQuery(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta"
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getImageScoreSale = (score) => {
    let scoreLetter;

    if (score <= 125) {
      scoreLetter = "1";
    } else if (score <= 250) {
      scoreLetter = "2";
    } else if (score <= 375) {
      scoreLetter = "3";
    } else if (score <= 500) {
      scoreLetter = "4";
    } else if (score <= 625) {
      scoreLetter = "5";
    } else if (score <= 750) {
      scoreLetter = "6";
    } else if (score <= 875) {
      scoreLetter = "7";
    } else {
      scoreLetter = "8";
    }

    return `/assets/score/serasa/score_${scoreLetter}.png`;
  };

  return (
    <>
      <div className="text-lg font-semibold mb-3 title-syncx">
        {query?.name}
      </div>
      <CardQueryDescription
        title="Informações da consulta"
        infos={[
          "Dados Cadastrais",
          "Score",
          "Pendências financeiras",
          "Protestos",
          "Cheques sem fundos",
          "Cheques sustados",
        ]}
        price={selectedPrice}
      >
        <form
          className="flex flex-col gap-2 bg-white p-2 rounded-lg shadow-md w-fit"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col md:flex-row gap-2 items-end">
            <QueryInput
              info={info}
              setInfo={setInfo}
              isLoading={isLoading}
              error={error}
              type="CPF"
            />
            <div>
              {!errorQuery && data && (
                <Button
                  className={`w-fit shadow-lg secondary ${
                    data && !isLoading ? "block" : "hidden"
                  }`}
                  isProcessing={pdfURL === ""}
                  disabled={pdfURL === ""}
                  onClick={() => handleClickDownloadPDF()}
                >
                  Salvar como PDF
                </Button>
              )}
            </div>
          </div>
        </form>
      </CardQueryDescription>

      <SliderToPreview />

      {errorQuery ? (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorQuery}</span>
        </Alert>
      ) : (
        <>
          <div
            className={`overflow-x-scroll ${
              data || isLoading ? "block" : "hidden"
            }`}
            ref={componentRef}
          >
            {!isLoading ? (
              <>
                <Card className="mt-2 p-5 card-pdf">
                  <HeaderQuery title={query?.name} />

                  <TitleStripe>Dados Cadastrais</TitleStripe>
                  <div className="flex gap-16">
                    <div className="text-sm">
                      <div className="mb-3">
                        <span className="font-semibold">Nome completo: </span>
                        {data?.CREDCADASTRAL?.DADOS_GERAIS?.DADOS[0]?.NOME ||
                          "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Nome da mãe: </span>
                        {data?.CREDCADASTRAL?.DADOS_GERAIS?.DADOS[0]
                          ?.NOME_DA_MAE || "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">
                          Data de nascimento:{" "}
                        </span>
                        {data?.CREDCADASTRAL?.DADOS_GERAIS?.DADOS[0]
                          ?.NASCIMENTO || "Indisponível"}
                      </div>
                    </div>
                    <div className="text-sm">
                      <div className="mb-3">
                        <span className="font-semibold">CPF: </span>
                        {document}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Sexo: </span>
                        {data?.CREDCADASTRAL?.DADOS_GERAIS?.DADOS[0]?.SEXO ||
                          "Indisponível"}
                      </div>
                    </div>
                  </div>

                  <TitleStripe>Quadro de avisos</TitleStripe>
                  <div className="container-alerts five-columns">
                    {data?.CREDCADASTRAL?.PEND_REFIN?.QUANTIDADE_OCORRENCIA ===
                    "0" ? (
                      <Alert
                        color="success"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <IoMdCheckmarkCircleOutline
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">Pendências REFIN</div>
                        <div className="text-center font-semibold">
                          Nada consta
                        </div>
                      </Alert>
                    ) : (
                      <Alert
                        color="failure"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <FiAlertOctagon
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">Pendências REFIN</div>
                        <div className="text-center font-semibold">
                          {formatToReal(
                            data?.CREDCADASTRAL?.PEND_REFIN?.VALOR_TOTAL?.replace(
                              ",",
                              "."
                            )
                          )}
                        </div>
                      </Alert>
                    )}

                    {data?.CREDCADASTRAL?.PEND_VENCIDAS
                      ?.QUANTIDADE_OCORRENCIA === "0" ? (
                      <Alert
                        color="success"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <IoMdCheckmarkCircleOutline
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">Pendências Vencidas</div>
                        <div className="text-center font-semibold">
                          Nada consta
                        </div>
                      </Alert>
                    ) : (
                      <Alert
                        color="failure"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <FiAlertOctagon
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">Pendências Vencidas</div>
                        <div className="text-center font-semibold">
                          {formatToReal(
                            data?.CREDCADASTRAL?.PEND_VENCIDAS?.VALOR_TOTAL?.replace(
                              ",",
                              "."
                            )
                          )}
                        </div>
                      </Alert>
                    )}

                    {data?.CREDCADASTRAL?.PEND_FINANCEIRAS
                      ?.QUANTIDADE_OCORRENCIA === "0" ? (
                      <Alert
                        color="success"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <IoMdCheckmarkCircleOutline
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">
                          Pendências Financeiras
                        </div>
                        <div className="text-center font-semibold">
                          Nada consta
                        </div>
                      </Alert>
                    ) : (
                      <Alert
                        color="failure"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <FiAlertOctagon
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">
                          Pendências Financeiras
                        </div>
                        <div className="text-center font-semibold">
                          {formatToReal(
                            data?.CREDCADASTRAL?.PEND_FINANCEIRAS?.VALOR_TOTAL?.replace(
                              ",",
                              "."
                            )
                          )}
                        </div>
                      </Alert>
                    )}

                    {data?.CREDCADASTRAL?.CH_SEM_FUNDOS_BACEN
                      ?.QUANTIDADE_OCORRENCIA === "0" ? (
                      <Alert
                        color="success"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <IoMdCheckmarkCircleOutline
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">
                          Cheques sem fundos BACEN
                        </div>
                        <div className="text-center font-semibold">
                          Nada consta
                        </div>
                      </Alert>
                    ) : (
                      <Alert
                        color="failure"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <FiAlertOctagon
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">
                          Cheques sem fundos BACEN
                        </div>
                        <div className="text-center font-semibold">
                          {
                            data?.CREDCADASTRAL?.CH_SEM_FUNDOS_BACEN
                              ?.QUANTIDADE_OCORRENCIA
                          }{" "}
                          ocorrências
                        </div>
                      </Alert>
                    )}

                    {data?.CREDCADASTRAL?.PROTESTO_SINTETICO
                      ?.QUANTIDADE_OCORRENCIA === "0" ? (
                      <Alert
                        color="success"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <IoMdCheckmarkCircleOutline
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">Protestos</div>
                        <div className="text-center font-semibold">
                          Nada consta
                        </div>
                      </Alert>
                    ) : (
                      <Alert
                        color="failure"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <FiAlertOctagon
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">Protestos</div>
                        <div className="text-center font-semibold">
                          {formatToReal(
                            data?.CREDCADASTRAL?.PROTESTO_SINTETICO?.VALOR_TOTAL?.replace(
                              ",",
                              "."
                            )
                          )}
                        </div>
                      </Alert>
                    )}
                  </div>
                  <div>
                    <TitleStripe>Score de risco</TitleStripe>
                  </div>
                  <div className="flex gap-2 items-center">
                    <div className="w-3/5 p-4">
                      <div className="flex-1 mt-2">
                        <img
                          className="px-6"
                          src={`${getImageScoreSale(
                            +data?.CREDCADASTRAL?.SCORES?.OCORRENCIAS[0]
                              ?.SCORE || 0
                          )}`}
                        />
                        <Badge className="text-center font-semibold text-lg w-fit mx-auto bg-blue-100 mt-1">
                          {+data?.CREDCADASTRAL?.SCORES?.OCORRENCIAS[0]
                            ?.SCORE || 0}
                        </Badge>
                      </div>
                    </div>
                    <div className="w-full">
                      <div className="mb-2">
                        <Alert className="w-fit mb-2">
                          Probabilidade de indadimplência ={" "}
                          {
                            data?.CREDCADASTRAL?.SCORES?.OCORRENCIAS[0]
                              ?.PROBABILIDADE_INADIMPLENCIA
                          }
                        </Alert>{" "}
                        <div className="text-sm">
                          {data?.CREDCADASTRAL?.SCORES?.OCORRENCIAS[0]?.TEXTO}
                        </div>
                      </div>
                    </div>
                  </div>
                  <Alert color="warning">
                    <span className="font-semibold">Importante:</span> a decisão
                    de aprovação ou não do crédito é de exclusiva
                    responsabilidade do concedente. as informações prestadas
                    pelo provedor tem como objevo subsidiar essas decisões e, em
                    hipótese alguma devem ser ulizadas como justificativa, pelo
                    concedente do crédito, para a tomada da referida decisão.
                  </Alert>
                  <div>
                    <TitleStripe className="mb-5">Renda Presumida</TitleStripe>
                    <div className="mb-3">
                      <span className="font-semibold">Faixa: </span>
                      <Badge
                        className="w-fit inline-block text-base"
                        color="green"
                      >
                        {data?.CREDCADASTRAL?.RENDA_PRESUMIDA?.VALOR_PRESUMIDO}
                      </Badge>
                    </div>
                    <div className="mb-3">
                      <span className="font-semibold">Descrição: </span>
                      {data?.CREDCADASTRAL?.RENDA_PRESUMIDA?.DESCRICAO}
                    </div>
                  </div>

                  <div>
                    <TitleStripe className="mb-5">
                      Participação em empresas
                    </TitleStripe>
                    {data?.CREDCADASTRAL?.PARTICIPACAO_EM_EMPRESAS?.OCORRENCIAS
                      ?.length > 0 ? (
                      <Timeline>
                        {data?.CREDCADASTRAL?.PARTICIPACAO_EM_EMPRESAS?.OCORRENCIAS?.map(
                          (participacao, index) => (
                            <Timeline.Item key={index}>
                              <Timeline.Point />
                              <Timeline.Content>
                                <Timeline.Time>
                                  Data entrada:{" "}
                                  {participacao?.PARTICIPANTE_ENTRADA ||
                                    "Sem info."}
                                </Timeline.Time>
                                <Timeline.Title>
                                  {participacao?.RAZAO_SOCIAL || "Sem info."}
                                </Timeline.Title>
                                <Timeline.Body>
                                  <div>
                                    CPF/CNPJ:{" "}
                                    {participacao?.CNPJ || "Sem info."}
                                  </div>
                                  <div>
                                    Participação:{" "}
                                    {participacao?.PARTICIPACAO || "Sem info."}
                                  </div>
                                  <div>
                                    Qtd. de sócios:{" "}
                                    {participacao?.QTD_OUTROS_SOCIOS ||
                                      "Sem info."}
                                  </div>
                                </Timeline.Body>
                              </Timeline.Content>
                            </Timeline.Item>
                          )
                        )}
                      </Timeline>
                    ) : (
                      <Card className={`bg-gray-200 mb-8 mt-3`}>
                        <div className="flex gap-2 items-center text-gray-600 font-semibold">
                          <div>Nada consta</div>
                        </div>
                      </Card>
                    )}
                  </div>

                  <div className="-mt-8">
                    <TitleStripe className="mb-5">Pendências refin</TitleStripe>

                    {data?.CREDCADASTRAL?.PEND_REFIN?.QUANTIDADE_OCORRENCIA !==
                    "0" ? (
                      <>
                        <div className="flex gap-6">
                          <div className="flex gap-2 items-center">
                            <span className="font-semibold text-red-700">
                              Quantidade:{" "}
                            </span>
                            <Badge color="failure" className="w-fit">
                              {
                                data?.CREDCADASTRAL?.PEND_REFIN
                                  ?.QUANTIDADE_OCORRENCIA
                              }
                            </Badge>
                          </div>
                          <div className="flex gap-2 items-center">
                            <span className="font-semibold text-red-700">
                              Valor Total:{" "}
                            </span>
                            <Badge color="failure" className="w-fit">
                              {formatToReal(
                                data?.CREDCADASTRAL?.PEND_REFIN?.VALOR_TOTAL?.replace(
                                  ",",
                                  "."
                                )
                              )}
                            </Badge>
                          </div>
                        </div>
                        <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                          <Table striped>
                            <Table.Head>
                              <Table.HeadCell className="text-center bg-red-300">
                                Data Venc.
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Informante
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Credor
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Valor
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Tipo Devedor
                              </Table.HeadCell>
                            </Table.Head>

                            <Table.Body className="divide-y">
                              <>
                                {data?.CREDCADASTRAL?.PEND_REFIN?.PROVEDORES?.map(
                                  (debito, index) => (
                                    <Table.Row
                                      key={index}
                                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                    >
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                        {debito.DATA_VENCIMENTO}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {debito.INFORMANTE}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {debito.CREDOR}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {formatToReal(
                                          debito.VALOR?.replace(",", ".")
                                        )}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {debito.TIPO_DEVEDOR}
                                      </Table.Cell>
                                    </Table.Row>
                                  )
                                )}
                              </>
                            </Table.Body>
                          </Table>
                        </div>
                      </>
                    ) : (
                      <Card className={`bg-green-200 mt-4`}>
                        <div className="flex gap-2 items-center text-gray-600 font-semibold">
                          <div>
                            <IoAlertCircleOutline fontSize={20} />
                          </div>
                          <div>Nada consta</div>
                        </div>
                      </Card>
                    )}
                  </div>

                  <div>
                    <TitleStripe className="mb-5">
                      Pendências vencidas
                    </TitleStripe>

                    {data?.CREDCADASTRAL?.PEND_VENCIDAS
                      ?.QUANTIDADE_OCORRENCIA !== "0" ? (
                      <>
                        <div className="flex gap-6">
                          <div className="flex gap-2 items-center">
                            <span className="font-semibold text-red-700">
                              Quantidade:{" "}
                            </span>
                            <Badge color="failure" className="w-fit">
                              {
                                data?.CREDCADASTRAL?.PEND_VENCIDAS
                                  ?.QUANTIDADE_OCORRENCIA
                              }
                            </Badge>
                          </div>
                          <div className="flex gap-2 items-center">
                            <span className="font-semibold text-red-700">
                              Valor Total:{" "}
                            </span>
                            <Badge color="failure" className="w-fit">
                              {formatToReal(
                                data?.CREDCADASTRAL?.PEND_VENCIDAS?.VALOR_TOTAL?.replace(
                                  ",",
                                  "."
                                )
                              )}
                            </Badge>
                          </div>
                        </div>
                        <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                          <Table striped>
                            <Table.Head>
                              <Table.HeadCell className="text-center bg-red-300">
                                Data Venc.
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Informante
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Credor
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Valor
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Tipo Devedor
                              </Table.HeadCell>
                            </Table.Head>

                            <Table.Body className="divide-y">
                              <>
                                {data?.CREDCADASTRAL?.PEND_VENCIDAS?.PROVEDORES?.map(
                                  (debito, index) => (
                                    <Table.Row
                                      key={index}
                                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                    >
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                        {debito.DATA_VENCIMENTO}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {debito.INFORMANTE}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {debito.CREDOR}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {formatToReal(
                                          debito.VALOR?.replace(",", ".")
                                        )}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {debito.TIPO_DEVEDOR}
                                      </Table.Cell>
                                    </Table.Row>
                                  )
                                )}
                              </>
                            </Table.Body>
                          </Table>
                        </div>
                      </>
                    ) : (
                      <Card className={`bg-green-200 mt-4`}>
                        <div className="flex gap-2 items-center text-gray-600 font-semibold">
                          <div>
                            <IoAlertCircleOutline fontSize={20} />
                          </div>
                          <div>Nada consta</div>
                        </div>
                      </Card>
                    )}
                  </div>

                  <div>
                    <TitleStripe className="mb-5">
                      Pendências financeiras
                    </TitleStripe>

                    {data?.CREDCADASTRAL?.PEND_FINANCEIRAS
                      ?.QUANTIDADE_OCORRENCIA !== "0" ? (
                      <>
                        <div className="flex gap-6">
                          <div className="flex gap-2 items-center">
                            <span className="font-semibold text-red-700">
                              Quantidade:{" "}
                            </span>
                            <Badge color="failure" className="w-fit">
                              {
                                data?.CREDCADASTRAL?.PEND_FINANCEIRAS
                                  ?.QUANTIDADE_OCORRENCIA
                              }
                            </Badge>
                          </div>
                          <div className="flex gap-2 items-center">
                            <span className="font-semibold text-red-700">
                              Valor Total:{" "}
                            </span>
                            <Badge color="failure" className="w-fit">
                              {formatToReal(
                                data?.CREDCADASTRAL?.PEND_FINANCEIRAS?.VALOR_TOTAL?.replace(
                                  ",",
                                  "."
                                )
                              )}
                            </Badge>
                          </div>
                        </div>
                        <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                          <Table striped>
                            <Table.Head>
                              <Table.HeadCell className="text-center bg-red-300">
                                Data Venc.
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Informante
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Credor
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Valor
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Tipo Devedor
                              </Table.HeadCell>
                            </Table.Head>

                            <Table.Body className="divide-y">
                              <>
                                {data?.CREDCADASTRAL?.PEND_FINANCEIRAS?.OCORRENCIAS?.map(
                                  (debito, index) => (
                                    <Table.Row
                                      key={index}
                                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                    >
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                        {debito.DATA_VENCIMENTO}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {debito.INFORMANTE}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {debito.CREDOR}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {formatToReal(
                                          debito.VALOR?.replace(",", ".")
                                        )}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {debito.TIPO_DEVEDOR}
                                      </Table.Cell>
                                    </Table.Row>
                                  )
                                )}
                              </>
                            </Table.Body>
                          </Table>
                        </div>
                      </>
                    ) : (
                      <Card className={`bg-green-200 mt-4`}>
                        <div className="flex gap-2 items-center text-gray-600 font-semibold">
                          <div>
                            <IoAlertCircleOutline fontSize={20} />
                          </div>
                          <div>Nada consta</div>
                        </div>
                      </Card>
                    )}
                  </div>

                  <div>
                    <TitleStripe className="mb-5">
                      Cheques sem fundos bacen
                    </TitleStripe>

                    {data?.CREDCADASTRAL?.CH_SEM_FUNDOS_BACEN
                      ?.QUANTIDADE_OCORRENCIA !== "0" ? (
                      <>
                        <div className="flex gap-6">
                          <div className="flex gap-2 items-center">
                            <span className="font-semibold text-red-700">
                              Quantidade:{" "}
                            </span>
                            <Badge color="failure" className="w-fit">
                              {
                                data?.CREDCADASTRAL?.CH_SEM_FUNDOS_BACEN
                                  ?.QUANTIDADE_OCORRENCIA
                              }
                            </Badge>
                          </div>
                        </div>
                        <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                          <Table striped>
                            <Table.Head>
                              <Table.HeadCell className="text-center bg-red-300">
                                Data Venc.
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Informante
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Credor
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Valor
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Tipo Devedor
                              </Table.HeadCell>
                            </Table.Head>

                            <Table.Body className="divide-y">
                              <>
                                {data?.CREDCADASTRAL?.CH_SEM_FUNDOS_BACEN?.OCORRENCIAS?.map(
                                  (debito, index) => (
                                    <Table.Row
                                      key={index}
                                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                    >
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                        {debito.DATA_VENCIMENTO}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {debito.INFORMANTE}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {debito.CREDOR}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {formatToReal(
                                          debito.VALOR?.replace(",", ".")
                                        )}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {debito.TIPO_DEVEDOR}
                                      </Table.Cell>
                                    </Table.Row>
                                  )
                                )}
                              </>
                            </Table.Body>
                          </Table>
                        </div>
                      </>
                    ) : (
                      <Card className={`bg-green-200 mt-4`}>
                        <div className="flex gap-2 items-center text-gray-600 font-semibold">
                          <div>
                            <IoAlertCircleOutline fontSize={20} />
                          </div>
                          <div>Nada consta</div>
                        </div>
                      </Card>
                    )}
                  </div>

                  <div>
                    <TitleStripe className="mb-5">Protestos</TitleStripe>

                    {data?.CREDCADASTRAL?.PROTESTO_SINTETICO
                      ?.QUANTIDADE_OCORRENCIA !== "0" ? (
                      <>
                        <div className="flex gap-6">
                          <div className="flex gap-2 items-center">
                            <span className="font-semibold text-red-700">
                              Quantidade:{" "}
                            </span>
                            <Badge color="failure" className="w-fit">
                              {
                                data?.CREDCADASTRAL?.PROTESTO_SINTETICO
                                  ?.QUANTIDADE_OCORRENCIA
                              }
                            </Badge>
                          </div>
                          <div className="flex gap-2 items-center">
                            <span className="font-semibold text-red-700">
                              Valor Total:{" "}
                            </span>
                            <Badge color="failure" className="w-fit">
                              {formatToReal(
                                data?.CREDCADASTRAL?.PROTESTO_SINTETICO?.VALOR_TOTAL?.replace(
                                  ",",
                                  "."
                                )
                              )}
                            </Badge>
                          </div>
                        </div>
                        <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                          <Table striped>
                            <Table.Head>
                              <Table.HeadCell className="text-center bg-red-300">
                                Data Ocorr.
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Cartório
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Comarca
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Valor
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                UF
                              </Table.HeadCell>
                            </Table.Head>

                            <Table.Body className="divide-y">
                              <>
                                {data?.CREDCADASTRAL?.PROTESTO_SINTETICO?.OCORRENCIAS?.map(
                                  (protesto, index) => (
                                    <Table.Row
                                      key={index}
                                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                    >
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center">
                                        {protesto.DATA_OCORRENCIA || "-"}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {protesto.CARTORIO || "-"}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {protesto.COMARCA || "-"}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {formatToReal(
                                          protesto.VALOR?.replace(",", ".")
                                        )}
                                      </Table.Cell>
                                      <Table.Cell className="text-center">
                                        {protesto.UF || "-"}
                                      </Table.Cell>
                                    </Table.Row>
                                  )
                                )}
                              </>
                            </Table.Body>
                          </Table>
                        </div>
                      </>
                    ) : (
                      <Card className={`bg-green-200 mt-4`}>
                        <div className="flex gap-2 items-center text-gray-600 font-semibold">
                          <div>
                            <IoAlertCircleOutline fontSize={20} />
                          </div>
                          <div>Nada consta</div>
                        </div>
                      </Card>
                    )}
                  </div>

                  <div className="mt-10 italic border border-gray-200 p-2">
                    <span className="font-semibold">Observações:</span> as
                    informações evidenciadas em “Dados Cadastrais Básicos” são
                    provenientes de base de dados oficial da Receita Federal do
                    Brasil. Para maior detalhamento e cruzamento com outras
                    fontes de dados cadastrais, acessar a consulta “Dados
                    Cadastrais” no painel de consultas.
                  </div>
                </Card>
              </>
            ) : (
              <QueryLoading />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default CreditoPremiumCPFQuery;
