import { Card } from "flowbite-react";

const QueryLoading = () => {
  return (
    <Card>
      <div role="status" className="w-full animate-pulse">
        {Array.from({ length: 10 }).map((_, index) => (
          <div key={index}>
            <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
            <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
            <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
            <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-3"></div>
            <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-10"></div>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default QueryLoading;
