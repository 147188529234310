import React from "react";
import moment from "moment";

const HeaderQuery = ({ title, type = "default" }) => {
  return (
    <>
      <div className="flex flex-row justify-between items-center gap-2 -mt-2 mb-1">
        <img src="/assets/logo-azul.png" className="h-14" />
        <div className="font-semibold text-xs">{title}</div>
        <div className="text-xs">
          <span>Data | Hora: </span>
          {moment(moment()).format("DD/MM/yyyy | HH:mm")}h
        </div>
      </div>
      <h2
        className="text-center font-semibold border-b border-black w-fit mx-auto text-lg whitespace-nowrap"
        style={{ "font-variant": "small-caps" }}
      >
        Relatório de Inteligência
      </h2>
    </>
  );
};

export default HeaderQuery;
