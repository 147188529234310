import { jwtDecode } from "jwt-decode";
import moment from "moment";
import { QUERIES } from "../config/apiConfig";
import { handleDownloadPdf } from "./pdf-exporter";
import axios from "../config/axiosInstance";

export const checkIfAuthenticated = () => {
  const token = localStorage.getItem("token");

  try {
    const decodedToken = jwtDecode(token);

    const tokenExpirationDate = new Date(decodedToken.exp) * 1000;
    const currentDate = new Date();

    return tokenExpirationDate > currentDate;
  } catch (error) {
    return false;
  }
};

export const saveUserInWindow = () => {
  const token = localStorage.getItem("token");
  const decodedToken = jwtDecode(token);
  window.user = decodedToken;
};

export const calculateExpirationPeriodTest = (date) => {
  const expirationDate = moment(date);
  const today = moment();
  return expirationDate.diff(today, "days") + 1;
};

export const getFirstLettersName = (fullName) => {
  const nameParts = fullName.trim().split(" ");

  const firstLetterFirstName = nameParts[0][0];
  const firstLetterLastName =
    nameParts.length > 1 ? nameParts[nameParts.length - 1][0] : "";

  return `${firstLetterFirstName}${firstLetterLastName}`.toUpperCase();
};

export const isValidCNPJ = (cnpj) => {
  cnpj = cnpj.replace(/[^\d]+/g, "");
  if (cnpj.length !== 14 || /^(\d)\1+$/.test(cnpj)) return false;
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== parseInt(digitos.charAt(0))) return false;
  tamanho += 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  return resultado === parseInt(digitos.charAt(1));
};

export const isValidCPF = (cpf) => {
  cpf = cpf.replace(/[^\d]+/g, "");
  if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) return false;
  let soma = 0;
  let resto;
  for (let i = 1; i <= 9; i++)
    soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(9, 10))) return false;
  soma = 0;
  for (let i = 1; i <= 10; i++)
    soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(10, 11))) return false;
  return true;
};

export const formatToReal = (number) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
};

export const resetValuesForm = (values, setValue, reset) => {
  values.forEach((value) => {
    setValue(value, "");
  });
  reset();
};

export const uploadPDF = async (component, info, queryId) => {
  const file = await handleDownloadPdf(component.current);
  const formData = new FormData();
  formData.append("file", file);

  try {
    let newInfo = info.length === 7 ? info : info.replace(/\D/g, "");

    return await axios.post(
      `${QUERIES.UPLOAD_PDF}?parameterInfo=${newInfo}&queryId=${queryId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  } catch (error) {
    console.error("Upload error:", error);
  }
};

export const getImageURLScore = (score) => {
  const scoreImages = [
    "/assets/score/score_1.png",
    "/assets/score/score_2.png",
    "/assets/score/score_3.png",
    "/assets/score/score_4.png",
    "/assets/score/score_5.png",
    "/assets/score/score_6.png",
  ];

  const index = Math.min(Math.floor(score / 170), scoreImages.length - 1);

  return scoreImages[index];
};

export const maskCEP = (cep) => {
  cep = cep?.replace(/\D/g, "");
  cep = cep?.replace(/^(\d{5})(\d)/, "$1-$2");

  return cep;
}

export const maskStateSubscription = (inscricao) => {
  inscricao = inscricao.replace(/\D/g, "");
  return inscricao.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3.$4-$5");
}
